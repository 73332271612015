export const studentsColumns = [
  {
    title: 'Nome',
    id: 'name',
    content: (v) => <span>{v.name}</span>,
    props: {
      left: true,
    },
  },
  {
    title: 'Email',
    id: 'email',
    content: (v) => <span>{v.email}</span>,
    props: {
      left: true,
    },
  },
  {
    title: 'Nível',
    id: 'level',
    content: (v) => <span>{v.level}</span>,
    props: {
      left: true,
    },
  },
  {
    title: 'Data/hora agendamento',
    id: 'reservedAt',
    content: (v) => <span>{v.reservedAt}</span>,
    props: {
      left: true,
    },
  },
]

export const nullStudentArray = [
  {
    name: '',
    email: '',
    level: '',
    reservedAt: '',
  },
]
