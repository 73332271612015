import React, { useEffect, useState } from 'react'
import { PrivateRoute } from '../components/private-route'
import Login from '../pages/Login'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import EsqueceuSuaSenha from '../pages/EsqueceuSuaSenha'
import ResetPassword from '../pages/ResetPassword'
import HeaderMobile from '../components/headerMobile'
import Header from '../components/header'
import { useUserInfo } from '../contexts/user.context'
import Admin from '../pages/Admin'

export default function AppRoutes() {
  const [isMobile, setIsMobile] = useState(null)
  const [token, setToken] = useState()
  const { userInfo } = useUserInfo()

  useEffect(() => {
    setIsMobile(window.innerWidth < 900)
  }, [])

  useEffect(() => {
    if (userInfo) {
      setToken(userInfo.token)
    }
  }, [userInfo])

  return (
    <>
      <Router>
        {userInfo && userInfo.token ? isMobile ? <HeaderMobile /> : <Header /> : null}
        <Routes>
          <Route path='/' exact element={<Admin />} />
          <Route path='/login' element={<Login />} />
          <Route path='/esqueceu-sua-senha' element={<EsqueceuSuaSenha />} />
          <Route path='/alterar-senha/:token' element={<ResetPassword />} />
          <Route
            path='/admin'
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  )
}
