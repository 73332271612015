import styled from 'styled-components'

export const ItemBox = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${(props) => (props.active ? '#181818' : '#212121')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  cursor: pointer;
  border: ${(props) => (props.active ? '2px solid #ffff00' : 'none')};
  font-size: 1.8rem;
  color: ${(props) => (props.active ? '#ffffff' : '#a2a2a2')};

  &:hover {
    background-color: ${(props) => (props.active ? '#181818' : '#2a2a2a')};
  }
`
