export const liveClassFilters = [
  {
    title: 'Professores',
    field: 'teacherId',
  },
  {
    title: 'Data Inicial',
    field: 'startDate',
    options: [],
    type: 'date',
  },
  {
    title: 'Data Final',
    field: 'endDate',
    options: [],
    type: 'date',
  },
  {
    title: 'Level',
    field: 'level',
    options: [
      { text: 'INICIANTE', value: 'BEGINNER' },
      { text: 'BAIXO INTERMEDIÁRIO', value: 'LOWER_INTERMEDIATE' },
      { text: 'INTERMEDIÁRIO', value: 'INTERMEDIATE' },
      { text: 'INTERMEDIÁRIO SUPERIOR', value: 'UPPER_INTERMEDIATE' },
      { text: 'AVANÇADO', value: 'ADVANCED' },
      { text: 'SUPERIOR AVANÇADO', value: 'UPPER_ADVANCED' },
    ],
  },
]
