import { useState } from 'react'
import { InputBox, InputBoxDynamic } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'

export default function EditLevel(props) {
  const [wasAccepted, setWasAccepted] = useState(false)
  const [payload, setPayload] = useState(props.payload)
  const [currentLevel, setCurrentLevel] = useState(props.payload?.level)

  const levels = [
    {
      value: 'BEGINNER',
      name: 'Beginner',
    },
    {
      value: 'LOWER_INTERMEDIATE',
      name: 'Lower Intermediate',
    },
    {
      value: 'INTERMEDIATE',
      name: 'Intermediate',
    },
    {
      value: 'UPPER_INTERMEDIATE',
      name: 'Upper Intermediate',
    },
    {
      value: 'ADVANCED',
      name: 'Advanced',
    },
    {
      value: 'UPPER_ADVANCED',
      name: 'Upper Advanced',
    },
  ]

  function handleLevelChange(e) {
    console.log('e', e)
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      {wasAccepted ? (
        <div>
          <h1 style={{ color: 'yellow', fontWeight: 'bold', textAlign: 'center' }}>
            Editar nível atual do aluno
          </h1>
          <InputBox style={{ padding: '20px 10px' }}>
            <select name='level' value={payload?.level} onChange={handleLevelChange}>
              {levels &&
                levels.map((level, index) => (
                  <option
                    key={index}
                    index={level.name + index}
                    disabled={currentLevel == level.value ? true : false}
                    selected={currentLevel == level.value ? true : false}
                    value={level.value}
                  >
                    {level.name}
                  </option>
                ))}
            </select>
          </InputBox>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            {currentLevel != payload?.level ? (
              <SubmitButton
                url={'/admin/student/change-level'}
                onResponse={() => props.onResponse(payload.id)}
                onError={() => props.onError()}
                payload={payload}
                isUpdate={true}
              ></SubmitButton>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <h1 style={{ color: 'yellow', fontWeight: 'bold', textAlign: 'center' }}>ATENÇÃO</h1>
          <p
            style={{
              color: 'white',
              textAlign: 'center',
              fontSize: '1.6rem',
              padding: '10px',
            }}
          >
            Essa ação impactará na plataforma do aluno (Aulas ao Vivo do novo nível serão liberadas,
            mas a quantidade de créditos será mantida). Deseja continuar?
          </p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
              <input
                style={{ cursor: 'pointer', width: '80px' }}
                type='button'
                value='NÃO'
                onClick={props.close}
              ></input>
            </InputBoxDynamic>
            <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
              <input
                style={{ cursor: 'pointer', width: '80px' }}
                type='button'
                value='SIM'
                onClick={() => setWasAccepted(true)}
              ></input>
            </InputBoxDynamic>
          </div>
        </div>
      )}
    </>
  )
}
