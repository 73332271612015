import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/pro-light-svg-icons'

import {
  ModalContainer,
  ModalBox,
  CloseButton,
  ConfirmButton,
  ModalTitleBox,
  ModalContent,
} from './styles'

export default function Modal(props) {
  return (
    <ModalContainer>
      <ModalBox width={props.width}>
        <CloseButton onClick={props.close}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <ModalTitleBox>
          <span className='modal-title'>{props.title}</span>
          <span className='modal-subtitle'>{props.subtitle}</span>
        </ModalTitleBox>
        <ModalContent>{props.children}</ModalContent>
        <ConfirmButton hide={!props.confirm} onClick={props.confirm}>
          <span>Confirmar</span>
          <FontAwesomeIcon icon={faCheck} />
        </ConfirmButton>
      </ModalBox>
    </ModalContainer>
  )
}
