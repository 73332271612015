import styled from 'styled-components'

export const AwardContainer = styled.div`
  font-size: 1.6rem;
`

export const AwardList = styled.div`
  table {
    width: 98%;
    display: table;
    color: #fff;
  }

  thead {
    tr {
      text-align: start;
    }
  }

  tbody {
    tr {
      height: 55px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .tbody-divisor {
    height: 5px;
  }
`

export const ModalContainer = styled.div`
  color: #fff;

  h3 {
    margin: auto;
    padding: 10px 0px 40px;
    color: #fff;

    span {
      color: #ffff00;
    }
  }

  .form {
    color: #fff;

    label {
      display: block;
      padding-top: 25px;
    }

    select {
      display: block;
      width: 108px;
      height: 38px;
      color: #fff;
      background-color: #424242;
    }

    .confirm-button {
      margin: 40px auto !important;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
`
