function formatDate(timeStamp) {
  if (!timeStamp) return ''

  const date = new Date(timeStamp)
  const month = date.getMonth() + 1

  let string =
    (date.getDate() > 9 ? date.getDate().toString() : '0' + date.getDate().toString()) + '/'
  string += (month > 9 ? month.toString() : '0' + month.toString()) + '/'
  string += date.getFullYear().toString()

  return string
}

function formatHour(timeStamp, unit) {
  if (!timeStamp) return ''
  const date = new Date(timeStamp)

  let string = date.getHours() > 9 ? date.getHours().toString() : '0' + date.getHours().toString()
  string += unit || ':'
  string +=
    date.getMinutes() > 9 ? date.getMinutes().toString() : '0' + date.getMinutes().toString()

  return string
}

function formatUTCHour(timeStamp) {
  if (!timeStamp) return ''

  const date = new Date(timeStamp)

  let string =
    (date.getUTCHours() > 9 ? date.getUTCHours().toString() : '0' + date.getUTCHours().toString()) +
    ':'
  string +=
    date.getUTCMinutes() > 9
      ? date.getUTCMinutes().toString()
      : '0' + date.getUTCMinutes().toString()

  return string
}

function format(timeStamp, studentLevelName, options) {
  if (!timeStamp || timeStamp === 0) return ''
  const seg = 1000
  const min = seg * 60
  const hour = min * 60
  let date = new Date(timeStamp + 3 * hour)

  let locale = 'en-US'
  if (studentLevelName === 'BEGINNER') {
    locale = 'pt-BR'
  }
  let defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'long',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
  if (options) {
    defaultOptions = options
  }
  return new Intl.DateTimeFormat(locale, defaultOptions).format(date)
}

function formatTimestampDDMMYYYYhhmmss(timeStamp) {
  if (!timeStamp || timeStamp === 0) return ''
  let date = new Date(timeStamp)

  let locale = 'pt-BR'
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Sao_Paulo',
  }
  return new Intl.DateTimeFormat(locale, options).format(date)
}

function formatTimestampDDMMYYYY(timeStamp) {
  if (!timeStamp || timeStamp === 0) return ''
  let date = new Date(timeStamp)

  let locale = 'pt-BR'
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'America/Sao_Paulo',
  }
  return new Intl.DateTimeFormat(locale, options).format(date)
}

function getTimeZoneName(timeStamp, studentLevelName) {
  let str = format(timeStamp, studentLevelName, {
    timeZoneName: 'long',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
  if (str) {
    return str.replace(new RegExp(/[\d\/-]/, 'g'), '').replace(',', '')
  }
}

module.exports = {
  formatDate,
  formatHour,
  formatUTCHour,
  format,
  getTimeZoneName,
  formatTimestampDDMMYYYYhhmmss,
  formatTimestampDDMMYYYY,
}
