import styled, { keyframes } from 'styled-components'
const LoadingAnimation = keyframes`
    0% {
      opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const Loading = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  opacity: ${(props) => (props.fade ? 0 : 1)};
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  animation: ${(props) => (props.fade ? LoadingAnimation : '')} 2s;
  & img {
    width: 7vw;
  }
`

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(600px) rotateY(20deg) rotateX(10deg);
  transform-style: preserve-3d;
  width: 300px;
  height: 300px;
`

export const LogoConquer = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(30px);
  transform-style: preserve-3d;
`
