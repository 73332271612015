import styled from 'styled-components'

export const GamificationContainer = styled.div`
  background-color: #212121;
  width: 100%;
  padding: 2%;
`

export const GamificationSection = styled.div`
  .gamification-title {
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      color: #fff;
      font-size: 2.2rem;
      padding: 0px;
    }

    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #424242;
  }
`

export const GamificationSectionRanking = styled.div`
  margin-top: 20px;
  background-color: #fff;

  .gamification-title {
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      color: #fff;
      font-size: 2.2rem;
      padding: 0px;
    }

    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #424242;
  }
`
