import styled from 'styled-components'

export const Box = styled.div`
  border: 1px solid #898989;
  margin-top: 20px;
  padding: 20px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  & > span.box-title {
    height: 20px;
    width: 80px;
    background-color: black;
    text-align: center;
    font-size: 1.5rem;
    position: absolute;
    top: -11px;
    left: calc(50% - 40px);
    color: #898989;
  }

  & > span.error {
    width: 100%;
    font-size: 1.3rem;
    color: rgba(255, 70, 70);
    text-align: center;
    padding: 0px 20px;
    margin-top: 20px;

    & > svg {
      margin-right: 5px;
    }
  }
`

export const NewAnswerBox = styled.div`
  width: 100%;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0px 15px;
`

export const NewAnswerText = styled.div`
  flex: 1 1 100%;

  & > label {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  & > input {
    display: block;
    height: 36px;
    color: white;
    width: 100%;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 10px 12px;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }
`

export const NewAnswerCorrect = styled.div`
  flex: 0 0 90px;

  & > label {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
    display: block;
  }

  & > input {
    display: block;
    align-self: center;
    margin: 8px auto;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  ${NewAnswerText} + & {
    margin-left: 10px;
  }
`

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  & > button {
    align-self: center;
    width: max-content;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    height: 20px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    &:focus {
      outline: none;
    }
  }

  & > button.new-answer {
    color: #ffff00;

    &:hover {
      background-color: rgba(255, 255, 0, 0.05);
    }
  }

  & > button.cancel {
    color: #898989;

    &:hover {
      background-color: rgba(150, 150, 150, 0.05);
    }
  }
`

export const Answer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  padding: 0px 10px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.4);
  min-height: 25px;
  color: ${(props) => (props.correct ? 'rgb(70, 255, 70)' : 'rgb(255, 70, 70)')};

  &:last-child {
    border-bottom: none;
    margin-bottom: 10px;
  }

  & > div.answer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 calc(100% - 50px);

    & > span {
      flex: 1 0 calc(100% - 30px);
      padding: 5px 10px 5px 0px;
    }

    & > svg {
      flex: 0 0 20px;
      margin-right: 10px;
    }
  }

  & > div.actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #ffff00;
    border-left: 1px solid rgba(200, 200, 200, 0.4);
    flex: 0 0 50px;

    & > svg {
      cursor: pointer;
    }
  }
`
