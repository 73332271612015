import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import DropFileZone from '../../../../../DropFileZone'
import { ButtonContainer, Error, InputBox } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'

export default function NewContentForm(props) {
  const [payload, setPayload] = useState(null)
  const [type, setType] = useState('')
  const [curationId, setCurationId] = useState(props.curationId)
  const [itemId, setItemId] = useState(props.itemId)
  const [errorMessage, setErrorMessage] = useState('')
  const [formInputs, setFormInputs] = useState()

  async function fetchItem() {
    await axios.get(`/admin/curation/item/${itemId}`).then((res) => {
      setType(res.data.type)
      setPayload(res.data)
    })
  }

  useEffect(() => {
    if (itemId != null) {
      fetchItem()
    }
  }, [itemId])

  useEffect(() => {
    if (!itemId) {
      formClear()
    }
  }, [type, itemId])

  useEffect(() => {
    if (payload) {
      renderFormInputs()
    }
  }, [payload])

  function handleTypeChange(e) {
    setType(e.target.value)
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function handleUploadedFile(e, target) {
    setPayload({
      ...payload,
      [target]: e,
    })
  }

  function formClear() {
    setPayload({
      type: type,
      name: '',
      sequence: 0,
      sourceUrl: '',
      curation: {
        id: curationId,
      },
    })
  }

  function renderFormInputs() {
    switch (payload.type) {
      case 'PDF':
        setFormInputs(
          <>
            <InputBox>
              <label>Nome</label>
              <input
                name='name'
                type='text'
                placeholder='Nome'
                value={payload.name}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Tempo</label>
              <input
                name='time'
                type='number'
                placeholder='O tempo (minutos)'
                value={payload.time}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Arquivo PDF</label>
              <DropFileZone
                validTypes={['application/pdf']}
                maxSize={1024 * 1024 * 20}
                uploadedFiles={(e) => handleUploadedFile(e, 'sourceUrl')}
              />
            </InputBox>
          </>,
        )
        break
      case 'AUDIO':
        setFormInputs(
          <>
            <InputBox>
              <label>Nome do áudio</label>
              <input
                name='name'
                type='text'
                placeholder='Nome do áudio'
                value={payload.name}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Tempo</label>
              <input
                name='time'
                type='number'
                placeholder='O tempo (minutos)'
                value={payload.time}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Link do Áudio (Spotify)</label>
              <input
                name='sourceUrl'
                type='text'
                placeholder='URL do áudio no spotify'
                value={payload.sourceUrl}
                onChange={handleChange}
              />
            </InputBox>
          </>,
        )
        break
      case 'VIDEO':
        setFormInputs(
          <>
            <InputBox>
              <label>Nome do vídeo</label>
              <input
                name='name'
                type='text'
                placeholder='Nome do vídeo'
                value={payload.name}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Tempo</label>
              <input
                name='time'
                type='number'
                placeholder='O tempo (minutos)'
                value={payload.time}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Link do Vídeo (Youtube)</label>
              <input
                name='sourceUrl'
                type='text'
                placeholder='URL do vídeo no Youtube'
                value={payload.sourceUrl}
                onChange={handleChange}
              />
            </InputBox>
          </>,
        )
        break
      default:
        setFormInputs(<></>)
    }
  }

  function validatePayload() {
    let msg = ''
    switch (type) {
      case 'PDF':
        msg = 'Por favor, insira um arquivo PDF.'
        break
      case 'VIDEO':
        msg = 'Por favor insira a url do video do youtube'
        break
      case 'AUDIO':
        msg = 'Por favor insira a url do spotify'
    }

    if (!payload.name) {
      setErrorMessage('Por favor, insira o nome.')
      return false
    }
    if (!payload.time) {
      setErrorMessage('Por favor, insira o tempo.')
      return false
    }
    if (!payload.sourceUrl) {
      setErrorMessage(msg)
      return false
    }
    if (type == 'AUDIO' || type == 'VIDEO') {
      if (payload.sourceUrl.indexOf('https://') < 0) {
        setErrorMessage('Url com formato inválido, precisa inicar com https://')
        return false
      }
    }

    if (type == 'AUDIO') {
      if (payload.sourceUrl.indexOf('spotify.com') < 0) {
        setErrorMessage('Url inválida, parece que a url não é do spotify')
        return false
      }
    }

    if (type == 'VIDEO') {
      if (payload.sourceUrl.indexOf('youtube.com') < 0) {
        setErrorMessage('Url inválida, parece que a url não é do youtube')
        return false
      }
      if (payload.sourceUrl.indexOf('watch?v=') < 0) {
        setErrorMessage('Url inválida, está faltando o parametro "watch"')
        return false
      }
    }

    setErrorMessage('')
    return true
  }

  function handleSubmitResponse(response) {
    props.showAlert({
      message: <span>Conteúdo cadastrado com sucesso!</span>,
      success: true,
    })
    props.updateData(true)
  }

  function handleSubmitError(data, status, generalMessage, request, config) {
    props.showAlert({
      message: (
        <>
          <span>Erro ao cadastrar conteúdo!</span>
          <br />
          {data && data.msg ? data.msg : generalMessage ? generalMessage : null}
        </>
      ),
      success: false,
    })
  }

  return (
    <>
      <InputBox>
        <label>Tipo</label>
        <select name='type' value={type} onChange={handleTypeChange} disabled={itemId != null}>
          <option disabled value=''>
            Selecione o Tipo do Conteúdo
          </option>
          <option value='PDF'>PDF</option>
          <option value='AUDIO'>ÁUDIO</option>
          <option value='VIDEO'>VÍDEO</option>
        </select>
      </InputBox>
      {formInputs}
      {errorMessage && (
        <Error>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </Error>
      )}
      <ButtonContainer>
        <div></div>
        <SubmitButton
          url='/admin/curation/item'
          onResponse={handleSubmitResponse}
          onError={handleSubmitError}
          payload={payload}
          isUpdate={itemId != null}
          isValid={validatePayload}
        ></SubmitButton>
      </ButtonContainer>
    </>
  )
}
