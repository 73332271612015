import styled from 'styled-components'

export const AdminContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;

  & > * {
    width: 200px;
  }
`

export const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 0px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`
