export function shouldShowButton(pageNumber, actualPage, pageTotal) {
  return (
    pageNumber === 1 ||
    pageNumber === pageTotal ||
    pageNumber === actualPage ||
    (pageNumber < actualPage && pageNumber > actualPage - 2) ||
    (pageNumber > actualPage && pageNumber < actualPage + 2) ||
    pageTotal <= 5
  )
}

export function getFilters(pages, filters, search) {
  let pageFilter = {}
  let body = {}
  let page = {}

  for (let i = 0; Array.isArray(filters) && i < filters.length; i++) {
    const f = filters[i]
    if (f.field === 'mentorId') {
      body.mentorId = f.value
    }
    if (f.field === 'startDate') {
      body.startDate = f.value
    }
    if (f.field === 'endDate') {
      body.endDate = f.value
    }
    if (f.field === 'status') {
      body.status = f.value
    }
    if (f.field === 'dayOfWeek') {
      body.dayOfWeek = f.value
    }
  }

  if (search || search !== '') {
    body.search = search
  }

  if (body || (body !== '' && page) || page !== '') {
    pageFilter.body = body
    page.pageNumber = pages
    pageFilter.page = page
  }

  return pageFilter
}
