import styled from 'styled-components'

export const HeaderMobileBox = styled.div`
  background-color: #141414;
  height: 82px;
  width: 100%;
`

export const HamburguerMenu = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin-top: 5px;
`

export const ExpandMenu = styled.div`
  background: black;
  max-width: ${(props) => (props.Open ? '100vw' : '0px')};
  opacity: ${(props) => (props.Open ? '1' : '0')};
  height: calc(100vh - 82px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.4s ease 0s;
  overflow: hidden;
  z-index: 9999;
`

export const Menubox = styled.ul`
  width: 100%;
  list-style: none;
`

export const Highlight = styled.span`
  color: #ffff00;
`

export const MenuItems = styled.li`
  color: white;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 60px;
`

export const Line = styled.span`
  display: block;
  width: 30px;
  background: #fff;
  height: 2px;
  margin: 5px 5px;
  transition: all 0.4s ease;
  z-index: 1;
`

export const FirstLine = styled(Line)`
  transform: ${(props) => (props.Open ? 'rotate(45deg) translate(0px, 0px)' : '')};
`
export const SecondLine = styled(Line)`
  transform: ${(props) => (props.Open ? 'rotate(-45deg) translate(7px,-10px);' : '')};
`
