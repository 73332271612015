import { useEffect, useState } from 'react'
import { faCheck, faEdit, faExclamation, faTimesSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubmitButtonStyle } from './styles'
import SubmitButton from '../../../../Template/SubmitButton'

const options = {
  timeZone: 'UTC',
}
const dateFormatter = new Intl.DateTimeFormat('pt-BR', options)

export default function FieldEditor(props) {
  const [editMode, setEditMode] = useState(false)
  const [payload, setPayload] = useState()
  const [newValue, setNewValue] = useState(props.label)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [dueAccess, setDueAccess] = useState(false)

  useEffect(() => {
    setPayload({
      studentId: props.userId,
      [props.field]: newValue,
    })
  }, [])

  function handleEditIcon() {
    setEditMode(!editMode)
  }

  function handleCancel() {
    setEditMode(!editMode)
    setNewValue(props.label)
  }

  function onChangeName(e) {
    if (props.field === 'dueAccess') {
      if (!props.owner) {
        alert(
          'Para alterar a data de fim da assinatura, edite o perfil do administrador dessa conta.',
        )
        return
      }

      setDueAccess(true)
    }

    setNewValue(e.target.value)

    setPayload({
      studentId: props.userId,
      [props.field]: e.target.value,
    })
  }

  function handleSelectChange(e) {
    setNewValue(e.target.value)

    setPayload({
      studentId: props.userId,
      [props.field]: e.target.value,
    })
  }

  function onSaveResponse(resp) {
    setShowSuccess(true)
    setEditMode(false)

    setTimeout(() => {
      setShowSuccess(false)
    }, 3000)
  }

  function onSaveError(err) {
    setShowError(true)
    setTimeout(() => {
      setShowError(false)
    }, 3000)
  }

  return (
    <>
      <span>
        {props.labelFormatter
          ? props.labelFormatter(newValue)
          : props.field === 'dueAccess' && dueAccess
            ? dateFormatter.format(new Date(newValue))
            : newValue}

        {!editMode ? (
          <>
            {' '}
            <FontAwesomeIcon
              icon={faEdit}
              color='yellow'
              size='lg'
              style={{ cursor: 'pointer' }}
              onClick={handleEditIcon}
            />
            {' Editar'}
          </>
        ) : (
          <>
            {' '}
            <br />
            <FontAwesomeIcon
              icon={faTimesSquare}
              color='red'
              size='lg'
              style={{ cursor: 'pointer' }}
              onClick={handleCancel}
            />
            {' Cancelar'}
          </>
        )}
      </span>

      {editMode && (
        <>
          {props.field === 'profile' ? (
            <select value={newValue} onChange={handleSelectChange}>
              <option value='ENGLISH_RECORDED'>English Online</option>
              <option value='ENGLISH_HYBRID'>English Live</option>
              <option value='ENGLISH_PREMIUM'>English Supreme</option>
            </select>
          ) : props.field === 'dueAccess' ? (
            <input type='date' value={newValue} onChange={onChangeName} />
          ) : (
            <input type='text' value={newValue} onChange={onChangeName}></input>
          )}

          <SubmitButtonStyle>
            <SubmitButton
              isUpdate={true}
              url={`/admin-cx/student/change-${props.field}`}
              payload={payload}
              onResponse={onSaveResponse}
              onError={onSaveError}
            />
          </SubmitButtonStyle>

          {showError && (
            <>
              <span style={{ color: 'red' }}>
                Problema ao salvar
                {'  '}
                <FontAwesomeIcon icon={faExclamation} color='red' size='lg' />
              </span>
            </>
          )}
        </>
      )}
      {showSuccess && (
        <>
          <span style={{ color: 'green' }}>
            Salvo com sucesso
            {'  '}
            <FontAwesomeIcon icon={faCheck} color='green' size='lg' />
          </span>
        </>
      )}
    </>
  )
}
