import styled from 'styled-components'

export const MainContainer = styled.div`
  border: solid 1px red;
`

export const SubmitButtonStyle = styled.div`
  & > button {
    width: 60% !important;
    height: 50% !important;
    font-size: 1.5rem;
    margin-top: 15px;
  }
`
