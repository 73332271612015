import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import DropFileZone from '../../../../../DropFileZone'
import { ButtonContainer, Error, InputBox } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'

export default function NewContentForm(props) {
  const [payload, setPayload] = useState(null)
  const [contentType, setContentType] = useState('')
  const [lesson, setLesson] = useState({ id: props.lessonId })
  const [contentId, setContentId] = useState(props.contentId)
  const [errorMessage, setErrorMessage] = useState('')
  const [formInputs, setFormInputs] = useState()

  async function fetchContent() {
    await axios.get(`/admin/content/${contentId}`).then((res) => {
      setContentType(res.data.payload.contentType)
      let respPayload = res.data.payload
      respPayload.lesson = {
        moduleId: props.moduleId,
      }
      setPayload(respPayload)
    })
  }

  useEffect(() => {
    if (contentId) {
      fetchContent()
    }
  }, [contentId])

  useEffect(() => {
    if (!contentId) {
      formClear()
    }
  }, [contentType, contentId])

  useEffect(() => {
    if (payload) {
      renderFormInputs()
    }
  }, [payload])

  function handleTypeChange(e) {
    setContentType(e.target.value)
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function handleUploadedFile(e, target) {
    setPayload({
      ...payload,
      [target]: e,
    })
  }

  function formClear() {
    switch (contentType) {
      case 'EBOOK':
        setPayload({
          contentType: 'EBOOK',
          name: 'Ebook',
          description: 'Ebook',
          sequence: 6,
          source: '',
          thumb: '',
          xp: 20,
          lesson: {
            id: lesson.id,
            moduleId: props.moduleId,
          },
        })

        break
      case 'VIDEO':
        setPayload({
          contentType: 'VIDEO',
          name: '',
          description: '',
          sequence: '',
          source: '',
          thumb: '',
          xp: 15,
          lesson: {
            id: lesson.id,
            moduleId: props.moduleId,
          },
        })

        break
      default:
        setPayload({ contentType: '' })
    }
  }

  function renderFormInputs() {
    switch (payload.contentType) {
      case 'EBOOK':
        setFormInputs(
          <>
            <InputBox>
              <label>Arquivo do E-Book (PDF)</label>
              <DropFileZone
                validTypes={['application/pdf']}
                maxSize={1024 * 1024 * 20}
                uploadedFiles={(e) => handleUploadedFile(e, 'source')}
              />
              <label>Arquivo da capa do E-Book (jpg)</label>
              <DropFileZone
                validTypes={['image/jpeg', 'image/jpg', 'image/png']}
                maxSize={1024 * 1024 * 20}
                uploadedFiles={(e) => handleUploadedFile(e, 'thumb')}
              />
            </InputBox>
          </>,
        )
        break
      case 'VIDEO':
        setFormInputs(
          <>
            <InputBox>
              <label>Nome do Step</label>
              <input
                name='name'
                type='text'
                placeholder='Nome do Step'
                value={payload.name}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Descrição do Step</label>
              <input
                name='description'
                type='text'
                placeholder='Descrição do Step'
                value={payload.description}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Sequência</label>
              <input
                name='sequence'
                type='text'
                placeholder='Sequência'
                value={payload.sequence}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Link do Vídeo (Vimeo)</label>
              <input
                name='source'
                type='text'
                placeholder='URL do Vídeo no Vimeo'
                value={payload.source}
                onChange={handleChange}
              />
            </InputBox>
            <InputBox>
              <label>Imagem de Capa (Thumb)</label>
              <DropFileZone
                validTypes={['image/jpeg', 'image/jpg', 'image/png']}
                maxSize={1024 * 1024 * 20}
                uploadedFiles={(e) => handleUploadedFile(e, 'thumb')}
              />
            </InputBox>
          </>,
        )
        break
      default:
        setFormInputs(<></>)
    }
  }

  function handleSubmitResponse(response) {
    props.showAlert({
      message: <span>Conteúdo cadastrado com sucesso!</span>,
      success: true,
    })
    props.updateData(true, lesson)
  }

  function handleSubmitError(data, status, generalMessage, request, config) {
    props.showAlert({
      message: (
        <>
          <span>Erro ao cadastrar conteúdo!</span>
          <br />
          {data && data.msg ? data.msg : generalMessage ? generalMessage : null}
        </>
      ),
      success: false,
    })
    props.updateData(true)
  }

  return (
    <>
      <InputBox>
        <label>Tipo</label>
        <select
          name='contentType'
          value={contentType}
          onChange={handleTypeChange}
          disabled={contentId != null}
        >
          <option disabled value=''>
            Selecione a Tipo do Conteúdo
          </option>
          <option value='EBOOK'>E-Book</option>
          <option value='VIDEO'>Vídeo aula</option>
        </select>
      </InputBox>
      {formInputs}
      {errorMessage && (
        <Error>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </Error>
      )}
      <ButtonContainer>
        <div></div>
        <SubmitButton
          url='/admin/content'
          onResponse={handleSubmitResponse}
          onError={handleSubmitError}
          payload={payload}
          isUpdate={contentId != null}
        ></SubmitButton>
      </ButtonContainer>
    </>
  )
}
