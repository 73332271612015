import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Lottie from 'react-lottie'

import {
  Container,
  ContainerFlexWrap,
  Tabs,
  EditButton,
  TableHeader,
  NewButton,
  HeaderSpacer,
  InputBoxDynamic,
  SearchField,
} from '../../styles'
import { LoadingContainer } from './styles'
import Modal from '../../Template/Modal'
import Alert from '../../Template/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faEye, faSearch } from '@fortawesome/pro-solid-svg-icons'
import ConquerTablePaginated from '../../Template/ConquerTablePaginated'
import NewUserForm from './components/NewUserForm'
import loadingData from '../../../../assets/json/loading.json'

export default function AdminUsers() {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubtitle, setModalSubtitle] = useState('')
  const [formComponent, setFormComponent] = useState()
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [loadingTable, setLoadingTable] = useState(true)
  const [itemsPerPage, setItemsPerPage] = useState(18)
  const [totalNumRecords, setTotalNumRecords] = useState()
  const [totalNumPages, setTotalNumPages] = useState()
  const [selectedUser, setSelectedUser] = useState(false)
  const [selectedUserData, setSelectedUserData] = useState()
  const [selectedTab, setSelectedTab] = useState('dados')
  const [enableMentoring, setEnableMentoring] = useState()
  const [isAdmin, setIsAdmin] = useState()
  const [enableUser, setEnableUser] = useState()
  const [loadingSelectedUser, setLoadingSelectedUser] = useState(true)
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const [resetFilter, setResetFilter] = useState(false)

  const userColumns = React.useMemo(() => [
    { title: 'Nome', field: 'name' },
    { title: 'E-mail', field: 'email' },
    {
      title: 'Conta Ativa?',
      field: 'enabled',
      content: (v) =>
        v.enabled ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />,
      props: {
        center: true,
        flex: '0 0 50px',
      },
    },
    {
      title: 'Ver mais...',
      id: 'actions',
      content: (v) => (
        <EditButton onClick={() => handleUserSelect(v)}>
          <FontAwesomeIcon icon={faEye} />
        </EditButton>
      ),
      width: 20,
    },
  ])

  useEffect(() => {
    let isMounted = true
    getData(isMounted)
    return () => (isMounted = false)
  }, [])

  async function getData(isMounted, loadPage) {
    setLoadingTable(true)
    const page = loadPage ? loadPage : 0

    await axios
      .get('/admin/user/metadata?page=' + page + '&pageSize=' + itemsPerPage + '&search=' + search)
      .then((res) => {
        setTotalNumRecords(res.data.payload.totalNumUsers)
        setTotalNumPages(res.data.payload.totalNumPages)
      })
    await axios
      .get('/admin/user?page=' + page + '&pageSize=' + itemsPerPage + '&search=' + search)
      .then((res) => {
        if (isMounted) {
          setData(
            res.data.payload.sort(function (a, b) {
              if (a.name > b.name) return 1
              if (a.name < b.name) return -1
              return 0
            }),
          )
          setLoadingTable(false)
        }
      })
  }

  useEffect(() => {
    if (selectedUser) {
      getUserById(selectedUser.id)
    }
  }, [selectedUser])

  useEffect(() => {
    if (search || resetFilter) {
      getData(true, 0)
      setResetFilter(false)
    }
  }, [search, resetFilter])

  async function getUserById(userId) {
    setLoadingTable(true)
    await axios
      .get(`/admin/user/${userId}`)
      .then((res) => {
        setSelectedUserData(res.data.payload)
        setLoadingTable(false)
        setLoadingSelectedUser(false)
      })
      .catch((error) => {
        setLoadingTable(false)
      })
  }

  function newData() {
    const payload = {
      name: '',
      cpf: '',
      email: '',
      password: '',
      codeAcquisition: '',
      student: false,
      admin: true,
      level: '',
      classId: '',
      enabledMentoring: false,
    }

    setFormComponent(
      <NewUserForm
        payload={payload}
        showAlert={handleAlert}
        close={handleClose}
        updateData={getData}
      />,
    )

    setModalTitle('Novo usuário')
    setModalSubtitle('')
    setShowModal(true)
  }

  function openTab(tab) {
    if (tab === 'dados') {
      setSelectedTab('dados')
    } else if (tab === 'mentoria') {
      setSelectedTab('mentoria')
    } else if (tab === 'reviews') {
      setSelectedTab('reviews')
    }
  }

  function handleClose() {
    setShowModal(false)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handlePageChange(page) {
    getData(true, page)
  }

  function handleUserSelect(e) {
    setSelectedUser(e)
  }

  function handleProfileChange(e) {
    setIsAdmin(e.target.value)
  }

  function handleMentoringChange(e) {
    setEnableMentoring(e.target.value)
  }

  function handleEnableUserChange(e) {
    setEnableUser(e.target.value)
  }

  useEffect(() => {
    handleProfileUpdate()
  }, [isAdmin])

  useEffect(() => {
    handleMentoringUpdate()
  }, [enableMentoring])

  useEffect(() => {
    handleEnableUserUpdate()
  }, [enableUser])

  function handleProfileUpdate() {
    if (!selectedUser) {
      return
    }
    const payload = {
      email: selectedUser.email,
      admin: isAdmin,
    }
    axios
      .post('/admin/user/profile/' + selectedUser.id, payload)
      .then((res) => {
        let userUpdate = { ...selectedUserData }
        userUpdate.admin = isAdmin
        setSelectedUserData(userUpdate)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleMentoringUpdate() {
    if (!selectedUser) {
      return
    }
    const payload = {
      email: selectedUser.email,
      enabledMentoring: enableMentoring,
    }
    axios
      .post('/admin/user/monitoring/' + selectedUser.id, payload)
      .then((res) => {
        let userUpdate = { ...selectedUserData }
        userUpdate.enabledMentoring = enableMentoring
        setSelectedUserData(userUpdate)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleEnableUserUpdate() {
    if (!selectedUser) {
      return
    }
    const payload = {
      email: selectedUser.email,
      enabled: enableUser,
    }
    axios
      .post('/admin/user/enable/' + selectedUser.id, payload)
      .then((res) => {
        let userUpdate = { ...selectedUserData }
        userUpdate.enabled = enableUser
        setSelectedUserData(userUpdate)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function clearFilter() {
    setFilter('')
    setSearch('')
    setResetFilter(true)
  }

  function searchByFilter() {
    setSearch(filter)
  }

  function handleFilterChange(e) {
    setFilter(e.target.value)
  }

  function handleFilterKeyPress(e) {
    if (e.key === 'Enter') setSearch(filter)
  }

  async function handleDefaultPasswordReset() {
    const payload = {
      email: selectedUserData.email,
    }

    axios
      .post('/admin/user/reset-password-default', payload)
      .then((res) => {
        setIsPasswordResetSuccess(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  let users = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span>Usuários do sistema</span>
          <div>
            <HeaderSpacer />
            <SearchField>
              <input
                type='text'
                placeholder='Pesquisar'
                value={filter}
                onChange={handleFilterChange}
                onKeyPress={handleFilterKeyPress}
              />
              <button onClick={() => searchByFilter()}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
              {search && search !== '' && (
                <a onClick={() => clearFilter()}>
                  <FontAwesomeIcon icon={faTimes} />
                  Limpar pesquisa
                </a>
              )}
            </SearchField>
            <HeaderSpacer />
            <NewButton onClick={() => newData()}>Convidar novo conquer</NewButton>
          </div>
        </TableHeader>
        <ConquerTablePaginated
          columns={userColumns}
          data={data}
          totalNumPages={totalNumPages}
          loading={loadingTable}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  )

  let userView = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span>Usuário</span>
        </TableHeader>
        <Tabs>
          <div className='actions'>
            <button
              className={selectedTab === 'dados' ? 'selectedTab' : ''}
              onClick={() => openTab('dados')}
            >
              Dados
            </button>
          </div>
          <div className='line'></div>
        </Tabs>

        {loadingSelectedUser ? (
          <LoadingContainer colSpan={100}>
            <Lottie options={loadingOptions} height={298} width={298} />
          </LoadingContainer>
        ) : (
          <>
            <ContainerFlexWrap hide={selectedTab !== 'dados'}>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Nome</label>
                <span>{selectedUserData?.name}</span>
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>E-mail</label>
                <span>{selectedUserData?.email}</span>
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Data do cadastro</label>
                <span>{selectedUserData?.createdAt}</span>
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Permissões</label>
                <select
                  name='perfil'
                  value={selectedUserData?.admin}
                  onChange={handleProfileChange}
                >
                  <option value={true}>Administrador</option>
                </select>
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Último acesso</label>
                <span>{selectedUserData?.lastAccess}</span>
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='0 1 25%'>
                <label>Ativo</label>
                <select
                  name='enable'
                  value={selectedUserData?.enabled}
                  onChange={handleEnableUserChange}
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </select>
              </InputBoxDynamic>
            </ContainerFlexWrap>
            <ContainerFlexWrap hide={selectedTab !== 'mentoria'}></ContainerFlexWrap>
            <ContainerFlexWrap hide={selectedTab !== 'reviews'}></ContainerFlexWrap>
            <ContainerFlexWrap>
              {isPasswordResetSuccess ? (
                <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={'green'}
                    style={{ fontSize: '20px', margin: '10px' }}
                  />
                  <label>
                    Senha resetado com successo!
                    <br />
                    (senha padrão Conquer01)
                  </label>
                </InputBoxDynamic>
              ) : (
                <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
                  <span>
                    Resetar senha manualmente
                    <br />
                    (senha padrão Conquer01)
                  </span>
                  <input
                    type='button'
                    value='Resetar para senha padrão'
                    onClick={() => handleDefaultPasswordReset()}
                  ></input>
                </InputBoxDynamic>
              )}
            </ContainerFlexWrap>
          </>
        )}
      </Container>
    </>
  )

  return (
    <>
      {selectedUser ? userView : users}
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />
    </>
  )
}
