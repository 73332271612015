import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs'
import { NewsForm, InputBox } from '../../styles'

import draftToHtml from 'draftjs-to-html'

import { Container, ConfirmButton } from '../../../../styles'
import axios from 'axios'

export default function NewsInputForm(props) {
  const [payload, setPayload] = useState(null)
  const [errorMessage, setFeedbackRequest] = useState()
  const [updateNews, setUpdateNews] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const toolbarConfig = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'image',
      'remove',
      'history',
    ],
    colorPicker: {
      colors: [
        '#FFF',
        '#000',
        '#00000069',
        '#161C24',
        '#212B36',
        '#63409A',
        '#63409A',
        '#C9DC44',
        '#141414',
        '#b4b4b4',
        '#8D8D8D',
        '#707070',
        '#545454',
        '#3D3D3D',
        '#2A2A2A',
        '#252525',
        '#262626',
        '#4F4F4F',
        '#4F63F0',
        '#6274F6',
        '#5121F4',
        '#2A9235',
        '#00a656',
        '#2EF142',
        '#FFFF00',
        '#f95252',
        '#664d03',
        '#fff3cd',
        '#f4c154',
        '#CD7F32',
      ],
    },
  }

  const handleEditorChange = (editorState) => {
    setEditorState(editorState)
    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setPayload((prevState) => ({
      ...prevState,
      description: description,
    }))
  }

  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function handleSubmit(e) {
    e.preventDefault()

    let request = {
      title: payload.title,
      description: payload.description,
    }

    if (updateNews) {
      request = {
        ...request,
        id: payload.id,
      }
      axios
        .put(`/news`, request)
        .then((res) => {
          window.location.reload()
        })
        .catch((err) => {
          setFeedbackRequest(
            <span>
              Erro ao cadastrar novidade:
              <br />
              {err.message}
            </span>,
          )
        })
    } else {
      axios
        .post('/news', request)
        .then((res) => {
          window.location.reload()
        })
        .catch((err) => {
          setFeedbackRequest(
            <span>
              Erro ao cadastrar novidade:
              <br />
              {err.message}
            </span>,
          )
        })
    }
  }

  useEffect(() => {
    if (props.payload) {
      setPayload({
        id: props.payload.id,
        title: props.payload.title,
        description: props.payload.description,
      })
      const blocksFromHTML = htmlToDraft(props.payload.description || '')
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      )
      setEditorState(EditorState.createWithContent(contentState))

      setUpdateNews(true)
    }
  }, [props.payload])

  return (
    <Container>
      <>
        <NewsForm>
          <InputBox>
            <label>Título (não fica visível na plataforma do aluno)</label>
            <br />
            <input
              name='title'
              type='text'
              placeholder='Titulo'
              value={payload?.title}
              onChange={handleChange}
            />
            <label>Descrição</label>
            <Editor
              editorState={editorState}
              toolbar={toolbarConfig}
              onEditorStateChange={handleEditorChange}
              toolbarClassName='editor-toolbar'
              wrapperClassName='editor-wrapper'
              editorClassName='editor-textarea'
              readOnly={false}
            />
          </InputBox>
          <ConfirmButton onClick={(e) => handleSubmit(e)}>
            <span>Confirmar</span>
            <FontAwesomeIcon icon={faCheck} />
          </ConfirmButton>
          <Container>{errorMessage}</Container>
        </NewsForm>
      </>
    </Container>
  )
}
