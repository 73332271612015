import styled from 'styled-components'

export const Main = styled.div`
  padding-top: 10%;
  padding-bottom: 10%;

  .checkbox {
    display: flex;
  }
  .row-buttons {
    margin-top: 5%;
    display: flex;
  }
`
export const SelectStudent = styled.select`
  padding-top: 3%;
  padding-bottom: 3%;
  background: #323232;
  border: 1px solid ${(props) => (props.notValid ? 'red' : '#898989')};
  border-radius: 6px;
  opacity: 1;
  color: #898989;
  outline: none;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  margin-top: 5%;
`
export const CheckBox = styled.input``

export const Option = styled.option`
  color: #898989;
`

export const SubTitleModule = styled.h4`
  font-size: 2.4rem;
  color: white;
  margin: 0px;
  font-weight: 300;
  margin-top: 5%;
`
