import styled from 'styled-components'

export const Button = styled.button`
  background-color: rgba(255, 255, 0, ${(props) => (props.disabled ? '0.4' : '1')});
  height: 44px;
  width: 106px;
  border: none;
  border-radius: 53px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 19px;
  padding: 0px 28px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & > svg {
    margin-top: 2px;
  }

  @media (max-width: 599px) {
    height: 30px;
    width: 65px;
    font-size: 14px;
    padding: 0px 14px;

    & > svg {
      height: 8px;
    }
  }
`
