import { React, useEffect, useState } from 'react'

import Modal from '../../../../Template/Modal'
import { Title, Body, Button, ButtonsContainer, AlertDanger, AlertSuccess } from './style'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

import axios from 'axios'

export default function ModalDeleteConfirmationAlternative(props) {
  const [icon, setIcon] = useState(faCheck)
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(props.message || '')
  const [showTitle, setShowTitle] = useState(true)
  const [confirmButtonText, setConfirmButtonText] = useState('Confirmar')

  useEffect(() => {
    setDisabled(false)
    setLoading(false)
    setShowTitle(true)
  }, [])

  function handleConfirmation() {
    processingStart()
    setTimeout(() => {
      doDeleteRequest()
    }, 2000)
  }

  function doDeleteRequest() {
    axios({
      url: props.url,
      method: 'DELETE',
    })
      .then((resp) => {
        processingFinish()
        setMessage(<AlertSuccess>{'Registro apagado com sucesso!'}</AlertSuccess>)
        if (props.handleClose) {
          props.handleClose()
        }
        if (props.onSuccess) {
          props.onSuccess()
        }
        if (props.onResponse) {
          props.onResponse(resp.data)
        }
      })
      .catch((error) => {
        let data = null
        let status = null
        let generalMessage = ''
        let request = null
        if (error.response) {
          data = error.response.data.msg.split('"msg":"')[1].split('"}')[0]
          status = error.response.status
        } else if (error.request) {
          request = error.request
        } else {
          generalMessage = error.message
        }

        processingFinish()
        setMessage(
          <AlertDanger>
            <span>{'Não foi possível apagar.'}</span>
            <br />
            {data || ''}
          </AlertDanger>,
        )
      })
  }

  function processingStart() {
    setDisabled(true)
    setLoading(true)
    setConfirmButtonText('Apagando')
    setMessage('')
    setIcon(faSpinnerThird)
    setShowTitle(true)
  }

  function processingFinish() {
    setDisabled(false)
    setLoading(false)
    setConfirmButtonText('Confirmar')
    setIcon(faCheck)
    setShowTitle(false)
  }

  return (
    <>
      <Modal close={props.handleClose} title={props.title} subtitle={props.subtitle}>
        <Title hidden={!showTitle}>Tem certeza que deseja apagar este item?</Title>
        {message ? <Body>{message}</Body> : null}
        <ButtonsContainer>
          <Button onClick={props.handleClose} disabled={disabled}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmation} disabled={disabled}>
            {confirmButtonText} <FontAwesomeIcon icon={icon} className={loading ? 'fa-spin' : ''} />
          </Button>
        </ButtonsContainer>
      </Modal>
    </>
  )
}
