import { useEffect, useState, useRef } from 'react'
import { SelectTeacher } from '../FilterLiveClass/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import {
  FilterContainer,
  FilterButton,
  FilterBox,
  FilterList,
  FilterChip,
  FilterChipButton,
  FilterChipsContainer,
  FilterItem,
} from '../../../../Template/Filter/styles'
import DatePicker from 'react-multi-date-picker'

export default function FilterLiveClass({ data, filters, result, teacherList }) {
  const [filtersLists, setFiltersLists] = useState([])
  const [showOptionsBox, setShowOptionsBox] = useState(false)
  const [mentorSelected, setMentorSelected] = useState()
  const [filteredData, setFilteredData] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [activeFilters, setActiveFilters] = useState([])
  const [activeFiltersChips, setActiveFiltersChips] = useState([])
  const box = useRef()

  useEffect(() => {
    if (activeFilters.length > 0) {
      result(filteredData)
    } else {
      result(null)
    }
  }, [filteredData])

  useEffect(() => {
    renderActiveFiltersChips()
    filterData()
  }, [activeFilters])

  useEffect(() => {
    renderFiltersLists()
  }, [filters, activeFilters])

  useEffect(() => {
    mentorSelected && handleFilterClick(mentorSelected, 'teacherId')
  }, [mentorSelected])

  useEffect(() => {
    startDate && handleFilterClick(startDate, 'startDate')
    endDate && handleFilterClick(endDate, 'endDate')
  }, [endDate])

  useEffect(() => {
    renderActiveFiltersChips()
    filterData()
  }, [activeFilters])

  useEffect(() => {
    if (showOptionsBox) {
      document.addEventListener('mousedown', handleClickOutsideBox)
    } else {
      document.removeEventListener('mousedown', handleClickOutsideBox)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBox)
    }
  }, [showOptionsBox])

  function filterData() {
    setFilteredData(activeFilters)
  }

  function renderActiveFiltersChips() {
    const chips = activeFilters.map((activeFilter) => generateChip(activeFilter))
    setActiveFiltersChips(chips)
  }

  function handleClickOutsideBox(e) {
    if (box.current.contains(e.target)) {
      return
    }

    setShowOptionsBox(false)
  }

  function handleFilterClick(filter, field) {
    if (isFilterActive(filter)) {
      removeFilter(filter)
    } else {
      addFilter(filter, field)
    }

    setShowOptionsBox(false)
  }

  function addFilter(filter, field) {
    let actualFilter = activeFilters.find((activeFilter) => activeFilter.field === field)
    if (actualFilter) {
      removeFilter(actualFilter)
    }

    if (field === 'teacherId') {
      const teacherName = teacherList.filter((t) => t.id === filter)[0].name.split(' ')[0]
      setActiveFilters((activeFilters) => [
        ...activeFilters,
        { field: field, text: teacherName, value: filter },
      ])
    } else if (field === 'startDate') {
      setActiveFilters((activeFilters) => [
        ...activeFilters,
        { field: field, text: filter, value: filter },
      ])
    } else if (field === 'endDate') {
      setActiveFilters((activeFilters) => [
        ...activeFilters,
        { field: field, text: filter, value: filter },
      ])
    } else {
      setActiveFilters((activeFilters) => [...activeFilters, { ...filter, field }])
    }
  }

  function removeFilter(filter) {
    if (filter.field === 'teacherId') setMentorSelected(null)
    if (filter.field === 'startDate') setStartDate(null)
    if (filter.field === 'endDate') setEndDate(null)

    setActiveFilters((activeFilters) =>
      activeFilters.filter((activeFilter) => activeFilter.value !== filter.value),
    )
  }

  function isFilterActive(filter) {
    return activeFilters.some((activeFilter) => activeFilter.value === filter.value)
  }

  function renderFiltersLists() {
    setFiltersLists(
      filters.map((filter, index) => (
        <FilterList key={index}>
          <FilterItem className='filter-title'>{filter.title}</FilterItem>
          {filter.field === 'startDate' ? (
            <>
              <div className='input-date'>
                <DatePicker
                  style={{ marginLeft: '11%', fontSize: '1vw', width: '100%', maxWidth: '200px' }}
                  format={'DD/MM/YYYY'}
                  value={startDate}
                  onFocusedDateChange={(e) => setStartDate(e.toString())}
                />
              </div>
            </>
          ) : filter.field === 'endDate' ? (
            <>
              <div className='input-date'>
                <DatePicker
                  style={{ marginLeft: '11%', fontSize: '1vw', width: '100%', maxWidth: '200px' }}
                  format={'DD/MM/YYYY'}
                  value={endDate}
                  onFocusedDateChange={(e) => setEndDate(e.toString())}
                />
              </div>
            </>
          ) : filter.field === 'teacherId' ? (
            <SelectTeacher
              className='filter-option'
              style={{ width: '60%' }}
              value={mentorSelected}
              onChange={(e) => setMentorSelected(e.target.value)}
            >
              <option className='option' style={{ width: '50%' }} value={null}></option>
              {teacherList &&
                teacherList?.map((t) => (
                  <option key={t.id} className='option' value={t.id} name={t.name}>
                    {' '}
                    {t.name}{' '}
                  </option>
                ))}
            </SelectTeacher>
          ) : (
            <div>
              {filter.options &&
                filter.options.map((option, index) => (
                  <FilterItem
                    key={index}
                    className='filter-option'
                    onClick={() => handleFilterClick(option, filter.field)}
                    active={isFilterActive(option)}
                  >
                    {option.text}
                    <FontAwesomeIcon icon={faCheck} />
                  </FilterItem>
                ))}
            </div>
          )}
        </FilterList>
      )),
    )
  }

  function generateChip(filter) {
    return (
      <FilterChip key={filter.value}>
        {filter.text}
        <FilterChipButton onClick={() => removeFilter(filter)}>
          <FontAwesomeIcon icon={faTimes} />
        </FilterChipButton>
      </FilterChip>
    )
  }

  return (
    <FilterContainer>
      <div ref={box}>
        <FilterButton onClick={() => setShowOptionsBox(!showOptionsBox)}>Filtrar</FilterButton>
        {showOptionsBox && <FilterBox> {filtersLists} </FilterBox>}
      </div>
      <FilterChipsContainer>{activeFiltersChips}</FilterChipsContainer>
    </FilterContainer>
  )
}
