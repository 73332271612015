import styled from 'styled-components'

export const Title = styled.span`
  display: block;
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`

export const SubmitButton = styled.button`
  border: none;
  background-color: #ffff00;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 6px;
  width: 110px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 32px;
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      bottom: 0;
      right: 0;
      left: -5px;
      border-radius: 32px;
      border: 5px solid rgba(200, 200, 200, 0.4);
      width: 120px;
      height: 50px;
    }
  }
`

export const BackButton = styled(SubmitButton)`
  background-color: transparent;
  color: #ffff00;
  width: 80px;

  &:hover,
  &:focus {
    outline: none;
    background-color: rgba(200, 200, 200, 0.3);

    &::after {
      display: none;
    }
  }
`

export const Error = styled.span`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  color: rgba(255, 70, 70);
  text-align: center;
  padding: 0px 20px;
  margin-top: 20px;

  & > svg {
    margin-right: 5px;
  }
`
