import styled from 'styled-components'

export const Box = styled.div`
  height: max-content;
  width: 100%;
  background-color: #323232;
  padding: 5px;
`

export const DropContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px 0px 20px 0px;
  background-color: transparent;
  border: 1px dashed
    ${(props) => (props.draggingFile ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => (props.draggingFile ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)')};
  cursor: ${(props) => (props.success ? 'default' : 'pointer')};

  & > div {
    pointer-events: none;
  }

  & > span {
    font-size: 1.3rem;
    pointer-events: none;
    width: 100%;
    padding: 0px 20px;
    text-align: center;
  }

  & > span.error {
    color: rgb(255, 70, 70);
    margin-top: 10px;
  }

  & > input[type='file'] {
    display: none;
  }
`

export const ActionBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 0px 20px;
  pointer-events: auto !important;

  & > a {
    font-size: 1.3rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
