import React, { useEffect, useState } from 'react'
import { Alert, Message, TextXp } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faExclamation, faCheck } from '@fortawesome/pro-solid-svg-icons'

export default function AlertBox(props) {
  const [hide, setHide] = useState(true)
  const [message, setMessage] = useState()
  const [animationTime, setAnimationTime] = useState(5)

  function closeAlert() {
    setHide(true)
    props.animationEnded(true)
  }

  useEffect(() => {
    if (props.animationTime > 0) {
      setAnimationTime(props.animationTime)
    } else {
      setAnimationTime(5)
    }
  }, [props.animationTime])

  useEffect(() => {
    if (props.hide != null && !props.hide) {
      setHide(props.hide)
    }
  }, [props.hide])

  useEffect(() => {
    if (props.message) {
      setMessage(props.message)
    }
  }, [props.message])

  useEffect(() => {
    if (hide != null && !hide) {
      setTimeout(function () {
        setHide(true)

        //                props.animationEnded(true);
      }, animationTime * 1000)
    }
  }, [hide])

  return (
    <>
      <Alert
        id={'alerta'}
        className={`${hide ? 'esconde' : 'mostrar'}`}
        hide={false}
        animationTime={animationTime + 's'}
      >
        {props.success ? (
          <FontAwesomeIcon icon={faCheck} color={'green'} />
        ) : (
          <FontAwesomeIcon icon={faExclamation} color={'red'} />
        )}
        <Message>{message}</Message>
        <FontAwesomeIcon
          icon={faTimes}
          color={'#FFF'}
          style={{ fontSize: '12px', marginLeft: 'auto', cursor: 'pointer' }}
          onClick={() => closeAlert()}
        />
      </Alert>
    </>
  )
}
