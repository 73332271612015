import React, { useState, useRef } from 'react'

import { Box, DropContainer, ActionBox } from './styles'

import Lottie from 'react-lottie'
import UploadData from '../../assets/json/upload.json'
import SuccessData from '../../assets/json/upload-success.json'

export default function DropFileZone(props) {
  const [draggingFile, setDraggingFile] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [uploadOptions, setUploadOptions] = useState({
    loop: true,
    autoplay: false,
    animationData: UploadData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  const fileInputRef = useRef()

  const validateQuantity = (files) => {
    if (files.length > 1 && !props.multiple) {
      setErrorMessage(<span className='error'>Você deve inserir apenas 1 arquivo.</span>)
      setError(true)
      return false
    } else {
      return true
    }
  }

  const validateSize = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (props.maxSize && files[i].size > props.maxSize) {
        setErrorMessage(
          <span className='error'>
            O arquivo '{files[i].name}' é maior que o tamanho permitido (
            {props.maxSize / 1024 / 1024} MB).
          </span>,
        )
        setError(true)
        return false
      }
    }

    return true
  }

  const validateTypes = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (!props.validTypes.some((validType) => files[i].type === validType)) {
        setErrorMessage(
          <span className='error'>
            O arquivo <strong>{files[i].name}</strong> não é de um tipo válido.
          </span>,
        )
        setError(true)
        return false
      }
    }

    return true
  }

  const handleFiles = (files) => {
    if (validateQuantity(files) && validateSize(files) && validateTypes(files)) {
      setErrorMessage('')
      setError(false)
      setUploadOptions({
        ...uploadOptions,
        animationData: SuccessData,
        loop: false,
      })
      sendFiles(files)

      setTimeout(() => {
        setSuccessMessage(
          files.length > 1 ? (
            <span>
              <strong>{files.length}</strong> arquivos adicionados com sucesso!
            </span>
          ) : (
            <span>
              Arquivo <strong>{files[0].name}</strong> adicionado com sucesso!
            </span>
          ),
        )
        setSuccess(true)
      }, 2000)
    }
  }

  const handleClick = () => {
    if (!success) fileInputRef.current.click()
  }

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      setDraggingFile(true)
      handleFiles(fileInputRef.current.files)
    }
  }

  const sendFiles = (files) => {
    if (props.multiple) {
      for (let i = 0; i < files.length; i++) {
        const filesToSend = []
        toBase64(files[0])
          .then((res) => filesToSend.push(res))
          .catch((err) => {
            setErrorMessage(<span className='error'>{err}</span>)
            setError(true)
            return
          })

        props.uploadedFiles(filesToSend)
      }
    } else {
      toBase64(files[0])
        .then((res) => props.uploadedFiles(res))
        .catch((err) => {
          setErrorMessage(<span className='error'>{err}</span>)
          setError(true)
        })
    }
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const clearFiles = () => {
    setSuccess(false)
    setSuccessMessage('')
    setDraggingFile(false)
    setUploadOptions({
      ...uploadOptions,
      animationData: UploadData,
      loop: true,
    })
    fileInputRef.current.value = null
    props.uploadedFiles(null)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    setDraggingFile(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDraggingFile(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length && !success) {
      handleFiles(files)
    }
  }

  return (
    <Box>
      <DropContainer
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        draggingFile={draggingFile}
        success={success}
      >
        <Lottie
          options={uploadOptions}
          height={75}
          width={75}
          isStopped={false}
          isPaused={!draggingFile}
          isClickToPauseDisabled
        />
        {success ? (
          successMessage
        ) : (
          <span>
            <strong>Clique</strong> para selecionar um arquivo ou <strong>arraste-o</strong> até
            aqui.
          </span>
        )}
        {success ? (
          <ActionBox>
            <a onClick={() => clearFiles()}>Remover</a>
          </ActionBox>
        ) : (
          <></>
        )}
        {error ? errorMessage : <></>}
        <input ref={fileInputRef} type='file' multiple={props.multiple} onChange={filesSelected} />
      </DropContainer>
    </Box>
  )
}
