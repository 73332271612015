import styled from 'styled-components'

export const NewsForm = styled.form`
  display: flex;
  flex-direction: column;

  color: #fff;
  font-size: 1.8em;
  padding: 6%;

  .editor-toolbar {
    border-radius: 8px 8px 0px 0px;
    background-color: #545454;
    color: #000;
    margin-bottom: 0px;

    border: 2px solid #ababab;
  }

  .editor-wrapper {
    border-radius: 8px;
  }

  .editor-textarea {
    min-height: 30vh;
    background-color: #000;
    border: 2px solid #ababab;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    padding: 0px 10px;
    resize: none;
    margin: 0px;
  }
`

export const NewsInput = styled.input`
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  margin-bottom: 2%;
`

export const NewsInputDate = styled.input`
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  margin-bottom: 2%;
  width: 160px;
`

export const NewsTextarea = styled.textarea`
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
`

export const InputBox = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'block')};

  & + & {
    margin-top: 10px;
  }

  & > input[type='text'] {
    display: block;
    height: 36px;
    color: white;
    width: 100%;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 10px 12px;
    margin-bottom: 1%;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }

  & > input[type='date'] {
    display: block;
    height: 36px;
    color: white;
    width: 180px;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 10px 12px;
    margin-bottom: 1%;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }

  & > textarea {
    display: block;
    height: 200px;
    color: white;
    width: 100%;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 10px 12px;
    margin-bottom: 1%;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }

  & > label {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  & > .checkbox {
    display: flex;
    align-items: center;
    width: 100%;

    & > label {
      color: white;
      font-size: 1.5rem;
      margin-left: 5px;
    }

    & > input {
      background-color: #373737;
    }
  }

  & > select {
    display: block;
    height: 36px;
    width: 100%;
    color: white;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 0px 12px;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }
`

export const News = styled.div`
  display: inline;
  cursor: pointer;
  font-weight: normal;
`
