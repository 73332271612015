import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Category, CategoryHeader, NewButton, ActionButton, Item } from './styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons'

import Alert from '../../../../Template/Alert'
import Form from '../Form'

export default function List(props) {
  const [exercises, setExercises] = useState([])
  const [formComponent, setFormComponent] = useState(null)
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')

  useEffect(() => {
    let isMounted = true

    getData(isMounted)

    return () => (isMounted = false)
  }, [])

  async function getData(isMounted) {
    await axios.get(`/admin/curation/${props.curationId}/question`).then((res) => {
      if (isMounted) setExercises(res.data.payload)
    })
  }

  function handleNew(type) {
    let localPayload = {
      type: type,
      answers: [],
      curation: { id: props.curationId },
      text: '',
      xp: 25,
      prompt: '',
    }
    setFormComponent(
      <Form
        payload={localPayload}
        close={handleCloseForm}
        showAlert={handleAlert}
        updateData={getData}
      />,
    )
  }

  function handleEdit(exercise, text) {
    setFormComponent(
      <Form
        payload={{ ...exercise, text: text, curation: { id: props.curationId } }}
        close={handleCloseForm}
        showAlert={handleAlert}
        updateData={getData}
        editing
      />,
    )
  }

  function handleDelete(exercise) {
    axios
      .delete('/admin/curation/question/delete/' + exercise.id)
      .then(() => {
        handleAlert({
          message: 'Excluído com sucesso',
          success: true,
        })
        getData(true)
      })
      .catch((err) => {
        console.log('ERROR', err)
        handleAlert({
          message: 'Ocorreu um erro.',
          success: false,
        })
      })
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handleCloseForm() {
    setFormComponent(null)
  }

  function filterExercises(type) {
    return exercises.filter((item) => item.type === type)
  }

  function renderExercises(type) {
    const exercises = filterExercises(type)

    if (!exercises.length)
      return <span className='empty-section'>Nenhum exercício deste tipo cadastrado.</span>

    switch (type) {
      case 'AUDIO_ACTIVITY':
        return exercises.map((exercise, index) => {
          const text = exercise.answers.find((answer) => answer.correct).text
          return (
            <Item key={index}>
              <span className='text'>{text}</span>
              <div className='buttons'>
                <ActionButton onClick={() => handleEdit(exercise, text)} maxWidth='80px'>
                  <FontAwesomeIcon icon={faPencil} />
                  <span>Editar</span>
                </ActionButton>
                <ActionButton
                  onClick={() => handleDelete(exercise)}
                  maxWidth='90px'
                  hoverColor='#ff4538'
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span>Excluir</span>
                </ActionButton>
              </div>
            </Item>
          )
        })
      case 'MULTIPLE_CHOICE_ACTIVITY':
        return exercises.map((exercise, index) => {
          const text = exercise.text
          return (
            <Item key={index}>
              <span className='text'>{text}</span>
              <div className='buttons'>
                <ActionButton onClick={() => handleEdit(exercise, text)} maxWidth='80px'>
                  <FontAwesomeIcon icon={faPencil} />
                  <span>Editar</span>
                </ActionButton>
                <ActionButton
                  onClick={() => handleDelete(exercise)}
                  maxWidth='90px'
                  hoverColor='#ff4538'
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span>Excluir</span>
                </ActionButton>
              </div>
            </Item>
          )
        })
      case 'RECORDING_ACTIVITY':
        return exercises.map((exercise, index) => {
          const text = exercise.answers[0].text
          return (
            <Item key={index}>
              <span className='text'>{text}</span>
              <div className='buttons'>
                <ActionButton onClick={() => handleEdit(exercise, text)} maxWidth='80px'>
                  <FontAwesomeIcon icon={faPencil} />
                  <span>Editar</span>
                </ActionButton>
                <ActionButton
                  onClick={() => handleDelete(exercise)}
                  maxWidth='90px'
                  hoverColor='#ff4538'
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span>Excluir</span>
                </ActionButton>
              </div>
            </Item>
          )
        })
      case 'TEXT_ACTIVITY':
        return exercises.map((exercise, index) => {
          const text = exercise.text
          return (
            <Item key={index}>
              <span className='text'>{text}</span>
              <div className='buttons'>
                <ActionButton onClick={() => handleEdit(exercise, text)} maxWidth='80px'>
                  <FontAwesomeIcon icon={faPencil} />
                  <span>Editar</span>
                </ActionButton>
                <ActionButton
                  onClick={() => handleDelete(exercise)}
                  maxWidth='90px'
                  hoverColor='#ff4538'
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span>Excluir</span>
                </ActionButton>
              </div>
            </Item>
          )
        })
    }
  }

  return (
    <>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />

      {formComponent || (
        <>
          <Category>
            <CategoryHeader>
              <span className='category-title'>Compreensão</span>
              <NewButton onClick={() => handleNew('AUDIO_ACTIVITY')}>
                <FontAwesomeIcon icon={faPlus} />
                Adicionar
              </NewButton>
            </CategoryHeader>
            {renderExercises('AUDIO_ACTIVITY')}
          </Category>
          <Category>
            <CategoryHeader>
              <span className='category-title'>Múltipla Escolha</span>
              <NewButton onClick={() => handleNew('MULTIPLE_CHOICE_ACTIVITY')}>
                <FontAwesomeIcon icon={faPlus} />
                Adicionar
              </NewButton>
            </CategoryHeader>
            {renderExercises('MULTIPLE_CHOICE_ACTIVITY')}
          </Category>
          <Category>
            <CategoryHeader>
              <span className='category-title'>Pronúncia</span>
              <NewButton onClick={() => handleNew('RECORDING_ACTIVITY')}>
                <FontAwesomeIcon icon={faPlus} />
                Adicionar
              </NewButton>
            </CategoryHeader>
            {renderExercises('RECORDING_ACTIVITY')}
          </Category>
          <Category>
            <CategoryHeader>
              <span className='category-title'>Escrita</span>
              <NewButton onClick={() => handleNew('TEXT_ACTIVITY')}>
                <FontAwesomeIcon icon={faPlus} />
                Adicionar
              </NewButton>
            </CategoryHeader>
            {renderExercises('TEXT_ACTIVITY')}
          </Category>
        </>
      )}
    </>
  )
}
