import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import DropFileZone from '../../../../../DropFileZone'
import { ButtonContainer, ConfirmButton, Error, InputBox } from '../../../../styles'

export default function NewModuleForm(props) {
  const [payload, setPayload] = useState(null)
  const [studentLevels, setStudentLevels] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setPayload({ ...props.payload })
  }, [props.payload])

  useEffect(() => {
    let isMounted = true

    getData(isMounted)

    return () => (isMounted = false)
  }, [])

  async function getData(isMounted) {
    await axios.get('/admin/level').then((res) => {
      if (isMounted) {
        setStudentLevels(res.data.payload)
      }
    })
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function handleUploadedFile(e) {
    setPayload({
      ...payload,
      coverSource: e,
    })
  }

  function validatePayload() {
    if (!payload.name) {
      setErrorMessage('Por favor, insira o nome do módulo.')
      return false
    }

    if (!payload.description) {
      setErrorMessage('Por favor, insira a descrição do módulo.')
      return false
    }

    if (!payload.sequence) {
      setErrorMessage('Por favor, insira o número de sequência do módulo.')
      return false
    }

    if (!payload.coverSource) {
      setErrorMessage('Por favor, insira a imagem de capa do módulo.')
      return false
    }

    if (!payload.studentLevelId) {
      setErrorMessage('Por favor, selecione o nível do módulo.')
      return false
    }

    setErrorMessage('')
    return true
  }

  function handleSubmit() {
    if (!validatePayload()) return

    payload.studentLevel = {
      id: payload.studentLevelId,
    }

    axios
      .post('/admin/module', payload)
      .then(() => {
        props.showAlert({
          message: <span>Módulo cadastrado com sucesso!</span>,
          success: true,
        })
        props.updateData(true)
        props.close()
      })
      .catch(() => {
        props.showAlert({
          message: <span>Erro ao cadastrar módulo!</span>,
          success: false,
        })
      })
  }

  return (
    <>
      {payload && (
        <>
          <InputBox>
            <label>Nome do Módulo</label>
            <input
              name='name'
              type='text'
              placeholder='Nome'
              value={payload.name}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Descrição</label>
            <input
              name='description'
              type='text'
              placeholder='Descrição'
              value={payload.description}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Sequência</label>
            <input
              name='sequence'
              type='text'
              placeholder='Sequência'
              value={payload.sequence}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Imagem de Capa</label>
            <DropFileZone
              validTypes={['image/jpeg', 'image/jpg', 'image/png']}
              maxSize={1024 * 1024 * 10}
              uploadedFiles={(e) => handleUploadedFile(e)}
            />
          </InputBox>
          <InputBox>
            <label>Nível do Aluno</label>
            <select name='studentLevelId' value={payload.studentLevelId} onChange={handleChange}>
              <option disabled value=''>
                Selecione o Nível
              </option>
              {studentLevels.map((studentLevel) => (
                <option key={studentLevel.id} value={studentLevel.id}>
                  {studentLevel.name}
                </option>
              ))}
            </select>
          </InputBox>
          {errorMessage && (
            <Error>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </Error>
          )}
          <ButtonContainer>
            <div></div>
            <ConfirmButton onClick={() => handleSubmit()}>
              <span>Confirmar</span>
              <FontAwesomeIcon icon={faCheck} />
            </ConfirmButton>
          </ButtonContainer>
        </>
      )}
    </>
  )
}
