import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Container, EditButton, FailureContent, FailureList } from './styles'

import { ActionButton, HeaderSpacer, NewButton, TableHeader } from '../../styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons'

import Modal from '../../Template/Modal'
import Alert from '../../Template/Alert'
import Filter from '../../Template/Filter'
import SearchBox from '../../Template/SearchBox'
import ConquerTable from '../../Template/ConquerTable'
import ConquerTablePaginated from '../../Template/ConquerTablePaginated'

import ScheduleForm from './components/ScheduleForm'
import ModalDeleteConfirmation from '../../Template/ModalDeleteConfirmation'
import FilterForm from './components/FilterForm'

export default function MentorsRecorded() {
  const [mentorData, setMentorData] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [filteredData, setFilteredData] = useState(null)
  const [filterResultData, setFilterResultData] = useState(null)
  const [searchResultData, setSearchResultData] = useState(null)
  const [formComponent, setFormComponent] = useState()
  const [contentId, setContentId] = useState('')
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedMentor, setSelectedModule] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubtitle, setModalSubtitle] = useState('')
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [loadingTable, setLoadingTable] = useState(true)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemUrl, setDeleteItemUrl] = useState('')
  const [deleteItemName, setDeleteItemName] = useState('')
  const [totalNumPages, setTotalNumPages] = useState()
  const [scheduledStudents, setScheduledStudents] = useState([])
  const [deleteFailure, setDeleteFailure] = useState(false)
  const [showFailureContent, setShowFailureContent] = useState()

  const mentorsColumns = React.useMemo(
    () => [
      { title: 'Nome', field: 'name' },
      { title: 'E-mail', field: 'email' },
      {
        title: 'Ver horários',
        content: (v) => (
          <EditButton onClick={() => handleMentorOpen(v)}>
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </EditButton>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
    ],
    [],
  )

  const scheduleColumns = React.useMemo(
    () => [
      { title: 'Dia da semana', field: 'dayOfWeek' },
      { title: 'início', field: 'mentoringStart' },
      { title: 'fim', field: 'mentoringEnd' },
      {
        title: '',
        id: 'actions',
        content: (v) => (
          <ActionButton>{/* <FontAwesomeIcon icon={faPencil}/> Editar */}</ActionButton>
        ),
        width: 50,
      },
      {
        title: '',
        id: 'delete',
        content: (v) => (
          <ActionButton onClick={() => handleDeleteContent(v)}>
            <FontAwesomeIcon icon={faTrash} /> Apagar
          </ActionButton>
        ),
        width: 10,
      },
    ],
    [],
  )

  const filters = React.useMemo(() => [
    {
      title: 'Nível',
      field: 'studentLevelName',
      options: [
        { text: 'Beginner', value: 'BEGINNER' },
        { text: 'Intermediate', value: 'INTERMEDIATE' },
        { text: 'Advanced', value: 'ADVANCED' },
      ],
    },
  ])

  useEffect(() => {
    let isMounted = true

    fetchMentors(isMounted)

    return () => (isMounted = false)
  }, [])

  async function fetchMentors(isMounted) {
    setLoadingTable(true)

    await axios.get('/admin/mentor').then((res) => {
      if (isMounted) {
        setMentorData(
          res.data.sort(function (a, b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          }),
        )
        setLoadingTable(false)
      }
    })
  }

  async function fetchScheduleConfigByMentorId(mentorId, page, start, end) {
    setLoadingTable(true)
    let pageFilter = {
      page: Math.max(page - 1, 0) || 0,
      pageSize: 18,
      mentoringStart: start || '',
      mentoringEnd: end || '',
    }
    await axios
      .post(`/admin/schedule-config/pagination/${mentorId}`, pageFilter)
      .then((res) => {
        let schedules = res.data.content
        setTotalNumPages(res.data.totalPages)
        setScheduleData(schedules)
        setLoadingTable(false)
      })
      .catch((error) => {
        setLoadingTable(false)
      })
  }

  function newScheduling() {
    const payload = {}

    setFormComponent(
      <ScheduleForm
        payload={payload}
        close={handleClose}
        updateData={() => {
          fetchScheduleConfigByMentorId(selectedMentor.id)
          handleAlert({
            message: 'Horário cadastrado com sucesso',
            success: true,
          })
          setShowModal(false)
        }}
        lessonId={null}
        mentorId={selectedMentor.id}
        mentor={selectedMentor}
      />,
    )
    setModalTitle('Novo horário')
    setModalSubtitle('')
    setShowModal(true)
  }

  function handleDeleteContent(v) {
    fetchScheduledStudents(v.id)
    setDeleteItemUrl(`admin/schedule-config/${v.id}`)
    setDeleteItemName(v.name)
    setDeleteConfirmation(true)
  }

  function handleCloseModalConfirmation(v) {
    setDeleteConfirmation(false)
  }

  useEffect(() => {
    if (selectedMentor) {
      fetchScheduleConfigByMentorId(selectedMentor.id)
    }
  }, [selectedMentor])

  function handleClose() {
    setShowModal(false)
  }

  function handleCloseEditModal() {
    setShowEditModal(false)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handleSearchResult(e) {
    setSearchResultData(e)
  }

  function handleFilterResult(e) {
    setFilterResultData(e)
  }

  function handleMentorOpen(e) {
    setSelectedModule(e)
  }

  function handleMentorsClick() {
    setSelectedModule(null)
    setSelectedSchedule(null)
    setScheduleData([])
    fetchMentors(true)
  }

  function deleteOnResponse(data) {
    if (data.status === true) {
      deleteOnSuccess()
    }

    if (data.status === false) {
      deleteOnFailure(data.students)
    }
  }

  function deleteOnSuccess() {
    handleAlert({
      message: 'Horário removido com sucesso',
      success: true,
    })
    fetchScheduleConfigByMentorId(selectedMentor?.id)
  }

  function deleteOnFailure(students) {
    setDeleteFailure(true)
    failureContent(students)
  }

  function failureContent(students) {
    console.log('students', students)
    setShowFailureContent(
      <>
        <FailureContent>
          Contém {students.length} aluno{students.length < 1 ? 's' : ''} com agendamento confirmado
          que não {students.length < 1 ? 'foram' : 'foi'} realocado{students.length < 1 ? 's' : ''}{' '}
          pelo sistema.
        </FailureContent>
        <FailureList>
          {students.map((student) => (
            <li key={student.studentId}>
              <span style={{ fontWeight: 'bold' }}>Nome:</span> {student.name}
              <br />
              <span style={{ fontWeight: 'bold' }}>Email:</span> {student.email}
              <br />
              <span style={{ fontWeight: 'bold' }}>Data da Mentoria:</span> {student.mentoringAt}
            </li>
          ))}
        </FailureList>
      </>,
    )
  }

  function handleCloseFailure() {
    setDeleteFailure(false)
    setShowFailureContent('')
  }

  useEffect(() => {
    if (searchResultData && filterResultData) {
      setFilteredData(filterResultData.filter((d) => searchResultData.includes(d)))
    } else {
      setFilteredData(searchResultData || filterResultData)
    }
  }, [searchResultData, filterResultData])

  function handlePageChange(page) {
    fetchScheduleConfigByMentorId(selectedMentor?.id, page)
  }

  function handleDateFilter() {
    setFormComponent(
      <FilterForm
        close={handleClose}
        filter={(startDate, endDate) => {
          fetchScheduleConfigByMentorId(selectedMentor?.id, 0, startDate, endDate)
          setShowModal(false)
        }}
      />,
    )
    setModalTitle('Filtro')
    setModalSubtitle('')
    setShowModal(true)
  }

  async function fetchScheduledStudents(scheduleConfigId) {
    const response = await axios.get(
      `/admin/schedule-config/${scheduleConfigId}/students-scheduled`,
    )
    setScheduledStudents(response.data)
  }

  const mentor = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span>Mentores</span>
          <div style={{ display: 'none' }}>
            <Filter data={mentorData} filters={filters} result={handleFilterResult} />
            <HeaderSpacer />
            <SearchBox data={mentorData} fields={['name']} result={handleSearchResult} />
            <HeaderSpacer />
          </div>
        </TableHeader>
        <ConquerTable
          columns={mentorsColumns}
          data={filteredData || mentorData}
          rowsPerPage={18}
          loading={loadingTable}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />
    </>
  )

  const schedule = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span style={{ fontSize: '2rem' }}>
            <span
              onClick={() => handleMentorsClick()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Mentores
            </span>{' '}
            {' > '} {selectedMentor?.name}
            {' > '}
            <span>Horários</span>
          </span>
          <div>
            <h1
              onClick={handleDateFilter}
              style={{ color: 'yellow', marginRight: '12px', cursor: 'pointer' }}
            >
              Filtro
            </h1>
            <NewButton onClick={() => newScheduling()}>Cadastrar novo horário</NewButton>
          </div>
        </TableHeader>
        <ConquerTablePaginated
          columns={scheduleColumns}
          data={filteredData || scheduleData}
          totalNumPages={totalNumPages}
          loading={loadingTable}
          onPageChange={handlePageChange}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />
      {showEditModal && (
        <Modal close={handleCloseEditModal} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
    </>
  )

  return (
    <>
      {deleteConfirmation && (
        <ModalDeleteConfirmation
          handleClose={handleCloseModalConfirmation}
          title='Apagar:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onResponse={(data) => deleteOnResponse(data)}
        ></ModalDeleteConfirmation>
      )}
      {selectedMentor ? schedule : mentor}
      {deleteFailure && (
        <Modal title='Não foi possível apagar!' close={() => handleCloseFailure()}>
          {showFailureContent}
        </Modal>
      )}
    </>
  )
}
