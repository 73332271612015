import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { CaptureConsole } from '@sentry/integrations'

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    environment: 'production',
    dsn: 'https://0959ae6c3bd54dd3ba0e48d905beee4b@o510564.ingest.sentry.io/5609941',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
