import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ItemBox } from './styles'

export default function Item(props) {
  return (
    <ItemBox active={props.active} onClick={props.onClick}>
      <span>{props.text}</span>
      <FontAwesomeIcon icon={props.icon} />
    </ItemBox>
  )
}
