import React, { createContext, useState, useContext } from 'react'

const BlockerModal = createContext()

export default function BlockerModalProvider({ children }) {
  const [blockerModal, setBlockerModal] = useState({ status: false, text: String })

  return (
    <BlockerModal.Provider value={{ blockerModal, setBlockerModal }}>
      {children}
    </BlockerModal.Provider>
  )
}

export function useBlockerModal() {
  const context = useContext(BlockerModal)
  const { blockerModal, setBlockerModal } = context
  return { blockerModal, setBlockerModal }
}
