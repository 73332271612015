import styled from 'styled-components'

export const ConfirmButton = styled.button`
  background: #ffff00;
  border: none;
  font-weight: 500;
  font-size: 1.8rem;
  color: black;
  cursor: pointer;
  align-items: center;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  height: 40px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  &:active {
    outline: none;
  }

  & > svg {
    margin-left: 10px;
  }
`
