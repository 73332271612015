import React from 'react'
import { TableSpacer, Th } from '../../../../Template/ConquerTable/styles'

export function DetailsMentoring({ mentoring }) {
  const columns = [
    {
      title: 'Data',
      field: 'dayAndFullMonth',
    },
    {
      title: 'Dia da semana',
      field: 'dayOfWeekFormatted',
    },
    {
      title: 'Horario',
      field: 'slotMentoring',
    },
    {
      title: 'Nome do mentor',
      field: 'teacherName',
      props: {
        center: true,
      },
    },
  ]

  return (
    <>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <Th key={index} {...column.props}>
              {column.title}
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className='no-hover'>
          <TableSpacer colSpan={100} />
        </tr>

        {mentoring.map((data, index) => (
          <tr key={index} style={{ color: '#fff' }}>
            <td style={{ textAlign: 'center' }}>{data.mentoringStart}</td>
            <td style={{ textAlign: 'center' }}>{data.dayOfWeek}</td>
            <td style={{ textAlign: 'center' }}>{data.mentoringTime}</td>
            <td style={{ textAlign: 'center' }}>{data.mentorDTO.name}</td>
          </tr>
        ))}
      </tbody>
    </>
  )
}
