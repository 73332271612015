import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faPencil,
  faTrashAlt,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-light-svg-icons'

import { Box, NewAnswerBox, NewAnswerText, NewAnswerCorrect, ButtonBox, Answer } from './styles'

export default function AnswersBox(props) {
  const [newAnswer, setNewAnswer] = useState({ active: false, index: -1, text: '', correct: false })
  const [newAnswerError, setNewAnswerError] = useState({ status: false, message: '' })

  function handleNewAnswer() {
    clearNewAnswerError()

    setNewAnswer({
      active: true,
      index: -1,
      text: '',
      correct: props.questionType === 'TEXT_ACTIVITY',
    })
  }

  function handleEditAnswer(answer, index) {
    clearNewAnswerError()

    setNewAnswer({
      index: index,
      id: answer.id,
      active: true,
      text: answer.text,
      correct: answer.correct,
    })
  }

  function handleRemoveAnswer(index) {
    const answers = [...props.answers]
    answers.splice(index, 1)
    props.updateAnswers(answers)
  }

  function handleNewAnswerChange(e) {
    if (e.target.name === 'correct') {
      setNewAnswer({
        ...newAnswer,
        correct: !newAnswer.correct,
      })
    } else {
      setNewAnswer({
        ...newAnswer,
        [e.target.name]: e.target.value,
      })
    }
  }

  function handleSubmitNewAnswer() {
    if (!validateNewAnswer()) return

    const answers = [...props.answers]

    if (newAnswer.index >= 0) {
      answers[newAnswer.index] = {
        text: newAnswer.text,
        correct: isTextOrRecording() || newAnswer.correct,
      }
    } else {
      answers.push({
        text: newAnswer.text,
        correct: isTextOrRecording() || newAnswer.correct,
      })
    }

    props.updateAnswers(answers)
    hideNewAnswer()
  }

  function validateNewAnswer() {
    if (!newAnswer.text.length > 0) {
      setNewAnswerError({
        status: true,
        message: 'Insira o texto para a resposta.',
      })
      return false
    }

    if (newAnswer.index < 0 && props.answers.some((answer) => answer.text === newAnswer.text)) {
      setNewAnswerError({
        status: true,
        message: 'Já existe uma resposta com este texto.',
      })
      return false
    }

    return true
  }

  function hideNewAnswer() {
    setNewAnswer({
      ...newAnswer,
      active: false,
    })
  }

  function clearNewAnswerError() {
    setNewAnswerError({
      status: false,
      message: '',
    })
  }

  function couldDeleteAnswer() {
    return !(props.editing && !isTextOrRecording())
  }

  function isTextOrRecording() {
    return props.questionType === 'TEXT_ACTIVITY' || props.questionType === 'RECORDING_ACTIVITY'
  }

  return (
    <Box>
      <span className='box-title'>Respostas</span>
      <div>
        {props.answers.map((answer, index) => (
          <Answer key={answer.text} correct={answer.correct}>
            <div className='answer'>
              {answer.correct ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
              <span>{answer.text}</span>
            </div>
            <div className='actions'>
              <FontAwesomeIcon icon={faPencil} onClick={() => handleEditAnswer(answer, index)} />
              {couldDeleteAnswer() && (
                <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleRemoveAnswer(index)} />
              )}
            </div>
          </Answer>
        ))}
      </div>
      <NewAnswerBox hide={!newAnswer.active}>
        <NewAnswerText>
          <label>Texto</label>
          <input
            name='text'
            type='text'
            placeholder='Texto da Resposta'
            value={newAnswer.text}
            onChange={handleNewAnswerChange}
          />
        </NewAnswerText>
        {!isTextOrRecording() && (
          <NewAnswerCorrect>
            <label>É a correta?</label>
            <input
              name='correct'
              type='checkbox'
              checked={newAnswer.correct}
              onChange={handleNewAnswerChange}
            />
          </NewAnswerCorrect>
        )}
      </NewAnswerBox>
      <ButtonBox>
        {newAnswer.active ? (
          <>
            <button className='cancel' onClick={hideNewAnswer}>
              Cancelar
            </button>
            <button className='new-answer' onClick={handleSubmitNewAnswer}>
              Confirmar
            </button>
          </>
        ) : (
          <></>
        )}
        {!newAnswer.active && (isTextOrRecording() || props.answers.length < 4) ? (
          <button className='new-answer' onClick={handleNewAnswer}>
            Adicionar Resposta
          </button>
        ) : (
          <></>
        )}
      </ButtonBox>
      {newAnswer.active && newAnswerError.status ? (
        <span className='error'>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {newAnswerError.message}
        </span>
      ) : (
        <></>
      )}
    </Box>
  )
}
