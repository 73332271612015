import React, { useState } from 'react'
import { Button } from '../../../../../buttons/Go/styles'

import { ButtonContainer, InputBox } from '../../../../styles'

export default function FilterForm(props) {
  const [dateStart, setDateStart] = useState()
  const [dateEnd, setDateEnd] = useState()

  function handleChangeStart(e) {
    setDateStart(e.target.value)
  }

  function handleChangeEnd(e) {
    setDateEnd(e.target.value)
  }

  return (
    <>
      <InputBox>
        <label>Data de início</label>
        <input
          name='dateStart'
          type='date'
          required
          value={dateStart}
          onChange={handleChangeStart}
        />
      </InputBox>
      <InputBox>
        <label>Data de fim</label>
        <input name='dateEnd' type='date' required value={dateEnd} onChange={handleChangeEnd} />
      </InputBox>

      <ButtonContainer>
        <div></div>
        <Button
          onClick={() => props.filter(dateStart, dateEnd)}
          style={{ justifyContent: 'center' }}
        >
          Pesquisar
        </Button>
      </ButtonContainer>
    </>
  )
}
