import styled from 'styled-components'

export const Modal = styled.div`
  width: 70%;
  border: 2px solid #ffff00;
  border-radius: 24px;
  display: flow-root;
  padding: 40px 80px;
  background-color: #0c0c0c;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;

  @media (max-width: 1000px) {
    padding: 10px 10px;
    width: 95%;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
`

export const ModalTitle = styled.h1`
  font-size: 2rem;
  color: #ffff00;
  margin-left: auto;
  margin-right: auto;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-weight: 500;
  font-size: 1.1rem;
  color: white;
  cursor: pointer;
  align-items: center;
  display: flex;
`

export const ModalContent = styled.div`
  width: 100%;
  display: ${(props) => props.display || 'flex'};
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 30px;
  max-height: 500px;
  overflow: auto;

  @media (max-width: 1000px) {
    padding: 10px 15px;
  }
`
