import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ActionButton, Container } from '../../../../styles'
import ConquerTable from '../../../../Template/ConquerTable'
import { TextContainer } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import ModalDeleteConfirmationAlternative from '../ModalDeleteConfirmationAlternative'

export default function ViewModalMentoring(props) {
  const [mentoring, setMentoring] = useState(null)
  const [loadingTable, setLoadingTable] = useState(true)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemUrl, setDeleteItemUrl] = useState('')
  const [deleteItemName, setDeleteItemName] = useState('')

  function handleCloseModalConfirmation() {
    setDeleteConfirmation(false)
  }

  function handleLiveDeleteButton(e) {
    setDeleteItemUrl(`/admin/mentoring-schedule/${e.id}/student/${e.studentId}`)
    setDeleteItemName(e.mentor.name)
    setDeleteConfirmation(true)
  }

  const mentoringColumns = [
    {
      title: 'Nome do mentor',
      id: 'mentorName',
      content: (v) => <span>{v.mentor.name}</span>,
      props: {
        left: true,
      },
    },
    {
      title: 'Email do Mentor',
      id: 'mentorEmail',
      content: (v) => <span>{v.mentor.email}</span>,
      props: {
        left: true,
      },
    },
    {
      title: 'Status da mentoria',
      id: 'status',
      content: (v) => <span>{v.status}</span>,
      props: {
        left: true,
      },
    },

    {
      title: 'Inicio da mentoria',
      id: 'mentoringStart',
      content: (v) => (
        <span>
          {new Date(v.mentoringStart).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
        </span>
      ),
      props: {
        left: true,
      },
    },
    {
      title: 'Final da mentoria',
      id: 'mentoringEnd',
      content: (v) => (
        <span>
          {new Date(v.mentoringEnd).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      ),
      props: {
        left: true,
      },
    },
    {
      title: '',
      id: 'delete',
      content: (v) => (
        <ActionButton onClick={() => handleLiveDeleteButton(v)}>
          <FontAwesomeIcon icon={faTrash} /> Apagar
        </ActionButton>
      ),
      props: {
        left: true,
      },
    },
  ]

  async function fetchMentoring() {
    await axios.get(`/admin/mentoring-schedule/student/${props.studentId}`).then((response) => {
      let studentId = props.studentId
      setMentoring(response.data.content)
      setMentoring((mentoring) =>
        mentoring.map((mentoring) => {
          mentoring.studentId = studentId
          return mentoring
        }),
      )
    })
    setLoadingTable(false)
  }

  useEffect(() => {
    fetchMentoring()
  }, [props.studentId])

  return (
    <Container>
      {mentoring && mentoring.length > 0 ? (
        <ConquerTable
          columns={mentoringColumns}
          data={mentoring}
          loading={loadingTable}
          disablePagination={true}
        />
      ) : (
        <TextContainer>Não há mentoria agendada para esse mês.</TextContainer>
      )}
      {deleteConfirmation && (
        <ModalDeleteConfirmationAlternative
          handleClose={handleCloseModalConfirmation}
          title='Apagar Mentoria do mentor:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onSuccess={() => fetchMentoring()}
        ></ModalDeleteConfirmationAlternative>
      )}
    </Container>
  )
}
