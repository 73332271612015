import styled from 'styled-components'
import { ReactComponent as Conquer } from '../svg/icon-conquer.svg'

export const Flex = styled.div`
  height: ${(props) => props.height || '100%'};
  display: flex;
  position: relative;
  align-items: ${(props) => props.AlignItems};
  justify-content: ${(props) => props.JustifyContent};
  flex-direction: ${(props) => props.FlexDirection};
  width: ${(props) => props.Width};
  gap: ${(props) => props.gap};
  opacity: ${(props) => (props.disabled ? '0.3' : '1.0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  & * {
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  }
`

export const InputBox = styled.div`
  display: block;
  margin-top: 20px;
`

export const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 700;
`

export const Input = styled.input`
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: ${(props) => (props.error ? '2px solid red' : '2px solid #1B1B1B')};
  font-size: 18px;
  line-height: 30px;
  outline: none !important;
  padding: 0.375rem 0.75rem;
  box-shadow: none !important;
  padding-left: 0;
  border-radius: 0;
  font-weight: bold;
  color: #1b1b1b;
  background-image: none !important;
  resize: none;
  background-clip: padding-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`

export const Generic = styled.div`
  width: ${(props) => props.Width};
`

export const GenericShow = styled.div`
  width: ${(props) => props.Width};
  display: ${(props) => (props.show ? 'block' : 'none')};
`

export const Text = styled.p`
  color: #1b1b1b;
  font-size: 1.4rem;
  line-height: 1.5;
`

export const ButtonGo = styled.button`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#FFFF00'};
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || '2.0rem'};
  padding: 10px 32px;
  border-radius: ${(props) => props.borderRadius || '53px'};
  border: 0;
  z-index: 1;
  margin-top: ${(props) => props.marginTop || '22px'};
  margin-bottom: ${(props) => props.marginBottom || '15px'};
  margin-right: ${(props) => props.marginRight || '0'};
  margin-left: ${(props) => props.marginLeft || '0'};
  display: flex;
  align-items: center;
  gap: 3px;
  justify-self: ${(props) => props.justifySelf || ''};
  width: fit-content;
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const SmallText = styled.div`
  color: #a2a2a2;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
`
export const TextAreaWithRows = styled.textarea`
  width: 70%;
  line-height: 5ch;
  background-image: linear-gradient(#ffffff00, transparent calc(5ch - 1px), #898989 0px);
  background-size: 100% 5ch;
  background-color: transparent;
  height: 20ch;
  outline: none;
  resize: none;
  color: white;
  border: none;
  font-size: 18px;
`
export const Message = styled.div`
  background-color: #1b1b1b;
  padding: 16px;
  text-align: center;
  color: #969696;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  margin-top: 35px;
  line-height: 24px;
  & svg {
    position: absolute;
    left: 50%;
    top: -18px;
    transform: translateX(-50%);
  }
`

export const Container = styled.div`
  z-index: ${(props) => props.zindex};
  width: ${(props) => props.width || '100%'};
  padding-right: ${(props) => (props.Pr ? props.Pr : '15px')};
  position: relative;
  padding-left: ${(props) => (props.Pl ? props.Pl : '15px')};
  margin-right: ${(props) => props.marginRight || 'auto'};
  margin-left: ${(props) => props.marginLeft || 'auto'};
  background-color: ${(props) => props.backgroundColor || ''};
  height: ${(props) => (props.HeightFull ? '100%' : 'auto')};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  display: ${(props) => props.display};
  gap: ${(props) => props.gap};
  @media (min-width: 576px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '540px')};
  }

  @media (min-width: 768px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '720px')};
  }

  @media (min-width: 992px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '960px')};
  }

  @media (min-width: 1200px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '1140px')};
  }

  @media (min-width: 1336px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '1310px')};
  }

  @media (min-width: 1400px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '1348px')};
  }

  @media (min-width: 1700px) {
    max-width: ${(props) => (props.isHeader ? '90vw' : '1900px')};
  }
`

export const TitleHeader = styled.h1`
  font-size: 3rem;
  color: white;
  margin-bottom: 1.5rem;
  font-weight: bold;
  margin-top: 0;
  text-transform: uppercase;
  & span {
    color: #ffff00;
  }
  @media (max-width: 900px) {
    font-size: 2.2rem;
  }
`

export const IconButtonLink = styled.div`
  background-color: #ffe607;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  color: black;
`

export const TextButtonLink = styled.div`
  color: white;
  font-weight: bold;
  font-size: 19px;
`

export const ButtonLink = styled.a`
  text-decoration: none;
  height: 60px;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.Loading ? '' : 'space-between')};
  background-color: #141414;
  margin-top: 20px;
  cursor: pointer;
  & ${TextButtonLink} {
    padding-left: ${(props) => (props.Loading ? '10px' : '20px')};
  }
`

export const TextButton = styled.div`
  color: ${(props) => props.color || 'white'};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '16px'};
`

export const TransparentButton = styled.button`
  border: 0;
  background-color: transparent !important;
  color: ${(props) => props.color || 'white'};
  height: 60px;
  overflow: hidden;
  width: ${(props) => props.width || '100%'};
  font-size: ${(props) => props.fontSize};
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${(props) => props.cursor || 'pointer'};
  & ${TextButton} {
    padding-left: 10px;
  }

  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }
`

export const Title = styled.p`
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin: ${(props) => props.margin || '0'};
  font-size: ${(props) => props.size || '42px'};
  line-height: ${(props) => props.lineHeight || '38px'};
  color: ${(props) => props.color || 'white'};
`

export const SubTitle = styled.p`
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin: ${(props) => props.margin || '0'};
  font-size: ${(props) => props.size || '20px'};
  line-height: ${(props) => props.lineHeight || '36px'};
  color: ${(props) => props.color || 'white'};
  text-align: left;
  word-wrap: break-word;
`

export const ButtonIcon = styled.button`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding: 15px 30px;
  width: ${(props) => props.width || '210px'};
  font-size: 1rem;
  line-height: 1.5;
  background: #fff;
  color: #1b1b1b;
  border: 0;

  &[aria-expanded='true'] {
    background-color: #ffe607;
  }

  &:active,
  &:focus {
    outline: 0;
  }
`

export const BoxIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.left ? '0' : '')};
  right: ${(props) => (props.right ? '0' : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  background-color: #ffe607;
  color: #1b1b1b;
`

export const TextButtonIcon = styled.div`
  margin-left: auto;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: ${(props) => props.size || '20px'};
  line-height: ${(props) => props.lineHeight || '34px'};
  text-align: ${(props) => props.textAlign || ''};
  letter-spacing: 0px;
  color: #242424;
`

export const ButtonsMobile = styled.div`
  margin-left: auto;
  margin-right: auto;
  border-radius: 26px;
  height: 100%;
  width: ${(props) => props.qtdButtons * (props.width || 132) + 'px' || '264px'};
  display: flex;
  background-color: #2a2a2a;

  @media (min-width: 900px) {
    display: none;
  }

  @media (max-width: 320px) {
    width: ${(props) => props.qtdButtons * (props.smWidth || 100) + 'px' || 'calc(100px * 2)'};
  }
`

export const ButtonRadius = styled.button`
  background-color: ${(props) => props.backgroundColor || '#2A2A2A'};
  color: ${(props) => props.color || 'white'};
  border: ${(props) => (props.active ? '2px solid #FFFF00' : '0')};
  border-radius: ${(props) => props.borderRadius || '26px'};
  height: ${(props) => props.height || '48px'};
  width: ${(props) => props.width || '132px'};
  padding: ${(props) => props.padding || '15px 30px'};
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  @media (max-width: 320px) {
    width: ${(props) => props.smWidth || '100px'};
    padding: 5px 10px;
  }
`

export const BtnNext = styled.div`
  user-select: none;
  background-color: ${(props) => (props.disabled ? '#505050' : '#FFFF00')};
  border-radius: 53px;
  width: 130px;
  height: 50px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 100px;
  font-size: 1.6rem;
  font-weight: 500;

  & svg {
    margin-left: 10px;
  }
  @media (max-width: 900px) {
    margin-right: 0px;
    margin-top: 10px;
  }
`

export const YellowText = styled.span`
  color: #ffff00;
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '100%'};
`

export const CircleButton = styled.div`
  background-color: ${(props) => props.bgColor || '#FFFF00'};
  width: ${(props) => props.size || '110px'};
  height: ${(props) => props.size || '110px'};
  border-radius: 50%;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 50px;
`

export const FlexHeader = styled.div`
  height: 100%;
  position: relative;
  align-items: ${(props) => props.AlignItems};
  justify-content: ${(props) => props.JustifyContent};
  flex-direction: ${(props) => props.FlexDirection};
  background-color: #1b1b1b;
  padding: 50px 15px;
  border-radius: 0 0 32px 32px;
  @media (max-width: 800px) {
    display: block;
  }
`

export const ContainerTotalWidth = styled.section`
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: ${(props) => (props.hide ? 'none' : props.display || '')};
  gap: ${(props) => props.gap};
`

export const DayText = styled.div`
  display: flex;
  font-size: ${(props) => props.fontSize || '17px'};
  color: ${(props) => props.color || '#898989'};
  @media (max-width: 1024px) {
    font-size: ${(props) => props.fontSizeSm || '17px'};
  }
`

export const DayNumber = styled.label`
  font-size: ${(props) => props.fontSize || '17px'};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  color: ${(props) => props.color || '#FFF'};
  @media (max-width: 1024px) {
    font-size: ${(props) => props.fontSizeSm || '17px'};
  }
`

export const LabelDefault = styled.label`
  font-size: ${(props) => props.fontSize || '17px'};
  color: ${(props) => props.color || '#FFF'};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  cursor: ${(props) => props.cursor};
  margin-left: ${(props) => props.marginLeft};
  z-index: ${(props) => props.zIndex};
`

export const ParagraphDefault = styled.p`
  font-size: ${(props) => props.fontSize || '17px'};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color || '#FFF'};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-align: ${(props) => props.textAlign};
`

export const GridContent = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
  grid-gap: ${(props) => props.gridGap};
  height: 100%;
  width: ${(props) => props.width};
  position: relative;
  max-width: ${(props) => props.maxWidth || ''};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  justify-self: ${(props) => props.justifySelf};
  flex-direction: ${(props) => props.flexDirection};
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  @media (max-width: 992px) {
    display: block;
  }

  @media (min-width: 800px) {
    margin-top: 0;
    margin-left: ${(props) => (props.mLeftActive ? '80px' : '')};
  }

  @media (min-width: 1200px) {
    margin-left: ${(props) => (props.mLeftActive ? '150px' : '')};
  }
`

export const TextGroup = styled.div`
  display: ${(props) => props.display || 'flex'};
  gap: ${(props) => props.gap || '5px'};
  align-items: ${(props) => props.alignItems || ''};
`

export const Opacity = styled.div`
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: ${(props) => (props.hide ? 'none' : props.display || 'flex')};
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

export const BoxModal = styled.div`
  background-color: #0c0c0c;
  border: 2px solid #ffff00;
  border-radius: 14px;
  height: 500px;
  width: 900px;
  padding: 50px 50px;
  position: relative;
  margin-top: 15px;

  @media (max-width: 1024px) {
    width: 800px;
  }

  @media (max-width: 800px) {
    width: 600px;
  }

  @media (max-width: 660px) {
    display: none;
  }
`

export const BoxModalDecorator = styled(BoxModal)`
  position: absolute;
  right: 12px;
  bottom: 10px;
  @media (max-width: 660px) {
    border: 0;
    border-radius: 0;
  }
`

export const BoxHeaderModalLogo = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 660px) {
    justify-content: flex-end;
  }
`

export const IconConquer = styled(Conquer)`
  font-size: ${(props) => props.fontSize || '42px'};
`

export const IconConquerPC = styled(Conquer)`
  font-size: ${(props) => props.fontSize || '42px'};

  @media (max-width: 660px) {
    display: none;
  }
`

export const Day = styled.div`
  display: grid;
  grid-gap: 5px;
  justify-items: center;
  cursor: ${(props) => props.cursor || 'pointer'};
  align-items: center;
  padding: 3px;
  border-bottom: ${(props) => (props.active ? '4px solid #FFFF00' : '')};
`

export const HeaderContentDefault = styled.div`
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (min-width: 800px) {
    margin-top: 0;
    padding-left: 80px;
  }

  @media (min-width: 1200px) {
    padding-left: 150px;
  }
`

export const Spacer = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'block')};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};

  @media (max-width: 599px) {
    width: ${(props) => props.smWidth || 'auto'};
  }
`

export const SpacerFlex = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  flex: ${(props) => props.flex || '1 1 100%'};
  height: ${(props) => props.height || 'auto'};
`

export const Divider = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'block')};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  ${(props) =>
    props.vertical ? 'border-left: 1px solid #2f2f2f;' : 'border-top: 1px solid #2f2f2f;'}
  align-self: center;

  @media (max-width: 899px) {
    display: ${(props) => (props.smHide ? 'none' : 'block')};
  }
`

export const Question = styled.div`
  color: ${(props) => (props.color ? props.color : 'white')};
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin-top: 20px;
`

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  gap: ${(props) => props.gap};
`

export const Line = styled.div`
  border-bottom: 1px solid #ffff00;
  width: 100%;
`

export const TitleQuestion = styled.div`
  text-align: center;
  width: 100%;
  color: #bbbbbb;
  font-size: 26px;
`
