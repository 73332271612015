import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Container, EditButton, Image } from './styles'

import { ActionButton, HeaderSpacer, NewButton, SearchField, TableHeader } from '../../styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPencil,
  faFilePdf,
  faTrash,
  faEye,
  faVideo,
  faFileAudio,
  faSearch,
  faTimes,
  faEdit,
} from '@fortawesome/pro-solid-svg-icons'

import Modal from '../../Template/Modal'
import Alert from '../../Template/Alert'
import Filter from '../../Template/Filter'
import SearchBox from '../../Template/SearchBox'
import ConquerTable from '../../Template/ConquerTable'
import List from '../CurationExercises/components/List'

import NewCurationForm from './components/NewCurationForm'
import NewContentForm from './components/NewContentForm'
import ModalDeleteConfirmation from '../../Template/ModalDeleteConfirmation'
import ConquerTablePaginated from '../../Template/ConquerTablePaginated'

export default function AdminCuration() {
  const [curationData, setCurationData] = useState([])
  const [contentData, setContentData] = useState([])
  const [filteredData, setFilteredData] = useState(null)
  const [filterResultData, setFilterResultData] = useState(null)
  const [searchResultData, setSearchResultData] = useState(null)
  const [formComponent, setFormComponent] = useState()
  const [showEditModal, setShowEditModal] = useState(false)
  const [showItemModal, setShowItemModal] = useState(false)
  const [showExercisesModal, setShowExercisesModal] = useState(false)

  const [selectedCuration, setSelectedCuration] = useState(false)
  const [payload, setPayload] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubtitle, setModalSubtitle] = useState('')
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [loadingTable, setLoadingTable] = useState(true)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemConfirmation, setDeleteItemConfirmation] = useState(false)

  const [deleteItemUrl, setDeleteItemUrl] = useState('')
  const [deleteItemName, setDeleteItemName] = useState('')

  const [totalNumPages, setTotalNumPages] = useState()
  const [search, setSearch] = useState('')
  const [selectedCurationId, setSelectedCurationId] = useState(null)

  const curationColumns = React.useMemo(
    () => [
      { title: 'Nome', field: 'name' },
      {
        title: 'Nível',
        content: (v) => v.levelName?.toUpperCase() || '-',
      },
      {
        title: 'Vídeos',
        content: (v) => v.videos,
      },
      {
        title: 'Audios',
        content: (v) => v.audios,
      },
      {
        title: 'PDF',
        content: (v) => v.pdfs,
      },
      {
        title: 'Tempo',
        content: (v) => v.time + ' min',
      },
      {
        title: 'Capa',
        content: (v) => (
          <a
            target='_blank'
            rel='noreferrer'
            href={v.thumb}
            style={{ color: 'white', fontSize: '2rem' }}
          >
            <Image src={v.thumb} width={'60px'} height={'60px'} />
          </a>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Ver conteúdos',
        content: (v) => (
          <EditButton onClick={() => handleContentOpen(v)}>
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
          </EditButton>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Exercícios',
        content: (v) => (
          <EditButton onClick={() => handleExercises(v)}>
            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          </EditButton>
        ),
        props: {
          center: true,
        },
      },
      {
        title: 'Editar',
        id: 'actions',
        content: (v) => (
          <ActionButton onClick={() => editCuration(v)}>
            <FontAwesomeIcon icon={faPencil} /> Editar
          </ActionButton>
        ),
        width: 50,
      },

      {
        title: '',
        id: 'delete',
        content: (v) => (
          <ActionButton onClick={() => handleDeleteCuration(v)}>
            <FontAwesomeIcon icon={faTrash} /> Apagar
          </ActionButton>
        ),
        width: 10,
      },
    ],
    [],
  )

  const contentColumns = React.useMemo(
    () => [
      {
        title: 'Formato',
        field: 'itemType',
        props: {
          width: '10%',
        },
      },
      {
        title: 'Nome',
        content: (v) => {
          return <span>{v.itemName}</span>
        },
      },
      {
        title: 'Url',
        content: (v) => {
          if (v.itemType === 'PDF') {
            return (
              <>
                <a target='_blank' rel='noreferrer' href={v.sourceUrl}>
                  Abrir <FontAwesomeIcon icon={faFilePdf} />
                </a>
              </>
            )
          } else if (v.itemType === 'VIDEO') {
            return (
              <>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={v.sourceUrl}
                  style={{ color: 'white' }}
                  onClick={() => openItem(v)}
                >
                  Abrir <FontAwesomeIcon icon={faVideo} />
                </a>
              </>
            )
          } else if (v.itemType === 'AUDIO') {
            return (
              <>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={v.sourceUrl}
                  style={{ color: 'white' }}
                  onClick={() => openItem(v)}
                >
                  Abrir <FontAwesomeIcon icon={faFileAudio} />
                </a>
              </>
            )
          }
        },
        props: {
          width: '10%',
        },
      },
      {
        title: 'Sequência',
        field: 'itemSequence',
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Tempo',
        content: (v) => v.itemTime + ' min',
      },
      {
        title: 'Editar',
        id: 'actions',
        content: (v) => (
          <ActionButton onClick={() => handleEditCurationItem(v)}>
            <FontAwesomeIcon icon={faPencil} /> Editar
          </ActionButton>
        ),
        width: 50,
      },
      {
        title: 'Apagar',
        id: 'actions',
        content: (v) => (
          <ActionButton onClick={() => handleDeleteCurationItem(v)}>
            <FontAwesomeIcon icon={faTrash} /> Apagar
          </ActionButton>
        ),
        width: 50,
      },
    ],
    [],
  )

  const filters = React.useMemo(() => [
    {
      title: 'Nível',
      content: (v) => v.level.name,
      options: [
        { text: 'Beginner', value: 'BEGINNER' },
        { text: 'Intermediate', value: 'INTERMEDIATE' },
        { text: 'Advanced', value: 'ADVANCED' },
      ],
    },
  ])

  function openItem(item) {
    setShowItemModal(true)
  }

  function handleExercises(v) {
    setModalTitle('Exercícios de Curadoria')
    setModalSubtitle(v.name)
    setSelectedCurationId(v.curationId)
    setShowExercisesModal(true)
  }

  useEffect(() => {
    let isMounted = true

    getCurations(isMounted)

    return () => (isMounted = false)
  }, [])

  async function getCurations(isMounted, pageNumber, searchTerm) {
    setLoadingTable(true)

    const page = pageNumber ? pageNumber : 0
    let url = '/admin/v2/curation/paginated/search?page=' + page + '&pageSize=10'
    if (searchTerm && searchTerm != '') {
      url += '&search=' + searchTerm
    }
    await axios.get(url).then((resp) => {
      setTotalNumPages(resp.data.numPages || 1)
      setCurationData(resp.data.content)
      setLoadingTable(false)
    })
  }

  async function getItemsByCurationId(curationId) {
    setLoadingTable(true)
    await axios
      .get(`/admin/curation/${curationId}/item`)
      .then((res) => {
        setContentData(
          res.data.payload.sort(function (a, b) {
            if (a.sequence > b.sequence) return 1
            if (a.sequence < b.sequence) return -1
            return 0
          }),
        )
        setLoadingTable(false)
      })
      .catch(() => {
        setLoadingTable(false)
      })
  }

  function newCurationData() {
    const payload = {
      name: '',
      description: '',
      sequence: '',
      thumb: '',
      xp: 0,
      level: '',
    }

    setFormComponent(
      <NewCurationForm
        payload={payload}
        showAlert={handleAlert}
        close={handleClose}
        updateData={getCurations}
        action='CREATE'
      />,
    )
    setModalTitle('Nova Curadoria')
    setModalSubtitle('')
    setShowModal(true)
  }

  function editCuration(v) {
    setFormComponent(
      <NewCurationForm
        payload={payload}
        showAlert={handleAlert}
        close={handleClose}
        updateData={getCurations}
        action='UPDATE'
        curationId={v.curationId}
      />,
    )
    setModalTitle('Edição Curadoria')
    setModalSubtitle('')
    setShowModal(true)
  }

  function newItem() {
    setFormComponent(
      <NewContentForm
        showAlert={handleAlert}
        close={handleClose}
        updateData={() => getItemsByCurationId(selectedCuration.curationId)}
        itemId={null}
        curationId={selectedCuration.curationId}
      />,
    )
    setModalTitle('Novo conteúdo')
    setModalSubtitle('')
    setShowModal(true)
  }

  function handleDeleteCuration(v) {
    setDeleteItemUrl('admin/curation/delete/' + v.curationId)
    setDeleteItemName(v.name)
    setDeleteConfirmation(true)
  }

  function handleDeleteCurationItem(v) {
    setDeleteItemUrl('admin/curation/item/' + v.itemId)
    setDeleteItemName(v.itemName)
    setDeleteItemConfirmation(true)
  }

  function handleCloseModalConfirmation(v) {
    setDeleteConfirmation(false)
    setDeleteItemConfirmation(false)
  }

  useEffect(() => {
    if (selectedCuration && selectedCuration.curationId) {
      getItemsByCurationId(selectedCuration.curationId)
    }
  }, [selectedCuration])

  function handleClose() {
    setShowModal(false)
    setShowEditModal(false)
    setShowExercisesModal(false)
  }

  function handleCloseEditModal() {
    setShowEditModal(false)
  }

  async function handleEditCurationItem(v) {
    setFormComponent(
      <NewContentForm
        showAlert={handleAlert}
        close={handleClose}
        updateData={() => getItemsByCurationId(selectedCuration.curationId)}
        itemId={v.itemId}
      />,
    )
    setModalTitle('Edição:')
    setModalSubtitle(v.itemName)
    setShowEditModal(true)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
    handleClose()
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handleSearchResult(e) {
    setSearchResultData(e)
  }

  function handleFilterResult(e) {
    setFilterResultData(e)
  }

  function handleContentOpen(e) {
    setSelectedCuration(e)
  }

  function handlePageChange(page) {
    getCurations(true, page)
  }

  useEffect(() => {
    if (searchResultData && filterResultData) {
      setFilteredData(filterResultData.filter((d) => searchResultData.includes(d)))
    } else {
      setFilteredData(searchResultData || filterResultData)
    }
  }, [searchResultData, filterResultData])

  function clearFilter() {
    setSearch('')
    getCurations(true, 0, '')
  }

  function searchByFilter() {
    getCurations(true, 0, search)
  }

  function handleFilterChange(e) {
    setSearch(e.target.value)
  }

  function handleFilterKeyPress(e) {
    if (e.key === 'Enter') setSearch(search)
  }

  function handleOnSkillsClick() {
    setSelectedCuration(null)
    setContentData([])
    // getCurations(true, 0, search)
  }

  const curation = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}

      {showExercisesModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          <List curationId={selectedCurationId} />
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span>Skills</span>
          <SearchField>
            <input
              type='text'
              placeholder='Pesquisar'
              value={search}
              onChange={handleFilterChange}
              onKeyPress={handleFilterKeyPress}
            />
            <button onClick={() => searchByFilter()}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
            {search && search !== '' && (
              <a onClick={() => clearFilter()}>
                <FontAwesomeIcon icon={faTimes} />
                Limpar pesquisa
              </a>
            )}
          </SearchField>
          <NewButton onClick={() => newCurationData()}>Cadastrar nova curadoria</NewButton>
        </TableHeader>
        <ConquerTablePaginated
          columns={curationColumns}
          data={curationData}
          totalNumPages={totalNumPages}
          loading={loadingTable}
          onPageChange={handlePageChange}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />
    </>
  )

  const content = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span style={{ fontSize: '2rem' }}>
            <span
              onClick={handleOnSkillsClick}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              skills{' '}
            </span>
            / {selectedCuration?.name} {'/ conteúdos'}
          </span>
          <div style={{ display: 'none' }}>
            <Filter data={contentData} filters={filters} result={handleFilterResult} />
            <HeaderSpacer />
            <SearchBox
              data={contentData}
              fields={['name', 'contentTdescription']}
              result={handleSearchResult}
            />
            <HeaderSpacer />
          </div>

          <NewButton onClick={() => newItem()}>Cadastrar novo conteúdo</NewButton>
        </TableHeader>
        <ConquerTable
          columns={contentColumns}
          data={filteredData || contentData}
          rowsPerPage={18}
          loading={loadingTable}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
        animationTime={30}
      />
      {showEditModal && (
        <Modal close={handleCloseEditModal} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
    </>
  )

  return (
    <>
      {deleteConfirmation && (
        <ModalDeleteConfirmation
          handleClose={handleCloseModalConfirmation}
          title='Apagar:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onSuccess={getCurations}
          message={
            'Atenção! Essa operação não poderá ser desfeita e vai apagar todos os conteúdos e exercícios vinculados à esta curadoria.'
          }
        ></ModalDeleteConfirmation>
      )}

      {deleteItemConfirmation && (
        <ModalDeleteConfirmation
          handleClose={handleCloseModalConfirmation}
          title='Apagar:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onSuccess={() => getItemsByCurationId(selectedCuration.curationId)}
          message={'Atenção! Essa operação não poderá ser desfeita.'}
        ></ModalDeleteConfirmation>
      )}

      {/* {selectedModule? (selectedCuration? content : content) : curation} */}
      {selectedCuration ? content : curation}
    </>
  )
}
