import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Banner = styled.div`
  background-size: cover;
  background-position: center;
  height: 650px;
  width: 100%;
  background-image: url(${(props) => props.Image});
  position: relative;
  display: flex;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(36, 36, 36, 1) 0%,
      rgb(20 19 19 / 51%) 40%,
      rgba(25, 24, 24, 0.44) 60%,
      rgb(28 26 26 / 77%) 80%,
      rgb(34 34 34 / 92%) 100%
    );
    filter: grayscale(100%);
  }
`

export const ModulesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0px 200px;
  padding-top: ${(props) => props.paddingTop || '0px'} !important;
  padding-bottom: ${(props) => props.paddingBottom || '0px'} !important;
  margin-top: ${(props) => props.marginTop || '0px'};
  justify-content: space-between;

  @media (max-width: 899px) {
    padding: 0px 50px;
  }

  ${(props) =>
    props.mdColumn
      ? `
        @media(max-width: 1199px) {
            flex-direction: column;
        }
    `
      : ''}
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0px 200px;
  margin-top: 150px;
  justify-content: space-between;

  @media (max-width: 899px) {
    flex-direction: column;
    padding: 0px;
    align-items: center;
  }

  @media (min-width: 900px) {
    padding: 0px 100px;
    flex-direction: row;
  }

  @media (min-width: 1400px) {
    padding: 0px 200px;
  }
`

export const Cursor = styled.div`
  @media (min-width: 1200px) {
    background-color: #ffff00;
    border-radius: 50%;
    display: block;
    position: fixed;
    margin-top: -10px;
    margin-left: -10px;
    width: 40px;
    height: 40px;
    pointer-events: none;
    opacity: 0.4;
    transform: scale(1);
    transition: 0.15s ease-out;
    z-index: 1000;
  }
`
export const Modulename = styled.h3`
  color: #a2a2a2;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 300;
  z-index: 100;
  margin: 0px;
`

export const ModuleTitle = styled.h1`
  color: #ffff00;
  margin: 0px;
  font-size: 7.2em;

  @media (max-width: 400px) {
    font-size: 5.2em;
  }
`

export const SubTitle = styled.h4`
  font-size: 2.4rem;
  color: white;
  margin: 0px;
  font-weight: 300;
`

export const BannerDescription = styled.p`
  color: white;
  font-size: 1.8rem;
  font-weight: 300;
  margin-top: 35px;
  width: ${(props) => (props.isLive ? '470px!important' : '500px')};
  line-height: 2.8rem;
  @media (max-width: 900px) {
    max-width: 80vw;
  }
`

export const ContentHeader = styled.div`
  position: absolute;
  top: -65px;
  left: 5px;
  font-size: 3.2rem;
  color: white;
  font-weight: bold;

  & .xp {
    font-size: 2rem;
    color: #898989;
    margin-left: 12px;
    font-weight: 300;
  }
`

export const ContentImageLive = styled.div`
  background-image: url(${(props) => props.Image});

  background-position: left;
  background-repeat: no-repeat;
  transition:
    background-size 3s,
    filter 1s;
  position: relative;
  filter: brightness(100%);
  display: flex;
  min-width: 42%;
  border-radius: 30px;
  box-shadow: #00000069 0 6px 9px;
  height: 610px;

  &:hover {
    filter: brightness(50%);
  }

  @media (max-width: 499px) {
    height: 300px !important;
  }

  @media (max-width: 1199px) {
    width: 100%;
    background-size: 160%;
    height: 400px;

    &:hover {
      background-size: 190% !important;
    }
  }

  @media (min-width: 1200px) {
    background-size: 280%;

    &:hover {
      background-size: 310% !important;
    }
  }

  @media (min-width: 1700px) {
    background-size: 220%;

    &:hover {
      background-size: 250% !important;
    }
  }

  & > div.live-symbol-container {
    position: absolute;
    top: 40px;
    left: 30px;
  }

  & > div.live-timer-container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px;
  }
`

export const ContentBox = styled.div`
  position: relative;
  background-color: black;
  border-radius: 30px;
  display: flex;
  width: ${(props) => props.width || '100%'};
  box-shadow: #00000069 0 6px 9px;

  @media (max-width: 899px) {
    width: ${(props) => props.smWidth || '100%'};
  }

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`

export const ContentImage = styled.div`
  background-image: url(${(props) => props.Image});
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  transition:
    background-position 3s,
    filter 1s;
  position: relative;
  background-position-x: 0%;
  filter: brightness(100%);
  border-radius: 30px;
  box-shadow: #00000069 0 6px 9px;
  height: 610px;
  width: 50%;

  @media (max-width: 499px) {
    height: 300px !important;
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: 400px;
  }

  &:hover {
    background-position-x: 99% !important;
    filter: brightness(50%);
  }
`

export const ContentInfo = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 60px 60px 170px 60px;
  flex-direction: column;
  position: relative;

  &.live {
    padding-bottom: 130px;
  }

  @media (max-width: 499px) {
    padding: 30px 30px 150px 30px;
    max-height: 600px;
    justify-content: space-between;
  }

  @media (min-width: 500px) {
    max-height: 700px;
    justify-content: space-between;
  }

  @media (min-width: 900px) {
    height: 500px;
    justify-content: space-evenly;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: auto;
  }
`

export const ContentTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  & .class {
    font-size: 3.2rem;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  & .step {
    color: #898989;
    font-size: 1.6rem;
    font-weight: 300;
  }
`

export const ContentDescription = styled.div`
  color: white;
  font-size: 1.8rem;
  font-weight: 300;
  width: 100%;
  line-height: 2.1rem;
  margin-top: 45px;

  &.guia {
    @media (max-width: 499px) {
      line-height: 2.3rem;
      font-size: 1.4rem;
    }

    @media (max-width: 699px) and (min-width: 500px) {
      line-height: 2.8rem;
      font-size: 1.6rem;
    }
  }
`

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 32px;

  & > span {
    color: #ffff00;
    font-size: 1.4rem;
  }
`

export const ProgressBar = styled.div`
  height: 8px;
  width: 100%;
  border: 1px solid #ffff00;
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 1px;
    bottom: 1px;
    height: 4px;
    width: ${(props) => props.Progress}%;
    background-color: #ffff00;
    border-radius: 5px;
  }
`

export const LinkContainer = styled.div`
  position: absolute;
  bottom: 60px;
  right: 60px;
  width: 225px;
  height: 75px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 499px) {
    bottom: 30px;
    right: 30px;
    height: 65px;
  }
`

export const LinkCustom = styled(Link)`
  color: ${(props) => (props.active ? '#FFFF00' : 'white')};
  font-size: 2rem;
  text-decoration: none;
  text-align: right;
  display: block;
  & > svg {
    margin-left: 12px;
  }
  & > svg path {
    fill: ${(props) => (props.active ? '#FFFF00' : 'white')};
  }

  @media (max-width: 499px) {
    font-size: 1.5rem;
  }
`

export const MaterialHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffff00;
  font-size: 1.8rem;
  text-transform: uppercase;
  width: 100%;
  position: absolute;
  top: -25px;

  @media (max-width: 399px) {
    font-size: 1.4rem;
  }

  @media (max-width: 899px) {
    justify-content: flex-start;
    padding-left: 10px;
  }

  & svg {
    margin-left: 15px;
    height: 30px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const MaterialBox = styled.div`
  position: relative;

  @media (max-width: 899px) {
    width: 90%;
    margin-left: 0px;
    margin-top: 100px;
  }

  @media (min-width: 900px) {
    width: 20%;
    margin-left: 20px;
  }
`

export const MaterialImage = styled.div`
  width: 100%;
  border-radius: 32px;
  margin-top: 25px;
  position: relative;
  transform: translateY(0px);
  transition: 0.5s;
  box-shadow: #00000069 0 6px 9px;
  background-image: url(${(props) => props.Image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;

  &:hover {
    transform: scale(1) translateY(-10px);
    transition: 0.5s;
    box-shadow: #00000069 5px 14px 14px 13px;
    cursor: pointer;
  }

  @media (max-width: 899px) {
    height: 400px;
  }

  @media (min-width: 900px) {
    height: 300px;
  }

  @media (min-width: 1200px) {
    height: 300px;
  }

  @media (min-width: 1700px) {
    height: 400px;
  }
`

export const ContentLive = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

export const ModuleButtonGo = styled.button`
  cursor: pointer;
  background-color: #ffff00;
  color: black;
  font-size: 2rem;
  border-radius: 53px;
  border: 0;
  z-index: 1;
  width: 110px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }

  & svg {
    margin-left: 10px;
  }
`

export const LiveGoContainer = styled.div`
  width: 110px;
  height: 50px;
  position: absolute;
  bottom: 60px;
  left: 60px;

  @media (max-width: 499px) {
    right: 30px !important;
    bottom: 30px !important;
  }

  @media (max-width: 1199px) {
    left: auto;
    right: 60px;
  }
`

export const NextMentoringHeader = styled.div`
  color: white;
  font-size: 2rem;
  text-align: right;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    text-align: left;
    margin-top: 20px;
    padding: 1rem;
  }

  & svg {
    height: 20px;
    margin-left: 15px;
  }
`

export const TextMentoring = styled.div``

export const ContentMentoring = styled.div`
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  border-radius: 32px;
  align-items: center;
  padding: 30px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const NextMentoring = styled.div`
  background-color: rgba(13, 13, 13, 0.78);
  border-radius: 32px;
  box-shadow: 0px 6px 9px #00000069;
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
  gap: ${(props) => props.gap};
  padding: 32px;
  width: fit-content;
  justify-content: end;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

export const ScheduleMentoring = styled.div`
  justify-content: end;
  display: ${(props) => (props.showSm ? 'none' : 'grid')};
  grid-gap: 20px;
  @media (max-width: 1024px) {
    justify-content: start;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: ${(props) => (props.showSm ? 'grid' : 'none')};
    grid-template-columns: 1fr;
  }
`

export const NextMentoringText = styled.div`
  display: grid;
  height: fit-content;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  @media (max-width: 768px) {
    justify-content: start;
  }
`

export const NextScheduleText = styled.span`
  font: normal normal bold 18px/28px DM Sans;
  color: #ffffff;
`

export const MentoringBox = styled.div`
  position: relative;
`

export const GuiaInfo = styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  padding: 50px 60px;
  padding-bottom: 120px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-image: url(${(props) => props.Image});
  background-size: cover;
  background-position: 50% 40%;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  background-color: rgba(80, 80, 80, 0.8);
  border-radius: 32px;

  @media (max-width: 499px) {
    padding: 30px;
    padding-bottom: 105px;
    justify-content: space-evenly;
  }

  @media (min-width: 900px) {
    padding-right: 40%;
  }

  @media (min-width: 1200px) {
    padding-right: 55%;
  }

  @media (min-width: 1700px) {
    padding-right: 65%;
  }
`

export const GuiaButton = styled.button`
  cursor: pointer;
  background-color: #ffff00;
  color: black;
  font-size: 1.6rem;
  border-radius: 53px;
  border: 0;
  z-index: 1;
  width: 180px;
  height: 40px;
  position: absolute;
  bottom: 50px;
  left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  & svg {
    margin-left: 10px;
  }

  @media (max-width: 499px) {
    right: 30px !important;
    bottom: 30px !important;
  }

  @media (max-width: 899px) {
    left: auto;
    right: 60px;
  }
`

export const EvaluateLive = styled.span`
  font-size: 2rem;
  color: #ffff00;
  margin-left: 12px;
  cursor: pointer;

  @media (max-width: 520px) {
    display: block;
    margin-left: 0;
  }

  @media (max-width: 320px) {
    display: block;
    margin-left: 0;
    font-size: 1.7rem;
  }
`
