import React from 'react'
import { Button, TextButton, IconButton, SkeletonLoading, ZindexFlex } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

import Lottie from 'react-lottie'
import animationData from '../../../assets/json/loading.json'

export default function ConfirmButton(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  function executeCallback() {
    props.execute()
  }

  return (
    <>
      {props.loading ? (
        <Button Loading={props.loading} onClick={() => executeCallback()}>
          <SkeletonLoading />
          <ZindexFlex>
            <Lottie
              options={defaultOptions}
              height={70}
              width={70}
              style={{ margin: 0, marginLeft: '10px' }}
            />
            <TextButton>{props.text}</TextButton>
          </ZindexFlex>
        </Button>
      ) : (
        <Button Loading={props.loading} onClick={() => executeCallback()}>
          <TextButton>{props.text}</TextButton>
          <IconButton>
            <FontAwesomeIcon icon={faArrowRight} />
          </IconButton>
        </Button>
      )}
    </>
  )
}
