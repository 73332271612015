import React, { useState, useEffect } from 'react'
import ModalDefault from '../ModalDefault'
import { Header, HeaderSection, ImageNews, Sections, SectionTitle } from './styles'
import { DisplayGrid, LabelDefault, Line, ParagraphDefault } from '../../assets/styles/global'
import axios from 'axios'
import { formatDate } from '../../utils/DateFormatter/'

export default function ModalNews(props) {
  const [hideModal, setHideModal] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    let isMounted = true

    let viewed = localStorage.getItem('news-viewed')
    if (!viewed) {
      getData(isMounted)
    }

    return () => (isMounted = false)
  }, [])

  async function getData(isMounted) {
    await axios
      .get('/news')
      .then((res) => {
        if (isMounted) {
          var dataPayload = res.data.payload
          if (Array.isArray(res.data.payload)) {
            setData(
              dataPayload.sort(function (a, b) {
                if (a.createdAt < b.createdAt) return 1
                if (a.createdAt > b.createdAt) return -1
                return 0
              }),
            )
          } else {
            setData(dataPayload)
          }
          localStorage.setItem('news-viewed', true)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setData([])
        } else {
          throw error
        }
      })
  }

  useEffect(() => {
    if (props.loadData) {
      getData(true)
    }
  }, [props.loadData])

  useEffect(() => {
    if (props.hide != null && !props.hide) {
      setHideModal(props.hide)
    }
  }, [props.hide])

  function handleHideModal(e) {
    setHideModal(e)
    props.closeModal(e)
  }

  // TODO Talvez instalar um editor de html
  // https://draftjs.org/docs/getting-started
  // https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs

  return (
    <>
      <ModalDefault
        display={'block'}
        modalHeader={
          <Header>
            <ParagraphDefault fontWeight={'bold'} margin={0} fontSize={'2.5rem'}>
              NOVIDADES
            </ParagraphDefault>
            <LabelDefault fontSize={'1.4rem'}>20 de março de 2021</LabelDefault>
          </Header>
        }
        hideModal={hideModal}
        closeModal={handleHideModal}
      >
        <DisplayGrid gap={'20px'}>
          <HeaderSection>
            <ImageNews image={'/img/updates.png'} />
            <ParagraphDefault>
              Hey, Conquer! Que nós nos dedicamos em sempre entregar o melhor para você, já falamos!
              Mas você está por dentro de todas as novidades que estamos trazendo para a plataforma?
              Acreditamos no poder de uma mudança, por menor que seja! Por isso, trouxemos algumas
              para você conferir:
            </ParagraphDefault>
          </HeaderSection>

          <Sections>
            {data && data.length > 0 ? (
              data.map((news) => {
                return (
                  <section key={news.title}>
                    <SectionTitle>{news.title}</SectionTitle>
                    <ParagraphDefault margin={'15px 0 0 0'} fontWeight={'bold'}>
                      {formatDate(news.createdAt)}
                    </ParagraphDefault>
                    <ParagraphDefault margin={'2px 0 30px 0'}>{news.description}</ParagraphDefault>
                  </section>
                )
              })
            ) : (
              <section key={data.title}>
                <SectionTitle>{data.title}</SectionTitle>
                <ParagraphDefault margin={'15px 0 0 0'} fontWeight={'bold'}>
                  {formatDate(data.createdAt)}
                </ParagraphDefault>
                <ParagraphDefault margin={'2px 0 30px 0'}>{data.description}</ParagraphDefault>
              </section>
            )}

            <section>
              <SectionTitle>Subindo a bordo</SectionTitle>
              <ParagraphDefault>
                Tradutores automáticos, aqui não! Muitos alunos tinham o tradutor ativo no seu
                navegador, o que afetava sua experiência. Mas já está resolvido, tradutor nenhum
                afetará nossos alunos! Hehe
              </ParagraphDefault>
              <ParagraphDefault>
                Uma plataforma nova não pode ser uma incógnita! Por isso, criamos um onboarding
                super rápido que explica o passo a passo ideal na plataforma para que você tenha a
                melhor experiência possível! Ver onboarding
              </ParagraphDefault>
            </section>

            <section>
              <SectionTitle>Durante o voô</SectionTitle>
              <ParagraphDefault margin={'15px 0 0 0'} fontWeight={'bold'}>
                Teve problemas com ruídos em seus exercícios?
              </ParagraphDefault>
              <ParagraphDefault margin={'2px 0 30px 0'}>
                Nossa inteligência artificial trabalhará para anular qualquer ruído inesperado
                durante seus áudios! Mesmo assim recomendamos que procure um lugar silencioso e
                tranquilo para sua prática, assim nenhum ruído afetará sua perfomance!
              </ParagraphDefault>

              <ParagraphDefault margin={'15px 0 0 0'} fontWeight={'bold'}>
                Aprendemos com mais facilidade apurando nossos sentidos
              </ParagraphDefault>
              <ParagraphDefault margin={'2px 0 30px 0'}>
                Agora, ao gravar sua resposta nos exercícios, você consegue ouvir seu próprio áudio
                e identificar todos os pontos que precisa.
              </ParagraphDefault>

              <ParagraphDefault margin={'15px 0 0 0'} fontWeight={'bold'}>
                Psiu, você com mobile
              </ParagraphDefault>
              <ParagraphDefault margin={'2px 0 30px 0'}>
                Consertamos vários componentes e fluxos para que sua experiência seja cada vez
                melhor! Esperamos que curta (:
              </ParagraphDefault>
            </section>
          </Sections>
        </DisplayGrid>
      </ModalDefault>
    </>
  )
}
