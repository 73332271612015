import { Container, EditButton, HeaderSpacer, SearchField, TableHeader } from '../../styles'
import ConquerTablePaginated from '../../Template/ConquerTablePaginated'
import FilterMentoring from '../../Pages/MentoringRecorded/components/FilterMentoring'
import { columnsMentoring, mentoringFilters } from './mentoringConstants'
import { getFilters } from './mentoringFunctions'
import Modal from '../../Template/Modal'
import { DetailsMentoring } from './components/DetailsMentoring'
import { EditMentoring } from './components/EditMentoring'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function MentoringRecorded() {
  const [data, setData] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [actualPage, setActualPage] = useState(1)
  const [pageTotal, setPageTotal] = useState(1)
  const [filterResultData, setFilterResultData] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const [search, setSearch] = useState('')
  const [reloadPage, setReloadPage] = useState(false)
  const [teacherList, setTeacherList] = useState()

  const editButton = [
    {
      content: (v) =>
        v.status !== 'CANCELED' ? (
          <EditButton onClick={() => handleEdit(v)}>
            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          </EditButton>
        ) : null,
      props: { center: true },
    },
  ]

  const defineColumns = columnsMentoring.concat(editButton)
  const columns = React.useMemo(() => defineColumns, [])

  const handleEdit = (v) => {
    setSelected([v])
    setShowEditModal(true)
  }

  useEffect(() => {
    let isMounted = true
    getData(isMounted, 1)
    !teacherList && getTeachers(isMounted)
    return () => (isMounted = false)
  }, [filterResultData])

  useEffect(() => {
    reloadPage && getData(true, actualPage).then(() => setReloadPage(false))
  }, [reloadPage])

  async function getData(isMounted, page) {
    setLoadingTable(true)
    const url = '/admin/mentoring/paginated-by-filter'
    const pageFilter = getFilters(page, filterResultData, search)
    if (isMounted) {
      await axios.post(url, pageFilter).then((res) => {
        setPageTotal(res.data.totalPages)
        setData(res.data.content)
        setActualPage(res.data.pageable.pageNumber + 1)
      })
    }

    setLoadingTable(false)
  }

  const getTeachers = async (isMounted) => {
    if (isMounted) {
      try {
        await axios.get('/admin/mentor').then((res) => setTeacherList(res.data))
      } catch (error) {
        setTeacherList([{}])
      }
    }
  }

  function handlePageChange(page) {
    getData(true, page)
  }

  const filters = React.useMemo(() => mentoringFilters)

  function handleFilterResult(e) {
    setFilterResultData(e)
  }

  return (
    <Container>
      <TableHeader>
        <span>Mentorias</span>
        <div>
          {teacherList && (
            <FilterMentoring
              teacherList={teacherList}
              data={data}
              filters={filters}
              result={handleFilterResult}
            />
          )}
          <HeaderSpacer />
          <SearchField>
            <input
              type='text'
              placeholder='Pesquisar aluno'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button onClick={() => getData(true, 1)}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </SearchField>
          <HeaderSpacer />
        </div>
      </TableHeader>
      <ConquerTablePaginated
        columns={columns}
        data={data}
        totalNumPages={pageTotal}
        loading={loadingTable}
        onPageChange={handlePageChange}
      />
      {showEditModal && (
        <Modal close={() => setShowEditModal(false)} title={'Editar Mentoria'}>
          <DetailsMentoring mentoring={selected} />
          <EditMentoring
            setShowEditModal={setShowEditModal}
            mentoring={selected}
            setReloadPage={setReloadPage}
          />
        </Modal>
      )}
    </Container>
  )
}
