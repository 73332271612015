import React, { createContext, useState, useContext, useEffect } from 'react'

const UserContext = createContext()

export default function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState()

  useEffect(() => {
    if (
      localStorage.getItem('userInfo') !== 'undefined' &&
      localStorage.getItem('userInfo') != null
    ) {
      setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
    }
  }, [])

  useEffect(() => {
    if (userInfo === undefined && localStorage.getItem('userInfo') !== 'undefined') {
      setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
    }
  }, [userInfo])

  return <UserContext.Provider value={{ userInfo, setUserInfo }}>{children}</UserContext.Provider>
}

export function useUserInfo() {
  const context = useContext(UserContext)
  const { userInfo, setUserInfo } = context
  return { userInfo, setUserInfo }
}
