import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Container, HeaderSpacer, TableHeader, EditButton } from '../../styles'

import Modal from '../../Template/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faEdit } from '@fortawesome/pro-solid-svg-icons'
import List from './components/List'
import Filter from '../../Template/Filter'
import SearchBox from '../../Template/SearchBox'
import ConquerTable from '../../Template/ConquerTable'

export default function AdminExercises() {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState(null)
  const [filterResultData, setFilterResultData] = useState(null)
  const [searchResultData, setSearchResultData] = useState(null)
  const [contentId, setContentId] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubtitle, setModalSubtitle] = useState('')
  const [showEditModal, setShowEditModal] = useState(false)
  const [loadingTable, setLoadingTable] = useState(true)

  const columns = React.useMemo(
    () => [
      { title: 'Nome', field: 'name' },
      {
        title: 'Nível',
        field: 'studentLevelName',
        content: (v) => v.studentLevelName?.toLowerCase() || '-',
        props: {
          textTransform: 'capitalize',
        },
      },
      { title: 'Módulo', field: 'moduleName' },
      { title: 'Lesson', field: 'lessonName' },
      {
        title: 'Sequência',
        field: 'sequence',
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Capa',
        content: (v) => (
          <a
            target='_blank'
            rel='noreferrer'
            href={v.thumb}
            style={{ color: 'white', fontSize: '2rem' }}
          >
            <FontAwesomeIcon icon={faImage} />
          </a>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        content: (v) => (
          <EditButton onClick={() => handleEdit(v)}>
            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          </EditButton>
        ),
        props: {
          center: true,
        },
      },
    ],
    [],
  )

  const filters = React.useMemo(
    () => [
      {
        title: 'Nível',
        field: 'studentLevelName',
        options: [
          { text: 'Beginner', value: 'BEGINNER' },
          { text: 'Intermediate', value: 'INTERMEDIATE' },
          { text: 'Advanced', value: 'ADVANCED' },
        ],
      },
    ],
    [],
  )

  useEffect(() => {
    let isMounted = true

    getData(isMounted)

    return () => (isMounted = false)
  }, [])

  async function getData(isMounted) {
    setLoadingTable(true)

    await axios.get('/admin/content/video').then((res) => {
      if (isMounted) {
        setData(
          res.data.payload.sort(function (a, b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          }),
        )
        setLoadingTable(false)
      }
    })
  }

  function handleCloseEditModal() {
    setShowEditModal(false)
  }

  function handleEdit(v) {
    setModalTitle('Exercícios')
    setModalSubtitle(v.name)
    setContentId(v.id)
    setShowEditModal(true)
  }

  function handleSearchResult(e) {
    setSearchResultData(e)
  }

  function handleFilterResult(e) {
    setFilterResultData(e)
  }

  useEffect(() => {
    if (searchResultData && filterResultData) {
      setFilteredData(filterResultData.filter((d) => searchResultData.includes(d)))
    } else {
      setFilteredData(searchResultData || filterResultData)
    }
  }, [searchResultData, filterResultData])

  return (
    <>
      <Container>
        <TableHeader>
          <span>Listagem dos Steps (Vídeos Gravados)</span>
          <div>
            <Filter data={data} filters={filters} result={handleFilterResult} />
            <HeaderSpacer />
            <SearchBox data={data} fields={['name', 'moduleName']} result={handleSearchResult} />
            <HeaderSpacer />
          </div>
        </TableHeader>
        <ConquerTable
          columns={columns}
          data={filteredData || data}
          rowsPerPage={18}
          loading={loadingTable}
        />
      </Container>

      {showEditModal && (
        <Modal close={handleCloseEditModal} title={modalTitle} subtitle={modalSubtitle}>
          <List contentId={contentId} />
        </Modal>
      )}
    </>
  )
}
