import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import {
  TableContainer,
  Table,
  Th,
  Td,
  Pagination,
  PaginationButton,
  TableSpacer,
  NoDataSpan,
  LoadingContainer,
} from './styles'

import loadingData from '../../../../assets/json/loading.json'

export default function ConquerTablePaginated({
  columns,
  data,
  totalNumPages,
  noDataText,
  loading,
  padding,
  backgroundColor,
  disablePagination,
  onPageChange,
}) {
  const [tableData, setTableData] = useState([])
  const [actualPage, setActualPage] = useState(1)
  const [pageTotal, setPageTotal] = useState(1)
  const [paginationButtons, setPaginationButtons] = useState([])

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    if (data) {
      renderTableData()
      renderPaginationButtons()
      setPageTotal(totalNumPages)
    }
  }, [data])

  useEffect(() => {
    renderPaginationButtons()
  }, [actualPage])

  function renderTableData() {
    setTableData(
      data.map((data, index) => (
        <tr key={index}>
          {columns.map((column, index) =>
            column.field === 'slotMentoring' ? (
              <Td key={index} {...column.props}>
                {column.content ? (
                  column.content(data)
                ) : (
                  <span>
                    {data['startHour']} - {data['finishHour']}
                  </span>
                )}
              </Td>
            ) : (
              <Td key={index} {...column.props}>
                {column.content ? column.content(data) : <span>{data[column.field]}</span>}
              </Td>
            ),
          )}
        </tr>
      )),
    )
  }

  function renderPaginationButtons() {
    const pageTotal = totalNumPages
    const buttons = []

    function shouldShowButton(pageNumber) {
      return (
        pageNumber === 1 ||
        pageNumber === pageTotal ||
        pageNumber === actualPage ||
        (pageNumber < actualPage && pageNumber > actualPage - 2) ||
        (pageNumber > actualPage && pageNumber < actualPage + 2) ||
        pageTotal <= 5
      )
    }

    for (let i = 2; i < pageTotal; i++) {
      buttons.push(
        <PaginationButton
          key={i}
          onClick={() => setPage(i)}
          show={shouldShowButton(i)}
          active={i === actualPage}
        >
          {i}
        </PaginationButton>,
      )
    }
    setPaginationButtons(buttons)
  }

  function actualPageData() {
    return tableData
  }

  function setPage(page) {
    setActualPage(page)
    onPageChange(page)
  }

  function decreasePage() {
    const page = actualPage - 1
    setActualPage(page)
    onPageChange(page)
  }

  function increasePage() {
    const page = actualPage + 1
    setActualPage(page)
    onPageChange(page)
  }

  return (
    <>
      <TableContainer padding={padding} backgroundColor={backgroundColor}>
        <Table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <Th key={index} {...column.props}>
                  {column.title}
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className='no-hover'>
              <TableSpacer colSpan={100} />
            </tr>
            {loading ? (
              <tr className='no-hover'>
                <LoadingContainer colSpan={100}>
                  <Lottie options={loadingOptions} height={298} width={298} />
                </LoadingContainer>
              </tr>
            ) : data.length > 0 ? (
              actualPageData()
            ) : (
              <tr>
                <NoDataSpan colSpan={100}>
                  {noDataText || 'Não há registros a serem exibidos nesta tabela.'}
                </NoDataSpan>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>

      {!loading && !disablePagination && (
        <Pagination>
          <PaginationButton show onClick={() => decreasePage()} disabled={actualPage === 1}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </PaginationButton>
          <PaginationButton show onClick={() => setPage(1)} active={actualPage === 1}>
            1
          </PaginationButton>
          <PaginationButton show={pageTotal > 5 && actualPage > 2} disabled dots>
            · · ·
          </PaginationButton>
          {paginationButtons}
          <PaginationButton show={pageTotal > 5 && actualPage < pageTotal} disabled dots>
            · · ·
          </PaginationButton>
          <PaginationButton
            show={pageTotal > 1}
            onClick={() => setPage(pageTotal)}
            active={actualPage === pageTotal}
          >
            {pageTotal}
          </PaginationButton>
          <PaginationButton show onClick={() => increasePage()} disabled={actualPage === pageTotal}>
            <FontAwesomeIcon icon={faChevronRight} />
          </PaginationButton>
        </Pagination>
      )}
    </>
  )
}
