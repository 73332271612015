import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Container } from '../../../../styles'
import ConquerTable from '../../../../Template/ConquerTable'
import { studentsColumns, nullStudentArray } from './structure'
import { TextContainer } from './styles'

export default function ViewModalContainer(props) {
  const [students, setStudents] = useState(null)
  const [loadingTable, setLoadingTable] = useState(true)

  useEffect(() => {
    async function fetchStudents() {
      await axios.get(`admin/live/${props.liveId}/students`).then((res) => setStudents(res.data))
      setLoadingTable(false)
    }

    fetchStudents()
  }, [props.liveId])

  return (
    <Container>
      {students && students.length > 0 ? (
        <ConquerTable
          columns={studentsColumns}
          data={students || nullStudentArray}
          loading={loadingTable}
        />
      ) : (
        <TextContainer>Não há alunos agendados para essa aula.</TextContainer>
      )}
    </Container>
  )
}
