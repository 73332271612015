import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Lottie from 'react-lottie'

import {
  Badge,
  ConfirmButton,
  Container,
  ContainerFlexWrap,
  EditButton,
  HeaderSpacer,
  InputBoxDynamic,
  NewButton,
  SearchField,
  TableHeader,
  Tabs,
} from '../../styles'
import { LoadingContainer, NewEnrollmentButtonPositioning, SuccessMessageStyle } from './styles'
import Modal from '../../Template/Modal'
import Alert from '../../Template/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretLeft,
  faCheck,
  faEye,
  faPencil,
  faSearch,
  faTimes,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import ConquerTablePaginated from '../../Template/ConquerTablePaginated'
import ConquerTable from '../../Template/ConquerTable'
import ViewModalLive from './components/ViewModalLive'
import NewEnrollmentForm from './components/NewEnrollmentForm'
import loadingData from '../../../../assets/json/loading.json'
import { faBan, faEdit } from '@fortawesome/pro-light-svg-icons'
import EditLevel from './components/EditLevel'
import FieldEditor from './components/FieldEditor'
import { Navigate } from 'react-router-dom'
import ViewModalMentoring from './components/ViewModalMentoring'
import {
  formatTimestampDDMMYYYY,
  formatTimestampDDMMYYYYhhmmss,
} from '../../../../utils/DateFormatter'

export default function Student() {
  const [data, setData] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalWidth, setModalWidth] = useState()
  const [modalSubtitle, setModalSubtitle] = useState('')
  const [formComponent, setFormComponent] = useState()
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [loadingTable, setLoadingTable] = useState(true)
  const [itemsPerPage, setItemsPerPage] = useState(18)
  const [totalNumRecords, setTotalNumRecords] = useState()
  const [totalNumPages, setTotalNumPages] = useState()
  const [selectedUser, setSelectedUser] = useState(false)
  const [selectedUserData, setSelectedUserData] = useState()
  const [selectedTab, setSelectedTab] = useState('dados')
  const [multiprofile, setMultiprofile] = useState()
  const [updateMultiprofile, setUpdateMultiprofile] = useState()
  const [enableUser, setEnableUser] = useState()
  const [loadingSelectedUser, setLoadingSelectedUser] = useState(true)
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
  const [isEmailResentSuccess, setIsEmailResentSuccess] = useState(false)
  const [isEnabledSuccess, setIsEnabledSuccess] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const [resetFilter, setResetFilter] = useState(false)
  const [diagnostics, setDiagnostics] = useState([])
  const [loginRedirect, setLoginRedirect] = useState(false)

  const userColumns = React.useMemo(() => [
    { title: 'Nome', field: 'name' },
    { title: 'E-mail', field: 'email' },
    {
      title: 'Nível',
      field: 'studentLevelName',
      content: (v) => v.studentLevelName?.toLowerCase() || '-',
      props: {
        textTransform: 'capitalize',
      },
    },
    {
      title: 'Visualizar dados do Aluno',
      id: 'actions',
      content: (v) => (
        <EditButton onClick={() => handleUserSelect(v)}>
          <FontAwesomeIcon icon={faEye} />
        </EditButton>
      ),
      width: 20,
    },
  ])

  const diagnosticsColumns = React.useMemo(() => [
    {
      title: 'Data de início',
      field: 'createdAtFormatted',
    },
    {
      title: 'Status',
      content: (v) => (
        <div>
          {v.state} <br />
          {v.redoDiagnostic && (
            <>
              <Badge>Liberado em {v.releasedDiagnosticFormatted}</Badge>
              <br />O aluno já pode refazer o nivelamento! Por favor envie o link:
              <br />
              https://level.conquerenglish.com.br/
              <br />O aluno deve utilizar o <b>mesmo email</b> de acesso da plataforma.
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Nível',
      field: 'classification',
      content: (v) => v.classification?.toLowerCase() || '-',
      props: {
        textTransform: 'capitalize',
      },
    },
    {
      title: 'Liberar nova tentativa',
      id: 'actions',
      content: (v) => (
        <>
          {!v.redoDiagnostic ? (
            <>
              <EditButton onClick={() => enableDiagnostic(v.id)} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faEdit} />
              </EditButton>
              <div>Clique para liberar</div>
            </>
          ) : (
            'Já está liberado.'
          )}
        </>
      ),
      width: 20,
    },
    {
      title: 'Ver resultado',
      id: 'actions',
      content: (v) => (
        <EditButton
          onClick={() => {
            window.open(v.url, '_blank').focus()
          }}
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faEye} />
        </EditButton>
      ),
      width: 20,
    },
  ])

  useEffect(() => {
    let isMounted = true
    getData(isMounted)
    return () => (isMounted = false)
  }, [])

  async function getData(isMounted, pageNumber) {
    setLoadingTable(true)
    const page = pageNumber ? pageNumber : 0

    await axios
      .get(
        '/admin/student/metadata?page=' + page + '&pageSize=' + itemsPerPage + '&search=' + search,
      )
      .then((res) => {
        setTotalNumRecords(res.data.payload.totalNumUsers)
        setTotalNumPages(res.data.payload.totalNumPages)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setLoginRedirect(true)
        }
      })
    await axios
      .get('/admin/student/search?page=' + page + '&pageSize=' + itemsPerPage + '&search=' + search)
      .then((res) => {
        if (isMounted) {
          setData(
            res.data.payload.content.sort(function (a, b) {
              if (a.name > b.name) return 1
              if (a.name < b.name) return -1
              return 0
            }),
          )
          setLoadingTable(false)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setLoginRedirect(true)
        }
      })
  }

  async function getDiagnostics(studentId) {
    await axios.get(`/admin-cx/diagnostic/${studentId}`).then((resp) => {
      setDiagnostics(resp.data)
    })
  }

  async function enableDiagnostic(diagnosticId) {
    await axios
      .put(`/admin-cx/diagnostic/${diagnosticId}/enable`)
      .then()
      .finally(() => {
        getDiagnostics(selectedUserData?.studentIdCore)
      })
  }

  useEffect(() => {
    if (selectedUser) {
      getStudentById(selectedUser.id)
      getMultiprofile()
    }
  }, [selectedUser])

  useEffect(() => {
    if (selectedTab === 'nivelamentos') {
      getDiagnostics(selectedUserData?.studentIdCore)
    }
  }, [selectedTab, selectedUser])

  useEffect(() => {
    if (search || resetFilter) {
      getData(true, 0)
      setResetFilter(false)
    }
  }, [search, resetFilter])

  useEffect(() => {
    if (selectedUserData !== null) {
      setTimeout(() => {
        setLoadingTable(false)
        setLoadingSelectedUser(false)
      }, 1500)
    }
  }, [selectedUserData])

  async function getStudentById(studentId) {
    setLoadingTable(true)
    setLoadingSelectedUser(true)
    await axios
      .get(`/admin/student/${studentId}/details`)
      .then((res) => {
        setSelectedUserData(res.data)
        console.log(res.data)
      })
      .catch((error) => {
        setLoadingTable(false)
      })
  }

  function openNewEnrollmentForm() {
    const payload = {
      email: '',
      name: '',
      cpf: '',
      phone: '',
      codeAcquisition: '',
      level: '',
      product: '',
      dualAccess: '',
    }

    setFormComponent(
      <NewEnrollmentForm
        payload={payload}
        showAlert={handleAlert}
        close={handleClose}
        updateData={getData}
      />,
    )

    setModalTitle('Matricular novo Aluno(a)')
    setModalSubtitle('')
    setShowModal(true)
  }

  function openTab(tab) {
    if (tab === 'dados') {
      setSelectedTab('dados')
    } else if (tab === 'mentoria') {
      setSelectedTab('mentoria')
    } else if (tab === 'checkpoints') {
      setSelectedTab('checkpoint')
    } else if (tab === 'nivelamentos') {
      setSelectedTab('nivelamentos')
    } else if (tab === 'multiperfil') {
      setSelectedTab('multiperfil')
    } else if (tab === 'credits') {
      setSelectedTab('credits')
    }
  }

  function handleClose() {
    setShowModal(false)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handlePageChange(page) {
    getData(true, page)
  }

  function handleUserSelect(e) {
    setSelectedUser(e)
  }

  function handleEnableUserChange(e) {
    setEnableUser(e.target.value)
    setIsEnabledSuccess(true)
    setTimeout(() => {
      setIsEnabledSuccess(false)
    }, 4000)
  }

  useEffect(() => {
    handleEnableUserUpdate()
  }, [enableUser])

  function handleEnableUserUpdate() {
    if (!selectedUser) {
      return
    }
    const payload = {
      studentId: selectedUser.id,
      enabled: enableUser,
    }
    axios.post('/admin/student/status', payload).then((res) => {
      let userUpdate = { ...selectedUserData }
      userUpdate.enabled = enableUser
      setSelectedUserData(userUpdate)
    })
  }

  function clearFilter() {
    setFilter('')
    setSearch('')
    setResetFilter(true)
  }

  function searchByFilter() {
    setSearch(filter)
  }

  function handleFilterChange(e) {
    setFilter(e.target.value.toLowerCase())
  }

  function handleFilterKeyPress(e) {
    if (e.key === 'Enter') setSearch(filter)
  }

  async function handleResendWelcomeEmail() {
    if (!selectedUserData.id) {
      // todo exibir msg para o usuario
      return
    }

    axios
      .put(`/admin-cx/student/resend-welcome-email`, {
        studentId: selectedUser.id,
      })
      .then((res) => {
        setIsEmailResentSuccess(true)
        setTimeout(() => {
          setIsEmailResentSuccess(false)
        }, 3000)
      })
  }

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  function getUserProfileFormatter(profile) {
    switch (profile) {
      case 'ENGLISH_RECORDED':
        return 'Conquer English Online'
      case 'ENGLISH_HYBRID':
        return 'Conquer English Live'
      case 'ENGLISH_PREMIUM':
        return 'Conquer English Online Supreme'
    }
    return 'N/A'
  }

  function openEditModal() {
    setFormComponent(
      <EditLevel
        payload={{
          id: selectedUserData?.id,
          level: selectedUserData?.level,
        }}
        close={handleClose}
        onError={handleError}
        onResponse={handleResponse}
      />,
    )
    setShowModal(true)
  }

  function handleResponse(id) {
    getStudentById(id)
    setShowModal(false)
    setHideAlert(false)
    setAlertSuccess(true)
    setAlertMessage('Nível do aluno atualizado com sucesso!')
  }

  function handleError() {
    setShowModal(false)
    setHideAlert(false)
    setAlertSuccess(false)
    setAlertMessage('Não foi possível editar o nível do aluno!')
  }

  function isEnabled(enable) {
    return (
      <>
        <select name='enable' value={selectedUserData?.enabled} onChange={handleEnableUserChange}>
          <option value={false}>Não</option>
          <option value={true}>Sim</option>
        </select>

        {isEnabledSuccess && (
          <SuccessMessageStyle>
            <FontAwesomeIcon
              icon={faCheck}
              color={'green'}
              style={{ fontSize: '20px', margin: '10px' }}
            />
            <label>Alterado com sucesso</label>
          </SuccessMessageStyle>
        )}
      </>
    )
  }
  function getStatus(status) {
    switch (status) {
      case 'ACCEPTED':
        return 'Perfil Ativo'
      case 'PENDING':
        return 'Convite Pendente'
      case 'CANCELED':
        return 'Convite Cancelado'
      default:
        return 'Owner'
    }
  }

  function handleOpenMultiprofileUser(e) {
    if (getStatus(e.status) === 'Convite Pendente') return
    setLoadingTable(true)
    axios
      .get(`/admin/student/${e.studentId}/details`)
      .then((response) => {
        handleUserSelect(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  function handleRemoveUserSubmit(e) {
    setLoadingTable(true)
    setShowModal(false)
    axios
      .put(`/admin/invite/${e.inviteId}/cancel`)
      .then((response) => {
        setAlertMessage('Convite cancelado com sucesso! Atualize a página')
        setAlertSuccess(true)
        setHideAlert(false)
      })
      .catch((error) => {
        setAlertMessage('Não foi possível cancelar o convite!')
        setAlertSuccess(false)
        setHideAlert(false)
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  function handleRemoveUser(e) {
    setModalTitle('Deseja remover o aluno(a)?')
    setModalSubtitle('')
    setShowModal(true)

    setFormComponent(
      <>
        <div className='form-group'>
          <p style={{ color: '#fff', textAlign: 'center', fontSize: '26px' }}>{e.name}</p>
          <div style={{ float: 'right' }}>
            <ConfirmButton onClick={() => handleRemoveUserSubmit(e)}>
              <span>Confirmar</span>
              <FontAwesomeIcon icon={faCheck} />
            </ConfirmButton>
          </div>
        </div>
      </>,
    )
  }

  function getMultiprofile() {
    setLoadingTable(true)
    axios
      .get(`/admin/student/${selectedUser.id}/profile-signatures`)
      .then((response) => {
        setMultiprofile(response.data)
        setUpdateMultiprofile(response.data?.total)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  async function handleDefaultPasswordReset() {
    if (!selectedUserData.id) {
      return
    } else if (multiprofile?.isOwner === false) {
      alert('Para resetar a senha de acesso, edite o perfil do administrador dessa conta.')
      return
    }
    axios.post(`/admin/student/${selectedUserData.id}/reset-password-default`).then((res) => {
      setIsPasswordResetSuccess(true)
      setTimeout(() => {
        setIsPasswordResetSuccess(false)
      }, 3000)
    })
  }

  function handleUpdateMultiprofile() {
    setLoadingTable(true)
    if (updateMultiprofile === '' || undefined) return
    axios
      .patch(`/admin/student/${selectedUser.id}/multi-profiles/${updateMultiprofile}`)
      .then(() => alert('Perfil atualizado com sucesso!'))
      .catch((error) => {
        alert(error.response.data.msg + ' Fale com time de tech')
      })
      .finally(() => {
        getMultiprofile()
        setLoadingTable(false)
      })
  }

  function handleSelectChangeMultiprofile(e) {
    setUpdateMultiprofile(e.target.value)
  }

  function changeMultiprofilelPlane() {
    return (
      <>
        <select
          name='change-multiprofile'
          onChange={handleSelectChangeMultiprofile}
          value={updateMultiprofile}
          disabled={multiprofile?.isOwner === false}
        >
          <option value=''>Selecione...</option>
          <option value='1'>1 perfil</option>
          <option value='2'>2 perfis</option>
          <option value='3'>3 perfis</option>
        </select>

        <input
          type='button'
          value='Alterar perfil'
          onClick={() => handleUpdateMultiprofile()}
          disabled={multiprofile?.isOwner === false}
          style={
            multiprofile?.isOwner === false
              ? { cursor: 'not-allowed', marginBottom: '-5px' }
              : { cursor: 'pointer', marginBottom: '-5px' }
          }
        />
      </>
    )
  }

  function showList() {
    setSelectedUser(null)
    setSelectedUserData(null)
    setIsEnabledSuccess(false)
    setMultiprofile(null)
    setUpdateMultiprofile(null)
  }

  function editLiveCredits() {
    setFormComponent(
      <ViewModalLive
        showAlert={handleAlert}
        close={handleClose}
        studentId={selectedUser.id}
        type='live-class'
        disablePagination
      />,
    )

    setModalTitle('Aulas ao vivo Reservadas')
    setModalSubtitle('')
    setModalWidth('60%')
    setShowModal(true)
  }

  function editMentorCredits() {
    setFormComponent(
      <ViewModalMentoring
        showAlert={handleAlert}
        close={handleClose}
        studentId={selectedUser.id}
        type='mentoring'
        disablePagination
      />,
    )

    setModalTitle('Mentorias Reservadas')
    setModalSubtitle('')
    setModalWidth('60%')
    setShowModal(true)
  }

  let users = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle} width={modalWidth}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span>Alunos - Online/Live/Supreme</span>
          <div>
            <HeaderSpacer />
            <SearchField>
              <input
                type='text'
                placeholder='Pesquisar'
                value={filter}
                onChange={handleFilterChange}
                onKeyPress={handleFilterKeyPress}
              />
              <button onClick={() => searchByFilter()}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
              {search && search !== '' && (
                <a onClick={() => clearFilter()}>
                  <FontAwesomeIcon icon={faTimes} />
                  Limpar pesquisa
                </a>
              )}
            </SearchField>
            <NewEnrollmentButtonPositioning>
              <NewButton onClick={() => openNewEnrollmentForm()}>Matricular novo aluno</NewButton>
            </NewEnrollmentButtonPositioning>
            <HeaderSpacer />
          </div>
        </TableHeader>
        <ConquerTablePaginated
          columns={userColumns}
          data={data}
          totalNumPages={totalNumPages}
          loading={loadingTable}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  )

  let userView = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle} width={modalWidth}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader style={{ padding: '0 0 0 60px' }}>
          <span onClick={() => showList()} style={{ padding: '0 12px 0 12px', cursor: 'pointer' }}>
            <FontAwesomeIcon
              icon={faCaretLeft}
              color='yellow'
              style={{
                cursor: 'pointer',
                fontSize: '3rem',
                marginBottom: '-5px',
              }}
            />{' '}
            Voltar para a listagem
          </span>
        </TableHeader>
        <Tabs>
          <div className='actions'>
            <button
              className={selectedTab === 'dados' ? 'selectedTab' : ''}
              onClick={() => openTab('dados')}
            >
              Dados
            </button>

            <button
              className={selectedTab === 'nivelamentos' ? 'selectedTab' : ''}
              onClick={() => openTab('nivelamentos')}
            >
              Nivelamentos
            </button>

            <button
              className={selectedTab === 'multiperfil' ? 'selectedTab' : ''}
              onClick={() => openTab('multiperfil')}
            >
              Multiperfil
            </button>

            <button
              className={selectedTab === 'credits' ? 'selectedTab' : ''}
              onClick={() => openTab('credits')}
              hidden={selectedUserData?.profile === 'ENGLISH_RECORDED'}
            >
              Créditos
            </button>
          </div>
        </Tabs>

        {loadingSelectedUser ? (
          <LoadingContainer colSpan={100}>
            <Lottie options={loadingOptions} height={298} width={298} />
          </LoadingContainer>
        ) : (
          <>
            <ContainerFlexWrap hide={selectedTab !== 'dados'}>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Nome</label>

                <FieldEditor
                  label={selectedUserData?.name}
                  userId={selectedUser?.id}
                  field={'name'}
                />
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>E-mail</label>
                <FieldEditor
                  label={selectedUserData?.email}
                  userId={selectedUser?.id}
                  field={'email'}
                />
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Nível</label>
                <span>
                  {selectedUserData?.level}
                  <span
                    style={{
                      color: 'yellow',
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={() => openEditModal()}
                  >
                    <FontAwesomeIcon icon={faEdit} size='lg' />
                  </span>
                </span>
              </InputBoxDynamic>

              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Data da matrícula (Horário de Brasília)</label>
                <span>{formatTimestampDDMMYYYYhhmmss(selectedUserData?.createdAtUnix)}</span>
              </InputBoxDynamic>

              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Tipo de acesso do aluno</label>
                <span>
                  <FieldEditor
                    label={selectedUserData?.profile}
                    labelFormatter={getUserProfileFormatter}
                    userId={selectedUser?.id}
                    field={'profile'}
                  />
                </span>
              </InputBoxDynamic>

              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Data de fim da assinatura</label>
                <span>
                  <FieldEditor
                    label={formatTimestampDDMMYYYY(selectedUserData?.dueAccessUnix)}
                    userId={selectedUser?.id}
                    field={'dueAccess'}
                    owner={multiprofile ? multiprofile?.isOwner : true}
                  />
                </span>
              </InputBoxDynamic>

              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Primeiro acesso (Horário de Brasília)</label>
                <span>{formatTimestampDDMMYYYYhhmmss(selectedUserData?.firstAccessUnix)}</span>
              </InputBoxDynamic>
              <InputBoxDynamic display='grid' flexSpacing='1 2 21%'>
                <label>Último acesso (Horário de Brasília)</label>
                <span>{formatTimestampDDMMYYYYhhmmss(selectedUserData?.lastAccessUnix)}</span>
              </InputBoxDynamic>

              <InputBoxDynamic display='grid' flexSpacing='0 1 25%'>
                <label>Conta ativa?</label>

                {isEnabled(selectedUserData?.enabled)}
              </InputBoxDynamic>
            </ContainerFlexWrap>

            <ContainerFlexWrap hide={selectedTab !== 'dados'}>
              {isPasswordResetSuccess ? (
                <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={'green'}
                    style={{ fontSize: '20px', margin: '10px' }}
                  />
                  <label>
                    Senha resetado com successo!
                    <br />
                    (senha padrão Conquer01)
                  </label>
                </InputBoxDynamic>
              ) : (
                <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
                  <span>
                    Resetar senha manualmente
                    <br />
                    (senha padrão Conquer01)
                  </span>
                  <input
                    type='button'
                    value='Resetar para senha padrão'
                    onClick={() => handleDefaultPasswordReset()}
                  ></input>
                </InputBoxDynamic>
              )}
            </ContainerFlexWrap>

            <ContainerFlexWrap hide={selectedTab !== 'dados'}>
              {isEmailResentSuccess ? (
                <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={'green'}
                    style={{ fontSize: '20px', margin: '10px' }}
                  />
                  <label>Email de primeiro acesso enviado com successo!</label>
                </InputBoxDynamic>
              ) : (
                <InputBoxDynamic display='flex' flexSpacing='0 1 40%'>
                  <span>Reenviar email de primeiro acesso!</span>
                  <input
                    type='button'
                    value='Reenviar email'
                    onClick={() => handleResendWelcomeEmail()}
                  ></input>
                </InputBoxDynamic>
              )}
            </ContainerFlexWrap>

            <ContainerFlexWrap hide={selectedTab !== 'nivelamentos'}>
              <TableHeader>
                <span>Nivelamentos do(a) Aluno(a)</span>
              </TableHeader>
              <ConquerTable
                columns={diagnosticsColumns}
                data={diagnostics}
                totalNumPages={-2}
                loading={loadingTable}
                onPageChange={handlePageChange}
                disablePagination={true}
              />
            </ContainerFlexWrap>

            <ContainerFlexWrap hide={selectedTab !== 'multiperfil'}>
              <InputBoxDynamic>
                <label
                  style={{
                    fontSize: '20px',
                    margin: ' 0 5px 0 0',
                  }}
                >
                  Tipo do plano
                </label>
                {changeMultiprofilelPlane()}
              </InputBoxDynamic>
            </ContainerFlexWrap>

            <ContainerFlexWrap hide={selectedTab !== 'multiperfil'} class='multiprofile-wrapper'>
              {multiprofile?.invitedUsersDTO?.map((invited) => (
                <>
                  <InputBoxDynamic display='grid' flexSpacing='1 2 19%'>
                    <label
                      style={{
                        fontSize: '20px',
                        marginBottom: '15px',
                      }}
                    >
                      Nome
                    </label>
                    <span style={{ marginBottom: '15px' }}>{invited.name}</span>
                  </InputBoxDynamic>
                  <InputBoxDynamic display='grid' flexSpacing='1 2 19%'>
                    <label
                      style={{
                        fontSize: '20px',
                        marginBottom: '15px',
                      }}
                    >
                      E-mail
                    </label>
                    <span
                      style={{
                        marginBottom: '15px',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                      }}
                    >
                      {invited?.email}
                    </span>
                  </InputBoxDynamic>
                  <InputBoxDynamic display='grid' flexSpacing='1 2 19%'>
                    <label
                      style={{
                        fontSize: '20px',
                        marginBottom: '15px',
                      }}
                    >
                      Status
                    </label>
                    <span field={'status'} style={{ marginBottom: '15px' }}>
                      {getStatus(invited.status)}
                    </span>
                  </InputBoxDynamic>
                  <InputBoxDynamic display='grid' flexSpacing='1 2 10%'>
                    <label
                      style={{
                        fontSize: '20px',
                        marginBottom: '15px',
                      }}
                    >
                      Perfil
                    </label>
                    <span field={'profile'} style={{ marginBottom: '15px' }}>
                      Abrir perfil
                      {getStatus(invited.status) === 'Convite Pendente' ? (
                        <FontAwesomeIcon
                          icon={faEdit}
                          color='yellow'
                          style={{
                            cursor: 'not-allowed',
                            marginBottom: '-5px',
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEdit}
                          color='yellow'
                          style={{
                            cursor: 'pointer',
                            marginBottom: '-5px',
                          }}
                          onClick={() => {
                            handleOpenMultiprofileUser(invited)
                            openTab('dados')
                          }}
                        />
                      )}
                    </span>
                  </InputBoxDynamic>
                  <InputBoxDynamic display='grid' flexSpacing='1 2 19%'>
                    <span>
                      {invited.status === null ? (
                        <FontAwesomeIcon
                          icon={faBan}
                          color='yellow'
                          style={{
                            cursor: 'not-allowed',
                            marginBottom: '-5px',
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTrash}
                          color='yellow'
                          style={{
                            cursor: 'pointer',
                            marginBottom: '-5px',
                          }}
                          onClick={() => {
                            handleRemoveUser(invited)
                            openTab('dados')
                          }}
                        />
                      )}
                    </span>
                  </InputBoxDynamic>
                </>
              ))}
            </ContainerFlexWrap>

            <ContainerFlexWrap hide={selectedTab !== 'credits'} class='credits-wrapper'>
              {selectedUserData?.profile === 'ENGLISH_PREMIUM' ? (
                <>
                  <InputBoxDynamic display='grid' flexSpacing='0 1 25%'>
                    <label style={{ marginBottom: '10px' }}>Cancele aulas ao vivo reservadas</label>
                    <EditButton onClick={() => editLiveCredits()}>
                      <FontAwesomeIcon icon={faPencil} />
                    </EditButton>
                  </InputBoxDynamic>

                  <InputBoxDynamic display='grid' flexSpacing='0 1 21%'>
                    <label style={{ marginBottom: '10px' }}>Cancele mentorias reservadas</label>
                    <EditButton onClick={() => editMentorCredits()}>
                      <FontAwesomeIcon icon={faPencil} />
                    </EditButton>
                  </InputBoxDynamic>
                </>
              ) : (
                selectedUserData?.profile === 'ENGLISH_HYBRID' && (
                  <InputBoxDynamic display='grid' flexSpacing='0 1 25%'>
                    <label style={{ marginBottom: '10px' }}>Altere créditos de aulas ao vivo</label>
                    <EditButton onClick={() => editLiveCredits()}>
                      <FontAwesomeIcon icon={faPencil} />
                    </EditButton>
                  </InputBoxDynamic>
                )
              )}
            </ContainerFlexWrap>
          </>
        )}
      </Container>
    </>
  )

  return (
    <>
      {loginRedirect ? <Navigate to='/login' /> : null}
      {selectedUser ? userView : users}
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
        animationTime={30}
      />
    </>
  )
}
