import styled from 'styled-components'

export const AlertBox = styled.div`
  background-color: ${(props) =>
    props.type === 'success'
      ? '#04844b'
      : props.type === 'danger'
        ? '#c23934'
        : props.type === 'warning'
          ? '#fffe00'
          : 'black'};
  color: ${(props) => (props.type === 'warning' ? 'black' : 'white')};
  margin-top: 20px;
  height: 50px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  font-size: 14px;
`
