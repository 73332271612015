import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  LoginHeader,
  LoginContainer,
  LoginBox,
  LoginTitle,
  LoginText,
  LoginForm,
  TextContainer,
  HelpButton,
} from '../Login/styles'

import { Button, IconButton, TextButton } from '../../components/buttons/confirmButton/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faQuestion } from '@fortawesome/pro-solid-svg-icons'
import Alert from '../../components/alerts/AlertBox'
import { openInNewTab, openInSameTab } from '../../utils/OpenURL'
import { useParams } from 'react-router-dom'

export default function ResetPassword(props) {
  const [data, setData] = useState()
  const [statusSuccess, setStatusSuccess] = useState(false)
  const [invalidToken, setInvalidToken] = useState(false)
  const [errors, setErrors] = useState(false)

  const params = useParams()
  const token = params.token

  async function resetPassword() {
    let send = true

    if (!data.password || data.password.length === 0) {
      setErrors((lg) => ({ ...lg, password: true }))
      send = false
    }
    if (!data.confirmPassword || data.confirmPassword.length === 0) {
      setErrors((lg) => ({ ...lg, confirmPassword: true }))
      send = false
    }

    if (send) {
      const result = await axios
        .request({
          url: `/reset-password`,
          method: 'POST',
          data: data,
        })
        .then((result) => {
          if (result.status === 200) {
            setStatusSuccess(true)
            setTimeout(() => {
              window.location = '/login'
            }, 1000)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  function handleInfo(e) {
    let value = e.target.value
    let name = e.target.name
    setData((lg) => ({ ...lg, [name]: value }))
  }
  console.log('props', token)

  function validateToken() {
    axios
      .request({
        url: `/validate-password-token?t=${token}`,
        method: 'GET',
      })
      .then((response) => {
        setData((lg) => ({ ...lg, token: token }))
      })
      .catch((error) => {
        setInvalidToken(true)
      })
  }

  useEffect(() => {
    validateToken()
  }, [])

  return (
    <>
      <LoginHeader>
        <a href='/'>
          <img src='/img/logo.png' alt='Conquer' height='42' />
        </a>
      </LoginHeader>
      <LoginContainer>
        <LoginBox smallPaddingY>
          <LoginTitle>Quase lá!</LoginTitle>
          {invalidToken ? (
            <>
              <LoginText>
                O Token informado é inválido, clique no botão abaixo para criar ou redefinir sua
                senha
              </LoginText>

              <Button onClick={() => openInSameTab(`${window.origin}/esqueceu-sua-senha`)}>
                <TextButton>Redefinir senha</TextButton>
                <IconButton>
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
              </Button>
            </>
          ) : (
            <>
              <LoginText marginBottomDisabled>Basta digitar sua nova senha abaixo.</LoginText>
              <LoginText>Deve ter pelo menos 6 caracteres e um número</LoginText>

              <LoginForm>
                <label>Senha *</label>
                <input
                  name='password'
                  placeholder='********'
                  type={'password'}
                  errors={errors.password}
                  onChange={(e) => handleInfo(e)}
                />
              </LoginForm>
              <LoginForm>
                <label>Confirmar senha *</label>
                <input
                  name='confirmPassword'
                  placeholder='********'
                  type={'password'}
                  errors={errors.confirmPassword}
                  onChange={(e) => handleInfo(e)}
                />
              </LoginForm>

              {!statusSuccess ? (
                <Button onClick={() => resetPassword()}>
                  <TextButton>Redefinir</TextButton>
                  <IconButton>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </IconButton>
                </Button>
              ) : null}

              <Alert text='Senha alterada com sucesso' type='success' show={statusSuccess} />
            </>
          )}
        </LoginBox>
        <TextContainer hideMobile>
          Welcome to Conquer <span className='yellow'>English</span>
        </TextContainer>
        <HelpButton onClick={() => openInNewTab('https://tamojunto.escolaconquer.com.br/hc/pt-br')}>
          <span>Precisa de ajuda?</span>
          <div className='icon'>
            <FontAwesomeIcon icon={faQuestion} />
          </div>
        </HelpButton>
      </LoginContainer>
    </>
  )
}
