import styled from 'styled-components'

export const SearchField = styled.div`
  height: 42px;
  display: flex;
  position: relative;

  & > input {
    height: 100%;
    border: none;
    background-color: #2a2a2a;
    color: white;
    font-family: 'DM Sans';
    padding: 0px 20px;
    font-size: 15px;
    width: 300px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgb(220, 220, 220);
    }
  }

  & > button {
    height: 100%;
    width: 65px;
    background-color: #ffff00;
    color: black;
    border: none;
    border-radius: 0px;
    font-size: 18px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  & > a {
    position: absolute;
    bottom: -5px;
    left: 20px;
    transform: translateY(100%);
    color: #ffff00;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    & > svg {
      margin-right: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`
