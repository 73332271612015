import React, { useEffect, useState } from 'react'
import { Loading, Wrapper, LogoConquer } from './styles'
import { useLoading } from '../../contexts/loading.context'
import Lottie from 'react-lottie'
import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import animationData from '../../assets/json/loading.json'

export default function LoadingComponent() {
  const [moveEffect, setMoveEffect] = useState({
    offsetX: '',
    offsetY: '',
    friction: 1 / 32,
  })

  function mouseMove(e) {
    let followX = window.innerWidth / 2 - e.clientX
    let followY = window.innerHeight / 2 - e.clientY
    let x = 0,
      y = 0
    x += (-followX - x) * moveEffect.friction
    y += (followY - y) * moveEffect.friction

    setMoveEffect((lg) => ({ ...lg, offsetX: x, offsetY: y }))
  }

  useEffect(() => {
    window.addEventListener('mousemove', (e) => {
      mouseMove(e)
    })
  }, [])

  const { fadeLoading, displayLoading } = useLoading()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Loading isActive={displayLoading} fade={fadeLoading}>
      <Wrapper
        style={{
          transform: `translate(-50%, -50%) perspective(600px)
                    rotateY(${moveEffect.offsetX}deg)
                    rotateX(${moveEffect.offsetY}deg)`,
        }}
      >
        <Lottie options={defaultOptions} height={300} width={300} />
        <LogoConquer>
          <Logo />
        </LogoConquer>
      </Wrapper>
    </Loading>
  )
}
