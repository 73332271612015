import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*overflow: auto;*/
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
`

export const ModalBox = styled.div`
  position: relative;
  background-color: black;
  margin: auto;
  padding: 30px 20px;
  width: ${(props) => props.width || '500px'};
  border: 2px solid #ffff00;
  border-radius: 24px;
  -webkit-box-shadow:
    16px 16px 0px -2px #000000,
    16px 16px 0px 0px #ffff00;
  box-shadow:
    16px 16px 0px -2px #000000,
    16px 16px 0px 0px #ffff00;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-weight: 500;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  right: 30px;
  top: 20px;
`

export const ModalTitleBox = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  text-align: center;
  color: white;

  & > .modal-title {
    font-size: 3rem;
  }

  & > .modal-subtitle {
    font-size: 2rem;
  }
`

export const ModalTitle = styled.span`
  width: 100%;
  text-align: center;
  font-size: 3rem;
  color: white;
`

export const ModalSubtitle = styled.span`
  width: 100%;
  text-align: center;
  font-size: 2rem;
  color: white;
`

export const ModalContent = styled.div`
  width: 100%;
  height: max-content;
  overflow: auto;
  padding: 0px 20px;
`

export const ConfirmButton = styled.button`
  background: #ffff00;
  border: none;
  font-weight: 500;
  font-size: 1.8rem;
  color: black;
  cursor: pointer;
  align-items: center;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  height: 40px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  align-self: flex-end;
  margin-top: 20px;

  &:active {
    outline: none;
  }

  & > svg {
    margin-left: 10px;
  }
`
