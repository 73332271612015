import React, { useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faQuestion } from '@fortawesome/pro-solid-svg-icons'

import Alert from '../../components/alerts/AlertBox'

import { Button, IconButton, TextButton } from '../../components/buttons/confirmButton/styles'

import {
  LoginHeader,
  LoginBox,
  LoginContainer,
  LoginTitle,
  TextContainer,
  LoginForm,
  LoginText,
  HelpButton,
  LinkSmall,
} from '../Login/styles'
import { openInNewTab } from '../../utils/OpenURL'

export default function EsqueceuSuaSenha() {
  const [email, setEmail] = useState({ email: '' })
  const [errors, setErrors] = useState({ email: false })
  const [status, setStatus] = useState(false)
  const [emailNotFoundError, setEmailNotFoundError] = useState(false)
  const [requestError, setRequestError] = useState(false)

  function validateResetPassword() {
    let valid = true

    if (!email.email) {
      setErrors((errors) => ({ ...errors, email: true }))
      valid = false
    }

    return valid
  }

  async function resetPassword() {
    setEmailNotFoundError(false)
    setRequestError(false)

    if (!validateResetPassword()) return
    try {
      const result = await axios.request({
        url: `/forget-password`,
        method: 'POST',
        data: email,
      })

      if (result.status === 200) {
        setStatus(true)
      }
    } catch (error) {
      if (error.response && error.response.status == 400) {
        setEmailNotFoundError(true)
      } else {
        setRequestError(true)
        throw error // repassa a exception para o sentry pegar e recebermos a notificação
      }
    }
  }

  function handleRecaptcha(value) {
    setEmail((lg) => ({ ...lg, reCaptcha: value }))
  }

  function handleInfo(e) {
    setErrors({ ...errors, [e.target.name]: false })
    setEmail({ ...email, [e.target.name]: e.target.value })
  }

  return (
    <>
      <LoginHeader>
        <a href='/'>
          <img src='/img/logo.png' alt='Conquer' height='42' />
        </a>
      </LoginHeader>
      <LoginContainer>
        <LoginBox smallPaddingY>
          <LoginTitle>Esqueceu sua senha?</LoginTitle>
          <LoginText>
            Não tem problema, informe seu e-mail para criar ou redefinir sua senha
          </LoginText>

          <LoginForm error={errors.email}>
            <label>E-mail *</label>
            <input
              name='email'
              placeholder='nome@dominio.com.br'
              type='text'
              onChange={(e) => handleInfo(e)}
              value={email.email}
            />
          </LoginForm>

          {!status ? (
            <Button onClick={() => resetPassword()}>
              <TextButton>Enviar</TextButton>
              <IconButton>
                <FontAwesomeIcon icon={faArrowRight} />
              </IconButton>
            </Button>
          ) : null}

          <LinkSmall href={'/'}>Voltar à tela de login</LinkSmall>

          <Alert text='Por favor, insira seu e-mail' type='danger' show={errors.email} />
          <Alert
            text='O e-mail de redefinição foi enviado com sucesso'
            type='success'
            show={status}
          />
          <Alert text='E-mail não encontrado' type='danger' show={emailNotFoundError} />
          <Alert
            text='Ops, tivemos problemas com sua requisição, recebemos a notificação e já estamos trabalhando para resolver.'
            type='danger'
            show={requestError}
          />
        </LoginBox>
        <TextContainer hideMobile>
          Welcome to Conquer <span className='yellow'>English</span>
        </TextContainer>
        <HelpButton onClick={() => openInNewTab('https://tamojunto.escolaconquer.com.br/hc/pt-br')}>
          <span>Precisa de ajuda?</span>
          <div className='icon'>
            <FontAwesomeIcon icon={faQuestion} />
          </div>
        </HelpButton>
      </LoginContainer>
    </>
  )
}
