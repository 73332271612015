import React from 'react'
import { BlockerBackground, Modal, Title, Description } from './styles'
import { useBlockerModal } from '../../contexts/blockerModal.context'

export default function BlockerModal() {
  const { blockerModal } = useBlockerModal()

  return (
    <BlockerBackground Active={blockerModal.status}>
      <Modal>
        {blockerModal.type !== undefined && blockerModal.type === 'low' ? (
          <div>
            <Title>Heeeey!</Title>
            <Description>{blockerModal.text}</Description>
          </div>
        ) : (
          <div>
            <Title>
              :( <br />
              Opssss!
            </Title>
            <Description>{blockerModal.text}</Description>
          </div>
        )}
      </Modal>
    </BlockerBackground>
  )
}
