import styled, { keyframes } from 'styled-components'

const BALLON_WIDTH = 250
const BALLON_HEIGHT = 140
const BALLON_COLOR = '#4f63f0'

const setPosition = (position) => {
  switch (position) {
    case 'top':
      return `
                top: -${BALLON_HEIGHT + 25}px;
                left: 50%;
                transform: translateX(-50%);
            `
    case 'right':
      return `
                right: -${BALLON_WIDTH + 25}px;
                top: 50%;
                transform: translateY(-50%);
            `
    case 'bottom':
      return `
                bottom: -${BALLON_HEIGHT + 25}px;
                left: 50%;
                transform: translateX(-50%);
            `
    case 'bottom-left':
      return `
                left: -${BALLON_WIDTH + 25}px;
                bottom: -${BALLON_HEIGHT / 2}px;
            `
    case 'left':
      return `
                left: -${BALLON_WIDTH + 25}px;
                top: 50%;
                transform: translateY(-50%);
            `
    case 'top-left':
      return `
                left: -${BALLON_WIDTH + 25}px;
                top: -50%;
                transform: translateY(-50%);
            `
  }
}

const renderArrow = (position) => {
  switch (position) {
    case 'top':
      return `
                bottom: -20px;
                left: calc(50% - 13px);
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-top: 20px solid ${BALLON_COLOR};
            `
    case 'right':
      return `
                left: -20px;
                top: calc(50% - 13px);
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-right: 20px solid ${BALLON_COLOR};
            `
    case 'bottom':
      return `
                top: -20px;
                left: calc(50% - 13px);
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-bottom: 20px solid ${BALLON_COLOR};
            `
    case 'bottom-left':
      return `
                right: -20px;
                bottom: calc(75% - 13px);
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 20px solid ${BALLON_COLOR};
            `
    case 'left':
      return `
                right: -20px;
                top: calc(50% - 13px);
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 20px solid ${BALLON_COLOR};
            `
    case 'top-left':
      return `
                right: -20px;
                top: calc(75% - 13px);
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 20px solid ${BALLON_COLOR};
            `
  }
}

const fadeIn = keyframes`
    from { opacity: 0 }
    to { opacity: 1 }
`

export const TourContainer = styled.div`
  position: relative;
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
`

export const Ballon = styled.div`
  width: ${BALLON_WIDTH}px;
  min-height: ${BALLON_HEIGHT}px;
  background: ${BALLON_COLOR};
  position: absolute;
  border-radius: 4px;
  z-index: 15;
  box-shadow: 0px 5px 15px -5px black;
  animation: ${fadeIn} 0.5s;

  @media (max-width: 899px) {
    display: none;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    ${(props) => setPosition(props.positionMd || props.position)}

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      ${(props) => renderArrow(props.positionMd || props.position)}
    }
  }

  @media (min-width: 1200px) {
    ${(props) => setPosition(props.position)}

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      ${(props) => renderArrow(props.position)}
    }
  }
`

export const BallonContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 20px;
  padding-top: 42px;
  padding-bottom: ${(props) => (props.hideButtons ? '42px' : '60px')};
`

export const StepIndicator = styled.span`
  position: absolute;
  top: 20px;
  left: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
`

export const StepText = styled.span`
  color: white;
  font-size: 14px;
  width: 100%;
  height: 100%;
  letter-spacing: 0.8px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  height: 15px;
  width: 15px;

  &:focus,
  &:hover {
    color: white;
    outline: none;
  }

  & > svg {
    margin: 0px !important;
  }
`

export const NextButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 28px;
  width: 88px;
  border-radius: 4px;
  font-size: 12px;
  letter-spacing: 1px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
    color: white;
    outline: none;
  }
`

export const FinishButton = styled(NextButton)`
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(245, 245, 245);
  color: ${BALLON_COLOR};

  &:focus,
  &:hover {
    background-color: white;
    color: ${BALLON_COLOR};
  }
`
