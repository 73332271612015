import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faQuestion } from '@fortawesome/pro-solid-svg-icons'

import {
  LoginHeader,
  LoginContainer,
  LinkSmall,
  LoginBox,
  LoginForm,
  LoginTitle,
  TextContainer,
  HelpButton,
} from './styles'
import ConfirmButton from '../../components/buttons/confirmButton'
import { Navigate } from 'react-router-dom'
import Alert from '../../components/alerts/AlertBox'
import { useUserInfo } from '../../contexts/user.context'
import { useBlockerModal } from '../../contexts/blockerModal.context'
import BlockerModal from '../../components/BlockerModal'
import { openInNewTab } from '../../utils/OpenURL'

export default function Login() {
  const { userInfo, setUserInfo } = useUserInfo()
  const [login, setLogin] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({ email: false, password: false })
  const { blockerModal, setBlockerModal } = useBlockerModal()
  const [redirect, setRedirect] = useState(null)
  const [buttonLoading, setButtonLoading] = useState({
    use: false,
    text: 'Entrar',
  })

  const catpchaKey = '6LeazjcaAAAAADYXnE6kWFUIJt9n1wy7DxKpGTbK'

  useEffect(() => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('token')
    localStorage.removeItem('studentId')
    setUserInfo(null)
  }, [])

  function validateLogin() {
    let valid = true

    if (!login.email) {
      setErrors((errors) => ({ ...errors, email: true }))
      valid = false
    }

    if (!login.password) {
      setErrors((errors) => ({ ...errors, password: true }))
      valid = false
    }

    return valid
  }

  function submitLogin() {
    if (!validateLogin()) return

    setButtonLoading({ use: true, text: 'Entrando...' })

    axios
      .request({
        url: `/login`,
        method: 'POST',
        data: login,
      })
      .then((response) => {
        let info = response.data.payload
        info['currentLevel'] = info.currentLevel
        info['currentLevelName'] = info.currentLevelName

        localStorage.setItem('userInfo', JSON.stringify(response.data.payload))
        localStorage.setItem('token', info.token)

        if (info.newPlatform) {
          window.location =
            process.env.REACT_APP_CONQUER_ENGLISH_HOST + '/token/' + window.btoa(info.token)
          return
        }

        axios.defaults.headers.common['Authorization'] = response.data.payload.token

        setUserInfo(info)
        setRedirect(true)
      })
      .catch((e) => {
        setButtonLoading({ use: false, text: 'Entrar' })
        setRedirect(false)
      })
  }

  function handleInfo(e) {
    setErrors({ ...errors, [e.target.name]: false })
    setLogin({ ...login, [e.target.name]: e.target.value })
  }

  function passwordKeyDown(e) {
    if (e.keyCode == 13) {
      submitLogin()
    }
  }

  return (
    <>
      <LoginHeader>
        <a href='/'>
          <img src='/img/logo.png' alt='Conquer' height='42' />
        </a>
        <button onClick={() => openInNewTab('https://conquerplus.com.br/login')}>
          Ir para Conquer Plus
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </LoginHeader>
      <LoginContainer>
        <LoginBox>
          <LoginTitle hideMobile>Entrar na conta</LoginTitle>
          <LoginTitle hideDesktop>Entrar no Conquer English</LoginTitle>

          <LoginForm error={errors.email}>
            <label>E-mail *</label>
            <input
              name='email'
              type='text'
              placeholder='nome@dominio.com.br'
              onChange={(e) => handleInfo(e)}
            />
          </LoginForm>
          <LoginForm error={errors.password}>
            <label>Senha *</label>
            <input
              name='password'
              type='password'
              placeholder='*********'
              onChange={(e) => handleInfo(e)}
              onKeyDown={passwordKeyDown}
            />
          </LoginForm>

          <LinkSmall href={'/esqueceu-sua-senha'}>Precisa criar ou Redefinir sua senha?</LinkSmall>

          <ConfirmButton
            execute={() => submitLogin()}
            text={buttonLoading.text}
            loading={buttonLoading.use}
          />
          {redirect &&
            userInfo &&
            (userInfo.currentClass ? <Navigate push to='/home' /> : <Navigate push to='/admin' />)}

          <Alert text={'E-mail ou senha incorretos'} type={'danger'} show={redirect === false} />
          <Alert
            text={'Preencha seu e-mail e senha'}
            type={'danger'}
            show={errors.email || errors.password}
          />
        </LoginBox>
        <TextContainer hideMobile>
          Welcome to Conquer <span className='yellow'>English</span>
        </TextContainer>
        <HelpButton onClick={() => openInNewTab('https://tamojunto.escolaconquer.com.br/hc/pt-br')}>
          <span>Precisa de ajuda?</span>
          <div className='icon'>
            <FontAwesomeIcon icon={faQuestion} />
          </div>
        </HelpButton>
      </LoginContainer>
      <BlockerModal Active={blockerModal} />
    </>
  )
}
