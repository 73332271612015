import styled from 'styled-components'

export const FilterContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;

  & > div {
    display: flex;
    align-items: center;
  }
`

export const FilterButton = styled.a`
  color: #ffff00;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const FilterBox = styled.div`
  position: absolute;
  bottom: -10px;
  transform: translateY(100%);
  background-color: #8b8b8b;
  padding: 20px 0px;
  min-width: 240px;
  z-index: 15;
`

export const FilterList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0px;
  margin: 0px;

  & + & {
    margin-top: 20px;
  }
`

export const FilterItem = styled.li`
  width: 100%;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  letter-spacing: 0.3px;

  &.filter-title {
    color: #ffff00;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
  }

  &.filter-option {
    color: white;
    font-size: 15px;
    cursor: pointer;
    background-color: ${(props) => (props.active ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};

    &:hover {
      background-color: ${(props) =>
        props.active ? 'rgba(255, 255, 255, 0.25)' : 'rgba(255, 255, 255, 0.15)'};
    }

    & > svg {
      display: ${(props) => (props.active ? 'block' : 'none')};
    }
  }
`

export const FilterChipsContainer = styled.div`
  display: flex;
  margin-left: 15px;
`

export const FilterChip = styled.div`
  height: 30px;
  width: max-content;
  border-radius: 6px;
  border: 1px solid white;
  color: white;
  font-size: 15px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px 0px 10px;

  & + & {
    margin-left: 5px;
  }
`

export const FilterChipButton = styled.button`
  background-color: transparent;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 0px;
  font-size: 15px;
  margin-left: 6px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`
