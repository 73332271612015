import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import { InputBox } from '../../../../styles'

import { ButtonsContainer, SubmitButton, BackButton, Error, Title } from './styles'
import AnswersBox from '../AnswersBox'

export default function Form(props) {
  const [payload, setPayload] = useState({})
  const [title, setTitle] = useState('')
  const [questionError, setQuestionError] = useState({ status: false, message: '' })

  useEffect(() => {
    setTitle(getTitleByQuestionType(props.payload.type))
    setPayload({
      ...props.payload,
      prompt: props.payload.prompt ? props.payload.prompt : getDefaultPrompt(props.payload.type),
    })
  }, [props.payload])

  function getDefaultPrompt(type) {
    switch (type) {
      case 'MULTIPLE_CHOICE_ACTIVITY':
        return 'Selecione a opção que contém a equivalência em inglês da frase abaixo:'
      case 'RECORDING_ACTIVITY':
        return 'Escute a gravação e fale a frase:'
      case 'AUDIO_ACTIVITY':
        return 'Selecione a opção que corresponde ao áudio:'
      case 'TEXT_ACTIVITY':
        return 'Escreva a seguinte frase em inglês:'
      default:
        return ''
    }
  }

  function getTitleByQuestionType(type) {
    const action = props.editing ? 'Editar' : 'Adicionar'
    switch (type) {
      case 'MULTIPLE_CHOICE_ACTIVITY':
        return action + ' - ' + 'Múltipla escolha'
      case 'RECORDING_ACTIVITY':
        return action + ' - ' + 'Pronúncia'
      case 'AUDIO_ACTIVITY':
        return action + ' - ' + 'Compreensão'
      case 'TEXT_ACTIVITY':
        return action + ' - ' + 'Escrita'
    }

    return ''
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function handleUpdateAnswers(answers) {
    setPayload({
      ...payload,
      answers: [...answers],
    })
  }

  function validateQuestion() {
    if (!payload.text) {
      setQuestionError({ status: true, message: 'Você deve inserir o texto da questão.' })
      return false
    }

    if (payload.text && payload.text.length <= 5) {
      setQuestionError({
        status: true,
        message: 'Você deve inserir um texto maior para a questão.',
      })
      return false
    }

    switch (payload.type) {
      case 'RECORDING_ACTIVITY':
      case 'TEXT_ACTIVITY':
        if (!payload.answers.length) {
          setQuestionError({
            status: true,
            message: 'Você deve inserir ao menos uma resposta para a questão.',
          })
          return false
        }
        break
      case 'MULTIPLE_CHOICE_ACTIVITY':
      case 'AUDIO_ACTIVITY':
        if (payload.answers.length < 4) {
          setQuestionError({
            status: true,
            message: 'Você deve inserir ao menos 4 respostas para a questão.',
          })
          return false
        }

        if (payload.answers.filter((answer) => answer.correct).length > 1) {
          setQuestionError({
            status: true,
            message: 'Você deve inserir apenas uma resposta correta para esta questão.',
          })
          return false
        }

        if (!payload.answers.some((answer) => answer.correct)) {
          setQuestionError({
            status: true,
            message: 'Você deve inserir ao menos uma resposta correta para esta questão.',
          })
          return false
        }
    }

    return true
  }

  function handleSubmit() {
    if (!validateQuestion()) return

    if (payload.id) {
      putPayload()
    } else {
      postPayload()
    }
  }

  function postPayload() {
    axios
      .post('/admin/curation/question', payload)
      .then(() => {
        props.showAlert({
          message: <span>Exercício cadastrado com sucesso!</span>,
          success: true,
        })
        props.updateData(true)
        handleClose()
      })
      .catch((err) => {
        props.showAlert({
          message: <span>Erro ao cadastrar exercício: {err?.response?.data?.msg || ''}</span>,
          success: false,
        })
        handleClose()
      })
  }

  function putPayload() {
    axios
      .put('/admin/curation/question', payload)
      .then(() => {
        props.showAlert({
          message: <span>Exercício editado com sucesso!</span>,
          success: true,
        })
        props.updateData(true)
        handleClose()
      })
      .catch((err) => {
        props.showAlert({
          message: <span>Erro ao editar exercício {err?.response?.data?.msg || ''}</span>,
          success: false,
        })
        handleClose()
      })
  }

  function handleClose() {
    props.close()
  }

  return (
    <>
      <Title>{title}</Title>
      <InputBox>
        <label>Chamada da questão</label>
        <input
          name='prompt'
          type='text'
          placeholder='Chamada da questão '
          value={payload.prompt}
          onChange={handleChange}
        />
      </InputBox>
      <InputBox>
        <label>Texto</label>
        <input
          name='text'
          type='text'
          placeholder='Texto da Questão'
          value={payload.text}
          onChange={handleChange}
        />
      </InputBox>
      <InputBox hide={payload.type !== 'AUDIO_ACTIVITY'}>
        <label>Áudio</label>
        <input
          name='text'
          type='text'
          placeholder='Texto do Áudio da Questão'
          value={payload.text}
          onChange={handleChange}
        />
      </InputBox>
      <AnswersBox
        type={payload.type}
        answers={payload.answers || []}
        updateAnswers={handleUpdateAnswers}
        editing={props.editing}
      />

      {questionError.status && (
        <Error>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {questionError.message}
        </Error>
      )}

      <ButtonsContainer>
        <BackButton onClick={() => handleClose()}>
          <FontAwesomeIcon icon={faChevronLeft} />
          Voltar
        </BackButton>
        <SubmitButton onClick={() => handleSubmit()}>
          Confirmar
          <FontAwesomeIcon icon={faCheck} />
        </SubmitButton>
      </ButtonsContainer>
    </>
  )
}
