import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { ButtonContainer, ConfirmButton, Error, InputBox } from '../../../../styles'
import { WarningText } from './styles'

export default function NewEnrollmentForm(props) {
  const [payload, setPayload] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [coreStudent, setCoreStudent] = useState(null)
  const [coreEnrollments, setCoreEnrollments] = useState(null)

  useEffect(() => {
    setPayload({ ...props.payload })
  }, [props.payload])

  useEffect(() => {
    if (coreStudent !== null && coreStudent?.id) {
      setPayload({
        ...payload,
        cpf: coreStudent?.cpf,
        name: coreStudent?.name,
        phone: coreStudent?.whatsApp,
        studentIdCore: coreStudent?.id,
      })
      getCoreEnrollments(coreStudent?.id)
    }
  }, [coreStudent])

  async function validateAcquisitionCode(code) {
    await axios
      .post('/admin/enrollment/validate-acquisition-code', { code: code })
      .catch((error) => {
        setSubmitDisabled(true)

        let respData = error?.response?.data
        let status = error?.response?.status

        function isServerError(status) {
          return status >= 500 && status <= 599
        }

        function isBadRequest(status) {
          return status >= 400 && status <= 499
        }

        if (isServerError(status)) {
          setErrorMessage(
            'Código de venda não encontrado na CIA. Verifique se o código digitado está correto.',
          )
        } else if (isBadRequest(status)) {
          setErrorMessage(
            'Verificar pagamento na Cia. Status do Pagamento: ' + respData?.acquisitionStatus,
          )
        }
      })
  }

  async function getCoreStudent(email) {
    await axios.get(`/admin/student/${email}`).then((resp) => {
      console.log('core student', resp.data)
      setCoreStudent(resp?.data)
    })
  }

  async function getCoreEnrollments(studentId) {
    await axios.get(`/admin/enrollments/${studentId}`).then((resp) => {
      console.log('core enrollments', resp.data)
      setCoreEnrollments(resp?.data)
    })
  }

  function onAcquisitionCodeFocusOut(e) {
    validateAcquisitionCode(e.target.value)
  }

  function onAcquisitionCodeFocusIn() {
    setSubmitDisabled(false)
    setErrorMessage(null)
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function validatePayload() {
    if (!payload.name) {
      setErrorMessage('Por favor, insira o nome do aluno.')
      return false
    }

    if (!payload.cpf) {
      setErrorMessage('Por favor, insira o CPF do aluno.')
      return false
    }

    if (!payload.email) {
      setErrorMessage('Por favor, insira o e-mail do aluno.')
      return false
    }

    if (!payload.codeAcquisition) {
      setErrorMessage('Por favor, insira o código da venda do aluno.')
      return false
    }

    if (!payload.level) {
      setErrorMessage('Por favor, selecione o nível do aluno.')
      return false
    }

    if (!payload.product) {
      setErrorMessage('Por favor, selecione o produto que o aluno deve acessar.')
      return false
    }

    setErrorMessage('')
    return true
  }

  function onEmailFocusOut(e) {
    getCoreStudent(e.target.value)
  }

  function handleSubmit() {
    if (!validatePayload()) return

    const newEnglishStudent = {
      email: payload.email,
      name: payload.name,
      cpf: payload.cpf,
      phone: payload.phone,
      codeAcquisition: payload.codeAcquisition,
      level: payload.level,
      product: payload.product,
    }

    axios
      .post('/admin/student', newEnglishStudent)
      .then((res) => {
        props.showAlert({
          message: <span>Aluno matriculado com sucesso!</span>,
          success: true,
        })
        props.updateData(true)
        props.close()
      })
      .catch((err) => {
        props.showAlert({
          message: <span>Erro ao tentar processar a sua requisição!</span>,
          success: false,
        })
      })
  }

  return (
    <>
      {payload && (
        <>
          <InputBox>
            <label>E-mail</label>
            <input
              name='email'
              type='text'
              placeholder='E-mail'
              value={payload.email}
              onChange={handleChange}
              onBlur={onEmailFocusOut}
            />
          </InputBox>

          <InputBox>
            <label>Nome do Aluno</label>
            <input
              name='name'
              type='text'
              placeholder='Nome'
              value={payload.name}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>CPF</label>
            <input
              name='cpf'
              type='text'
              placeholder='CPF'
              value={payload.cpf}
              onChange={handleChange}
            />
          </InputBox>

          <InputBox>
            <label>Telefone</label>
            <input
              name='phone'
              type='text'
              placeholder='Telefone'
              value={payload?.phone}
              onChange={handleChange}
            />
          </InputBox>

          <InputBox>
            <label>Código da venda da CIA*</label>
            <input
              name='codeAcquisition'
              type='text'
              placeholder='Código da venda, exemplo: 16062021223755372PLMFRRR'
              value={payload.codeAcquisition}
              onChange={handleChange}
              onBlur={onAcquisitionCodeFocusOut}
              onFocus={onAcquisitionCodeFocusIn}
            />
          </InputBox>

          <InputBox>
            <label>Nível do Aluno</label>
            <select name='level' value={payload.level} onChange={handleChange}>
              <option value='' disabled>
                Selecione o Nível
              </option>
              <option value='BEGINNER'>BEGINNER</option>
              <option value='LOWER_INTERMEDIATE'>LOWER INTERMEDIATE</option>
              <option value='INTERMEDIATE'>INTERMEDIATE</option>
              <option value='UPPER INTERMEDIATE'>INTERMEDIATE</option>
              <option value='ADVANCED'>ADVANCED</option>
              <option value='UPPER ADVANCED'>UPPER ADVANCED</option>
            </select>
          </InputBox>

          <InputBox>
            <label>Produto que o aluno deve ter acesso</label>
            <select name='product' value={payload.product} onChange={handleChange}>
              <option value='' disabled>
                Selecione o Produto
              </option>
              <option value='ENGLISH UP'>English UP</option>
              <option value='UP_TRIAL'>English UP TRIAL</option>
              <option value='ENGLISH SUPER'>English SUPER</option>
              <option value='ENGLISH SUPREME'>English SUPREME</option>
              <option value='ENGLISH TOEFL'>English TOEFL</option>
            </select>
          </InputBox>

          <WarningText>
            Ao confirmar a matrícula o sistema vai tentar enviar o e-mail de boas vindas para o(a)
            aluno(a). Neste e-mail o aluno consegue acessar um link para criar sua senha e acessar a
            plataforma.
          </WarningText>

          {errorMessage && (
            <Error>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </Error>
          )}
          <ButtonContainer>
            <div></div>
            <ConfirmButton onClick={() => handleSubmit()} disabled={submitDisabled}>
              <span>Confirmar</span>
              <FontAwesomeIcon icon={faCheck} />
            </ConfirmButton>
          </ButtonContainer>
        </>
      )}
    </>
  )
}
