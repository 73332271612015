import React, { useEffect, useState } from 'react'
import { RankingContainer } from './styles'
import { Input, NewButton } from '../../../../styles'
import axios from 'axios'
import Modal from '../../../../Template/Modal'
import { ModalContainer } from '../Awards/styles'

export default function Ranking() {
  const [showModal, setShowModal] = useState(false)
  const [ranking, setRanking] = useState([])
  const [modalTitle, setModalTitle] = useState()
  const [formComponent, setFormComponent] = useState()
  const [endAt, setEndAt] = useState()

  function convertDateToBRFormat(date) {
    const dateArray = date.split('-')
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
  }

  function updateRanking(rankingId, newDate) {
    axios
      .put(`/admin/gamification/ranking/${rankingId}`, {
        endAt: newDate,
      })
      .then((resp) => {
        if (resp.status === 200) {
          getRanking()
          notifyUpdate()
        }
      })
  }

  function notifyUpdate() {
    setModalTitle('Editado com sucessoo')
    setFormComponent(
      <ModalContainer>
        <h3>Edição finalizada com sucesso!</h3>

        <div className='form-buttons'>
          <NewButton onClick={() => closeModal()}>OK</NewButton>
        </div>
      </ModalContainer>,
    )
    setShowModal(true)
  }

  function handleModalUpdateDate(rankingId, newDate) {
    setModalTitle('Editar data de apuração?')
    setFormComponent(
      <ModalContainer>
        <h3>
          Editar data final do ranking de {convertDateToBRFormat(ranking?.end_at)} para{' '}
          {convertDateToBRFormat(newDate)}
        </h3>

        <div className='form-buttons'>
          <NewButton onClick={() => closeModal()}>Não</NewButton>
          <NewButton onClick={() => updateRanking(rankingId, newDate)}>Sim</NewButton>
        </div>
      </ModalContainer>,
    )
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  async function getRanking() {
    await axios.get('/admin/gamification/active-ranking').then((resp) => {
      setRanking([])
      if (resp.status === 200) {
        setRanking(resp.data)
        setEndAt(resp.data.end_at)
      }
    })
  }

  useEffect(() => {
    getRanking()
  }, [])

  return (
    <RankingContainer>
      {showModal && (
        <Modal title={modalTitle} close={closeModal} subtitle={``}>
          {formComponent}
        </Modal>
      )}

      <div className='gamification-title'>
        <h2>Alterar data final do Ranking atual</h2>
      </div>
      <div className='date-ranking'>
        <div className='dates'>
          <label className='disabled'>Data inicial</label>
          <Input
            className='disabled'
            type='date'
            name='startAt'
            value={ranking?.start_at}
            disabled
          />
        </div>
        <div className='dates'>
          <label>Data Final</label>
          <Input
            type='date'
            name='endAt'
            value={endAt}
            onChange={(e) => setEndAt(e.target.value)}
          />
        </div>
        <div>
          <NewButton onClick={() => handleModalUpdateDate(ranking.ranking_id, endAt)}>
            Alterar
          </NewButton>
        </div>
      </div>
    </RankingContainer>
  )
}
