import styled, { keyframes } from 'styled-components'

export const BoxReCAPTCHA = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;

  @media (max-width: 400px) {
    justify-content: center;
  }
`

export const LinkSmall = styled.a`
  font-size: 14px;
  text-align: right;
  margin-top: 5px;
  display: block;
  color: #1b1b1b;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 399px) {
    font-size: 12px;
  }
`

const shake = keyframes`
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(4px);
    }
    100% {
        transform: translateX(0px);
    }
`

export const LoginHeader = styled.nav`
  background-color: #141414;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 50px 14px 50px;
  height: 84px;
  z-index: 15;

  & > button {
    border: 1px solid rgba(255, 255, 0, 0.5);
    border-radius: 0px;
    height: 48px;
    width: 210px;
    background: transparent;
    color: white;
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.45px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.5s;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: rgba(255, 255, 0, 0.9);

      & > svg {
        animation: ${shake} 0.5s;
        animation-iteration-count: infinite;
      }
    }

    &:active {
      background-color: rgba(200, 200, 200, 0.1);
    }

    & > svg {
      color: #ffff00;
      font-size: 18px;
    }

    @media (max-width: 599px) {
      display: none;
    }
  }
`

export const LoginContainer = styled.div`
  background-image: url('/img/background-login.png');
  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(25, 25, 25, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 84px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;

  @media (max-width: 899px) {
    flex-direction: column;
  }

  @media (max-width: 599px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const LoginBox = styled.div`
  background-color: white;

  @media (max-width: 399px) {
    padding: 50px 30px 40px 30px;
    width: 100%;
  }

  @media (min-width: 400px) {
    padding: 70px 36px 60px 36px;
    width: 100%;
  }

  @media (min-width: 600px) {
    padding: 70px 84px 60px 84px;
    width: 570px;
  }

  @media (min-width: 900px) {
    padding: 80px 50px 70px 50px;
    width: 450px;
  }

  @media (min-width: 1200px) {
    padding: ${(props) => (props.smallPaddingY ? '80px 84px 70px 84px' : '110px 84px 100px 84px')};
    width: 570px;
  }
`

export const LoginTitle = styled.span`
  display: ${(props) => (props.hideDesktop ? 'none' : 'block')};
  font-size: 5rem;
  font-weight: 400;
  margin-bottom: 30px;

  @media (max-width: 899px) {
    display: ${(props) => (props.hideMobile ? 'none' : 'block')};
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    font-size: 4.4rem;
  }

  @media (max-width: 599px) {
    font-size: 4.1rem;
  }

  @media (max-width: 399px) {
    font-size: 3rem;
  }
`

export const LoginForm = styled.div`
  width: 100%;

  & > label {
    color: #1b1b1b;
    font-size: 14px;
    font-weight: 700;
  }

  & > input {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: ${(props) => (props.error ? '2px solid red' : '2px solid #1B1B1B')};
    font-size: 18px;
    line-height: 30px;
    outline: none;
    padding: 0px;
    border-radius: 0;
    font-weight: bold;
    resize: none;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out;
    font-family: 'DM Sans';

    &:focus {
      border-bottom: 2px solid #ffe607;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }

  & + & {
    margin-top: 20px;
  }
`

export const TextContainer = styled.div`
  max-width: 400px;
  font-size: 4.8rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: white;

  & > span.yellow {
    color: #ffff00;
  }

  @media (max-width: 899px) {
    display: ${(props) => (props.hideMobile ? 'none' : 'block')};
  }
`

export const HelpButton = styled.button`
  position: absolute;
  top: 130px;
  right: 50px;
  border: none;
  border-radius: 0px;
  height: 54px;
  width: 270px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgb(245, 245, 245);

    & > div.icon {
      background-color: black;

      &::before {
        background-color: #ffff00;
      }

      & > svg {
        color: black;
      }
    }
  }

  & > span {
    flex: 0 0 205px;
    font-family: 'DM Sans';
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.4px;
  }

  & > div.icon {
    flex: 0 0 65px;
    height: 100%;
    background-color: #ffff00;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.35s;

    &::before {
      content: '';
      background-color: black;
      height: 24px;
      width: 24px;
      position: absolute;
      border-radius: 50%;
      transition: 0.35s;
    }

    & > svg {
      font-size: 12px;
      color: #ffff00;
      z-index: 10;
      transition: 0.35s;
    }
  }

  @media (max-width: 899px) {
    position: initial;
    align-self: flex-end;
    margin-right: 30px;
  }

  @media (max-width: 599px) {
    margin-right: 0px;
  }
`

export const LoginText = styled.p`
  color: #1b1b1b;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin-bottom: ${(props) => (props.marginBottomDisabled ? '0px' : '20px')};
  margin-top: 0px;
`
