import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import { ButtonContainer, Error, InputBox } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'

export default function NewLessonForm(props) {
  const [payload, setPayload] = useState(null)
  const [modules, setModules] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [moduleId, setModuleId] = useState()

  useEffect(() => {
    setPayload({ ...props.payload })
  }, [props.payload])

  useEffect(() => {
    let isMounted = true
    if (props.lessonId) {
      fetchLessonById(props.lessonId)
    }

    return () => (isMounted = false)
  }, [props.lessonId])

  useEffect(() => {
    if (props.module && props.module.id) {
      setModuleId(props.module.id)
      setModules([props.module])
      setPayload({
        ...payload,
        ['moduleId']: props.module.id,
      })
    }
  }, [props.module])

  async function fetchLessonById(lessonId) {
    await axios.get('/admin/lesson/' + lessonId).then((res) => {
      setPayload(res.data.payload)
    })
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function validatePayload() {
    if (!payload.name) {
      setErrorMessage('Por favor, insira o nome do módulo.')
      return false
    }

    if (!payload.description) {
      setErrorMessage('Por favor, insira a descrição do módulo.')
      return false
    }

    if (!payload.moduleId) {
      setErrorMessage('Por favor, selecione o módulo ao qual a aula pertence.')
      return false
    }

    setErrorMessage('')
    return true
  }

  function handleSubmitResponse(response) {
    if (!validatePayload()) return

    props.showAlert({
      message: <span>Operação realizada com sucesso!</span>,
      success: true,
    })
    props.updateData(true)
  }

  function handleSubmitError(data, status, generalMessage, request, config) {
    props.showAlert({
      message: (
        <>
          <span>Erro no processamento!</span>
          <br />
          {data && data.msg ? data.msg : generalMessage ? generalMessage : null}
        </>
      ),
      success: false,
    })
  }

  return (
    <>
      {payload && (
        <>
          <InputBox>
            <label>Nome da Aula</label>
            <input
              name='name'
              type='text'
              placeholder='Nome'
              value={payload.name}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Descrição</label>
            <input
              name='description'
              type='text'
              placeholder='Ex: Lesson 4 Hollywood'
              value={payload.description}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Módulo</label>
            <select
              name='moduleId'
              value={moduleId}
              onChange={handleChange}
              disabled={moduleId != null}
            >
              <option disabled value=''>
                Selecione o Módulo
              </option>
              {modules.map((module) => (
                <option key={module.id} value={module.id}>
                  {module.name}
                </option>
              ))}
            </select>
          </InputBox>
          {errorMessage && (
            <Error>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </Error>
          )}
          <ButtonContainer>
            <div></div>
            <SubmitButton
              url='/admin/lesson'
              onResponse={handleSubmitResponse}
              onError={handleSubmitError}
              payload={payload}
              isUpdate={props.lessonId != null}
            ></SubmitButton>
          </ButtonContainer>
        </>
      )}
    </>
  )
}
