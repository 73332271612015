import React, { useEffect, useState } from 'react'
import { CloseButton, Modal, ModalContent, ModalHeader, ModalTitle } from './styles'
import { ReactComponent as ConquerIcon } from '../../assets/svg/icon-conquer.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Opacity } from '../../assets/styles/global'

export default function ModalDefault(props) {
  const [hideModal, setHideModal] = useState(true)
  const [modalContent, setModalContent] = useState()

  useEffect(() => {
    if (props.modalContent) {
      setModalContent(props.modalContent)
    }
  }, [props.modalContent])

  useEffect(() => {
    if (props.children) {
      setModalContent(props.children)
    }
  }, [props.children])

  useEffect(() => {
    if (props.hideModal != null) {
      setHideModal(props.hideModal)
    }
  }, [props.hideModal])

  useEffect(() => {
    if (hideModal != null && hideModal) {
      props.closeModal(hideModal)
    }
  }, [hideModal])

  function closeModal() {
    setHideModal(true)
  }

  return (
    <>
      <Opacity hide={hideModal}>
        <Modal>
          <ModalHeader>
            <ConquerIcon width={36} />
            {props.modalHeader}
            <ModalTitle>{props.modalTitle}</ModalTitle>
            <CloseButton onClick={() => closeModal()}>
              <FontAwesomeIcon icon={faTimes} size={'3x'} />
            </CloseButton>
          </ModalHeader>
          <ModalContent display={props.display}>{modalContent}</ModalContent>
        </Modal>
      </Opacity>
    </>
  )
}
