import React from 'react'
import LoadingProvider from './contexts/loading.context'
import Routes from './routes'
import LoadingComponent from './components/loading'
import axios from 'axios'
import UserProvider from './contexts/user.context'
import BlockerModalProvider from './contexts/blockerModal.context'
import TourProvider from './contexts/tour.context'

export default function App() {
  axios.defaults.baseURL = process.env.REACT_APP_HOST
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')

  return (
    <>
      <LoadingProvider>
        <BlockerModalProvider>
          <UserProvider>
            <TourProvider>
              <Routes />
            </TourProvider>
          </UserProvider>
          <LoadingComponent />
        </BlockerModalProvider>
      </LoadingProvider>
    </>
  )
}
