import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

import {
  FilterContainer,
  FilterButton,
  FilterBox,
  FilterList,
  FilterChip,
  FilterChipButton,
  FilterChipsContainer,
  FilterItem,
} from './styles'

export default function Filter({ data, filters, result, mentoringFilter }) {
  const [showOptionsBox, setShowOptionsBox] = useState(false)
  const [filtersLists, setFiltersLists] = useState([])
  const [activeFilters, setActiveFilters] = useState([])
  const [activeFiltersChips, setActiveFiltersChips] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const box = useRef()

  useEffect(() => {
    if (activeFilters.length > 0) {
      result(filteredData)
    } else {
      result(null)
    }
  }, [filteredData])

  useEffect(() => {
    renderFiltersLists()
  }, [filters, activeFilters])

  useEffect(() => {
    renderActiveFiltersChips()
    filterData()
  }, [activeFilters])

  useEffect(() => {
    if (showOptionsBox) {
      document.addEventListener('mousedown', handleClickOutsideBox)
    } else {
      document.removeEventListener('mousedown', handleClickOutsideBox)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBox)
    }
  }, [showOptionsBox])

  function handleClickOutsideBox(e) {
    if (box.current.contains(e.target)) return

    setShowOptionsBox(false)
  }

  function renderFiltersLists() {
    setFiltersLists(
      filters.map((filter, index) => (
        <FilterList key={index}>
          <FilterItem className='filter-title'>{filter.title}</FilterItem>
          {filter.options.map((option, index) => (
            <FilterItem
              key={index}
              className='filter-option'
              onClick={() => handleFilterClick(option, filter.field)}
              active={isFilterActive(option)}
            >
              {option.text}
              <FontAwesomeIcon icon={faCheck} />
            </FilterItem>
          ))}
        </FilterList>
      )),
    )
  }

  function handleFilterClick(filter, field) {
    if (isFilterActive(filter)) removeFilter(filter)
    else addFilter(filter, field)

    setShowOptionsBox(false)
  }

  function addFilter(filter, field) {
    let actualFilter = activeFilters.find((activeFilter) => activeFilter.field === field)

    if (actualFilter) removeFilter(actualFilter)

    setActiveFilters((activeFilters) => [...activeFilters, { ...filter, field }])
  }

  function removeFilter(filter) {
    setActiveFilters((activeFilters) =>
      activeFilters.filter((activeFilter) => activeFilter.value !== filter.value),
    )
  }

  function renderActiveFiltersChips() {
    const chips = activeFilters.map((activeFilter) => generateChip(activeFilter))
    setActiveFiltersChips(chips)
  }

  function generateChip(filter) {
    return (
      <FilterChip key={filter.value}>
        {filter.text}
        <FilterChipButton onClick={() => removeFilter(filter)}>
          <FontAwesomeIcon icon={faTimes} />
        </FilterChipButton>
      </FilterChip>
    )
  }

  function isFilterActive(filter) {
    return activeFilters.some((activeFilter) => activeFilter.value === filter.value)
  }

  function filterData() {
    let filteredData = [...data]

    if (mentoringFilter) {
      setFilteredData(activeFilters)
    } else {
      activeFilters.forEach(
        (filter) => (filteredData = filteredData.filter((d) => d[filter.field] === filter.value)),
      )
      setFilteredData(filteredData)
    }
  }

  return (
    <FilterContainer>
      <div ref={box}>
        <FilterButton onClick={() => setShowOptionsBox(!showOptionsBox)}>Filtrar</FilterButton>
        {showOptionsBox && <FilterBox>{filtersLists}</FilterBox>}
      </div>
      <FilterChipsContainer>{activeFiltersChips}</FilterChipsContainer>
    </FilterContainer>
  )
}
