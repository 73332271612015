import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Container, EditButton, Image } from './styles'

import { ActionButton, HeaderSpacer, NewButton, TableHeader } from '../../styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faFilePdf, faTrash, faEye } from '@fortawesome/pro-solid-svg-icons'

import Modal from '../../Template/Modal'
import Alert from '../../Template/Alert'
import Filter from '../../Template/Filter'
import SearchBox from '../../Template/SearchBox'
import ConquerTable from '../../Template/ConquerTable'

import NewModuleForm from './components/NewModuleForm'
import NewLessonForm from './components/NewLessonForm'
import NewContentForm from './components/NewContentForm'
import ModalDeleteConfirmation from '../../Template/ModalDeleteConfirmation'

export default function AdminModules() {
  const [moduleData, setModuleData] = useState([])
  const [lessonData, setLessonData] = useState([])
  const [contentData, setContentData] = useState([])
  const [filteredData, setFilteredData] = useState(null)
  const [filterResultData, setFilterResultData] = useState(null)
  const [searchResultData, setSearchResultData] = useState(null)
  const [formComponent, setFormComponent] = useState()
  const [contentId, setContentId] = useState('')
  const [showEditModal, setShowEditModal] = useState(false)

  const [selectedModule, setSelectedModule] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(false)
  const [payload, setPayload] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalSubtitle, setModalSubtitle] = useState('')
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [loadingTable, setLoadingTable] = useState(true)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemUrl, setDeleteItemUrl] = useState('')
  const [deleteItemName, setDeleteItemName] = useState('')

  const moduleColumns = React.useMemo(
    () => [
      {
        title: 'Sequência',
        field: 'sequence',
        props: {
          center: true,
          width: '10%',
        },
      },
      { title: 'Nome', field: 'name' },
      {
        title: 'Nível',
        content: (v) => v.studentLevelName.toLowerCase() || '-',
        props: {
          textTransform: 'capitalize',
          width: '20%',
        },
      },
      {
        title: 'Capa',
        content: (v) => (
          <a
            target='_blank'
            rel='noreferrer'
            href={v.coverSource}
            style={{ color: 'white', fontSize: '2rem' }}
          >
            <Image src={v.coverSource} width={'60px'} height={'60px'} />
          </a>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Ver aulas',
        content: (v) => (
          <EditButton onClick={() => handleLessonsOpen(v)}>
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
          </EditButton>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
    ],
    [],
  )

  const lessonColumns = React.useMemo(
    () => [
      { title: 'Aula', field: 'lessonName' },
      {
        title: 'Sequência',
        field: 'sequence',
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Ver conteúdos',
        content: (v) => (
          <>
            <div style={{ marginLeft: '25x' }}>
              <EditButton onClick={() => handleContentOpen(v)}>
                <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
              </EditButton>
            </div>
          </>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
    ],
    [],
  )

  const contentColumns = React.useMemo(
    () => [
      {
        title: 'Formato',
        field: 'contentType',
        props: {
          width: '10%',
        },
      },
      {
        title: 'Nome',
        content: (v) => {
          if (v.contentType === 'Ebook') {
            return (
              <a target='_blank' rel='noreferrer' href={v.source} style={{ color: 'white' }}>
                Abrir <FontAwesomeIcon icon={faFilePdf} />
              </a>
            )
          }
          return <span>{v.name}</span>
        },
      },
      {
        title: 'Sequência',
        field: 'sequence',
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: 'Capa',
        content: (v) => (
          <a
            target='_blank'
            rel='noreferrer'
            href={v.thumb}
            style={{ color: 'white', fontSize: '2rem' }}
          >
            <img src={v.thumb} width={'60px'} height={'60px'} />
          </a>
        ),
        props: {
          center: true,
          width: '10%',
        },
      },
      {
        title: '',
        id: 'actions',
        content: (v) => (
          <ActionButton onClick={() => handleEditContent(v)}>
            <FontAwesomeIcon icon={faPencil} /> Editar
          </ActionButton>
        ),
        width: 50,
      },
      {
        title: '',
        id: 'delete',
        content: (v) => (
          <ActionButton onClick={() => handleDeleteContent(v)}>
            <FontAwesomeIcon icon={faTrash} /> Apagar
          </ActionButton>
        ),
        width: 10,
      },
    ],
    [],
  )

  const filters = React.useMemo(() => [
    {
      title: 'Nível',
      field: 'studentLevelName',
      options: [
        { text: 'Beginner', value: 'BEGINNER' },
        { text: 'Intermediate', value: 'INTERMEDIATE' },
        { text: 'Advanced', value: 'ADVANCED' },
      ],
    },
  ])

  useEffect(() => {
    let isMounted = true

    getModules(isMounted)

    return () => {
      isMounted = false
    }
  }, [])

  async function getModules(isMounted) {
    setLoadingTable(true)

    await axios.get('/admin/modules-infos').then((res) => {
      if (isMounted) {
        setModuleData(
          res.data.payload.sort(function (a, b) {
            if (a.sequence > b.sequence) return 1
            if (a.sequence < b.sequence) return -1
            return 0
          }),
        )
        setLoadingTable(false)
      }
    })
  }

  async function getLessonByModule(moduleId) {
    setLoadingTable(true)
    await axios
      .get(`/admin/module/${moduleId}/lesson`)
      .then((res) => {
        setLessonData(
          res.data.payload.sort(function (a, b) {
            if (a.sequence > b.sequence) return 1
            if (a.sequence < b.sequence) return -1
            return 0
          }),
        )
        setLoadingTable(false)
      })
      .catch(() => {
        setLoadingTable(false)
      })
  }

  async function getContentByLesson(lessonId) {
    setLoadingTable(true)
    await axios
      .get(`/admin/lesson/${lessonId}/content`)
      .then((res) => {
        setContentData(
          res.data.payload.sort(function (a, b) {
            if (a.sequence > b.sequence) return 1
            if (a.sequence < b.sequence) return -1
            return 0
          }),
        )
        setLoadingTable(false)
      })
      .catch(() => {
        setLoadingTable(false)
      })
  }

  function newModuleData() {
    const payload = {
      name: '',
      description: '',
      sequence: '',
      coverSource: '',
      xp: 1000,
      studentLevelId: '',
    }

    setFormComponent(
      <NewModuleForm
        payload={payload}
        showAlert={handleAlert}
        close={handleClose}
        updateData={getModules}
      />,
    )
    setModalTitle('Novo módulo')
    setModalSubtitle('')
    setShowModal(true)
  }

  function newLessonData() {
    const payload = {
      coverSource: '',
      name: '',
      description: '',
      moduleId: '',
    }

    setFormComponent(
      <NewLessonForm
        payload={payload}
        showAlert={handleAlert}
        close={handleClose}
        updateData={() => getLessonByModule(selectedModule.id)}
        lessonId={null}
        module={selectedModule}
      />,
    )
    setModalTitle('Nova aula')
    setModalSubtitle('')
    setShowModal(true)
  }

  function handleNewContent() {
    setFormComponent(
      <NewContentForm
        showAlert={handleAlert}
        close={handleClose}
        updateData={() => getContentByLesson(selectedLesson.id)}
        lessonId={selectedLesson.id}
        moduleId={selectedModule.id}
        contentId={null}
      />,
    )
    setModalTitle('Novo conteúdo')
    setModalSubtitle('')
    setShowModal(true)
  }

  function handleDeleteContent(v) {
    setDeleteItemUrl('admin/content/' + v.id)
    setDeleteItemName(v.name)
    setDeleteConfirmation(true)
  }

  function handleCloseModalConfirmation(v) {
    setDeleteConfirmation(false)
  }

  function handleEditModule(v) {
    const module = v.row.original

    setPayload({
      id: module.id,
      name: module.name,
      description: module.description,
      sequence: module.sequence,
      xp: module.xp,
      studentLevelId: module.studentLevelId,
    })
    setModalTitle('Editar módulo')
    setModalSubtitle(module.name)
    setShowModal(true)
  }

  useEffect(() => {
    if (selectedModule) {
      getLessonByModule(selectedModule.id)
    }
  }, [selectedModule])

  useEffect(() => {
    if (selectedLesson) {
      getContentByLesson(selectedLesson.id)
    }
  }, [selectedLesson])

  function handleClose() {
    setShowModal(false)
    setShowEditModal(false)
  }

  function handleCloseEditModal() {
    setShowEditModal(false)
  }

  function handleEditContent(e) {
    setModalTitle('Editar Conteúdo')
    setModalSubtitle(e.name)
    setContentId(e.id)
    setFormComponent(
      <NewContentForm
        showAlert={handleAlert}
        close={handleClose}
        updateData={() => {
          if (e?.lessonId) {
            getContentByLesson(e.lessonId)
          }
        }}
        lessonId={e.lessonId}
        moduleId={selectedModule.id}
        contentId={e.id}
      />,
    )
    setShowEditModal(true)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
    handleClose()
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handleSearchResult(e) {
    setSearchResultData(e)
  }

  function handleFilterResult(e) {
    setFilterResultData(e)
  }

  function handleContentOpen(e) {
    setSelectedLesson(e)
  }

  function handleLessonsOpen(e) {
    setSelectedModule(e)
  }

  function handleModulosClick() {
    setSelectedModule(null)
    setSelectedLesson(null)
    setLessonData([])
    setContentData([])
    getModules(true)
  }

  function handleModuleClick() {
    setSelectedLesson(null)
    getLessonByModule(selectedModule.id)
  }

  function handleLessonClick() {
    setContentData([])
    getContentByLesson(selectedLesson.id)
  }

  useEffect(() => {
    if (searchResultData && filterResultData) {
      setFilteredData(filterResultData.filter((d) => searchResultData.includes(d)))
    } else {
      setFilteredData(searchResultData || filterResultData)
    }
  }, [searchResultData, filterResultData])

  const module = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span>Módulos</span>
          <div style={{ display: 'none' }}>
            <Filter data={moduleData} filters={filters} result={handleFilterResult} />
            <HeaderSpacer />
            <SearchBox data={moduleData} fields={['name']} result={handleSearchResult} />
            <HeaderSpacer />
          </div>

          <NewButton onClick={() => newModuleData()}>Cadastrar novo módulo</NewButton>
        </TableHeader>
        <ConquerTable
          columns={moduleColumns}
          data={filteredData || moduleData}
          rowsPerPage={18}
          loading={loadingTable}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />
    </>
  )

  const lesson = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span style={{ fontSize: '2rem' }}>
            <span
              onClick={() => handleModulosClick()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Módulos
            </span>{' '}
            {' > '} {selectedModule?.name}
            {' > '}
            <span>Aulas</span>
          </span>
          <div style={{ display: 'none' }}>
            <Filter data={lessonData} filters={filters} result={handleFilterResult} />
            <HeaderSpacer />
            <SearchBox
              data={lessonData}
              fields={['moduleName', 'lessonName']}
              result={handleSearchResult}
            />
            <HeaderSpacer />
          </div>
          <NewButton onClick={() => newLessonData()}>Cadastrar nova aula</NewButton>
        </TableHeader>
        <ConquerTable
          columns={lessonColumns}
          data={filteredData || lessonData}
          rowsPerPage={18}
          loading={loadingTable}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
      />
    </>
  )

  const content = (
    <>
      {showModal && (
        <Modal close={handleClose} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
      <Container>
        <TableHeader>
          <span style={{ fontSize: '2rem' }}>
            <span
              onClick={() => handleModulosClick()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Módulos
            </span>
            {' > '}
            <span
              onClick={() => handleModuleClick()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {selectedModule?.name}
            </span>
            {' > '}
            <span
              onClick={() => handleLessonClick()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {selectedLesson?.lessonName}
            </span>
            <span>{' > '}Conteúdos</span>
          </span>
          <div style={{ display: 'none' }}>
            <Filter data={contentData} filters={filters} result={handleFilterResult} />
            <HeaderSpacer />
            <SearchBox
              data={contentData}
              fields={['name', 'contentType', 'moduleName']}
              result={handleSearchResult}
            />
            <HeaderSpacer />
          </div>

          <NewButton onClick={() => handleNewContent()}>Cadastrar novo conteúdo</NewButton>
        </TableHeader>
        <ConquerTable
          columns={contentColumns}
          data={filteredData || contentData}
          rowsPerPage={18}
          loading={loadingTable}
        />
      </Container>
      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
        animationTime={30}
      />
      {showEditModal && (
        <Modal close={handleCloseEditModal} title={modalTitle} subtitle={modalSubtitle}>
          {formComponent}
        </Modal>
      )}
    </>
  )

  return (
    <>
      {deleteConfirmation && (
        <ModalDeleteConfirmation
          handleClose={handleCloseModalConfirmation}
          title='Apagar:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onSuccess={() => getContentByLesson(selectedLesson.id)}
        ></ModalDeleteConfirmation>
      )}
      {selectedModule ? (selectedLesson ? content : lesson) : module}
    </>
  )
}
