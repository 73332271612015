import styled from 'styled-components'

export const WinnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px 0;

  h3 {
    font-size: 3rem;
    font-weight: 500;
    color: #ffff00;
    margin: 10px 0;
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #ffff00;
    margin: 10px 0;
  }

  span {
    font-size: 1.7rem;
    font-weight: 700;
    color: #ffff00;
    margin: 10px 0;
  }
`
