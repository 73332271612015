import React, { useState, useEffect } from 'react'
import { ReactComponent as IconConquer } from '../../assets/svg/icon-conquer.svg'
import {
  HeaderMobileBox,
  HamburguerMenu,
  FirstLine,
  SecondLine,
  ExpandMenu,
  Menubox,
  MenuItems,
  Highlight,
} from './styles'
import { Container, Flex } from '../../assets/styles/global'
import { Animated } from 'react-animated-css'
import { ReactComponent as IconQuestion } from '../../assets/svg/icon-question-circle.svg'
import { useBlockerModal } from '../../contexts/blockerModal.context'
import BlockerModal from '../BlockerModal'
import { useUserInfo } from '../../contexts/user.context'
import { Navigate } from 'react-router-dom'
import { HeaderBox } from '../header/styles'
import ModalNews from '../ModalNews'

export default function HeaderMobile() {
  const [openMenu, setOpenMenu] = useState(false)
  const { blockerModal, setBlockerModal } = useBlockerModal()
  const [redirectAdmin, setRedirectAdmin] = useState(false)
  const [redirectReview, setRedirectReview] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [hideModal, setHideModal] = useState(true)
  const { userInfo } = useUserInfo()

  function logout() {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('token')
    window.location = '/login'
  }

  useEffect(() => {
    setRedirect(false)
    setRedirectAdmin(false)
    setRedirectReview(false)
  }, [redirect, redirectAdmin, redirectReview])

  function home() {
    setRedirect(true)
  }

  function admin() {
    setRedirectAdmin(true)
  }

  useEffect(() => {
    var is_chrome = navigator.userAgent.indexOf('CriOS') > -1
    var is_safari = navigator.userAgent.indexOf('Safari') > -1
    var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    if (is_chrome && is_safari) {
      is_safari = false
    }

    if (iOS && is_chrome) {
      setBlockerModal({
        status: true,
        text: 'Podemos te dar uma dica? Sua experiência na nossa plataforma será muito melhor pelo Safari ou pelo computador. Nos encontramos lá?',
        type: 'low',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleHideModal(e) {
    setHideModal(e)
    setOpenMenu(false)
  }

  function handleNeedHelpRedirect() {
    window.location = 'https://tamojunto.escolaconquer.com.br/hc/pt-br'
  }

  return (
    <>
      {redirect ? <Navigate to='/home'></Navigate> : ''}
      {redirectAdmin ? <Navigate to='/admin'></Navigate> : ''}
      {redirectReview && <Navigate to='/my-reviews'></Navigate>}
      <HeaderMobileBox>
        <Container HeightFull>
          <Flex AlignItems='center' JustifyContent='space-between'>
            <IconConquer height='42px' onClick={() => home()} />
            <HamburguerMenu onClick={() => setOpenMenu(!openMenu)}>
              <FirstLine Open={openMenu} />
              <SecondLine Open={openMenu} />
            </HamburguerMenu>
          </Flex>
        </Container>
        <ExpandMenu Open={openMenu}>
          <Menubox>
            <Animated
              animationIn='flipInX'
              animationOut='fadeOut'
              isVisible={openMenu}
              animationInDelay={150}
              animationOutDuration={100}
            >
              <MenuItems onClick={() => home()}>home</MenuItems>
            </Animated>
            <Animated
              animationIn='flipInX'
              animationOut='fadeOut'
              isVisible={openMenu}
              animationInDelay={150}
              animationOutDuration={100}
            >
              <MenuItems onClick={() => handleHideModal(false)}>Novidades</MenuItems>
            </Animated>
            <Animated
              animationIn='flipInX'
              animationOut='fadeOut'
              isVisible={openMenu}
              animationInDelay={750}
              animationOutDuration={100}
            >
              <MenuItems onClick={() => handleNeedHelpRedirect()}>
                <IconQuestion style={{ width: '30px', height: '24px', marginRight: '9px' }} />
                <Highlight>precisa de ajuda?</Highlight>
              </MenuItems>
            </Animated>
            <Animated
              animationIn='flipInX'
              animationOut='fadeOut'
              isVisible={openMenu}
              animationInDelay={900}
              animationOutDuration={100}
            >
              <MenuItems onClick={() => logout()}>sair da plataforma</MenuItems>
            </Animated>
          </Menubox>
        </ExpandMenu>
      </HeaderMobileBox>
      <ModalNews hide={hideModal} closeModal={handleHideModal} />
      <BlockerModal Active={blockerModal} />
    </>
  )
}
