import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ActionButton, Container } from '../../../../styles'
import ConquerTable from '../../../../Template/ConquerTable'
import { TextContainer } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import ModalDeleteConfirmationAlternative from '../ModalDeleteConfirmationAlternative'

export default function ViewModalLive(props) {
  const [liveReserved, setLiveReserved] = useState(null)
  const [loadingTable, setLoadingTable] = useState(true)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemUrl, setDeleteItemUrl] = useState('')
  const [deleteItemName, setDeleteItemName] = useState('')

  function handleCloseModalConfirmation() {
    setDeleteConfirmation(false)
  }

  function handleDeleteButton(e) {
    setDeleteItemUrl(`/admin/live-schedule/${e.id}/student/${e.studentId}`)
    setDeleteItemName(e.theme)
    setDeleteConfirmation(true)
  }
  const liveColumns = [
    {
      title: 'Nome do professor',
      id: 'teacherName',
      content: (v) => <span>{v.teacher.name}</span>,
      props: {
        left: true,
      },
    },
    {
      title: 'Nome da Aula',
      id: 'theme',
      content: (v) => <span>{v.theme}</span>,
      props: {
        left: true,
      },
    },

    {
      title: 'Inicio da aula',
      id: 'liveStart',
      content: (v) => (
        <span>
          {new Date(v.liveStart).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      ),
      props: {
        left: true,
      },
    },
    {
      title: 'Fim da aula',
      id: 'liveEnd',
      content: (v) => (
        <span>
          {new Date(v.liveStart).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      ),
      props: {
        left: true,
      },
    },
    {
      title: '',
      id: 'delete',
      content: (v) => (
        <ActionButton onClick={() => handleDeleteButton(v)}>
          <FontAwesomeIcon icon={faTrash} /> Apagar
        </ActionButton>
      ),
      props: {
        left: true,
      },
    },
  ]

  async function fetchLive() {
    await axios.get(`/admin/live-schedule/student/${props.studentId}`).then((response) => {
      let studentId = props.studentId
      setLiveReserved(response.data.content)
      setLiveReserved((liveReserved) =>
        liveReserved.map((liveReserved) => {
          liveReserved.studentId = studentId
          return liveReserved
        }),
      )
    })
    setLoadingTable(false)
  }

  useEffect(() => {
    fetchLive()
  }, [props.studentId])

  return (
    <Container>
      {liveReserved && liveReserved.length > 0 ? (
        <ConquerTable
          columns={liveColumns}
          data={liveReserved}
          loading={loadingTable}
          disablePagination={true}
        />
      ) : (
        <TextContainer>Não há aulas reservadas</TextContainer>
      )}
      {deleteConfirmation && (
        <ModalDeleteConfirmationAlternative
          handleClose={handleCloseModalConfirmation}
          title='Apagar aula:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onSuccess={() => fetchLive()}
        ></ModalDeleteConfirmationAlternative>
      )}
    </Container>
  )
}
