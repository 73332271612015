import axios from 'axios'
import { React, useEffect, useState, useMemo } from 'react'

import { useUserInfo } from '../../../../contexts/user.context'
import {
  Container,
  ActionButton,
  TableHeader,
  NewButton,
  HeaderSpacer,
  SearchField,
} from '../../styles'

import Modal from '../../Template/Modal'
import Alert from '../../Template/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSearch, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons'
import ConquerTablePaginated from '../../Template/ConquerTablePaginated'
import LiveForm from './components/LiveForm'
import EditLiveForm from './components/EditLiveForm'
import ViewModalContainer from './components/ViewModalContainer'
import ModalDeleteConfirmation from '../../Template/ModalDeleteConfirmation'
import { faEye } from '@fortawesome/pro-light-svg-icons'
import { liveClassFilters } from './liveClassConstants'
import FilterLiveClass from '../../Pages/LiveClass/components/FilterLiveClass'
import { getFilters } from './liveClassFunctions'

export default function LiveClass(props) {
  const [lives, setLives] = useState([])

  const [loadingTable, setLoadingTable] = useState(true)
  const { userInfo } = useUserInfo()
  const [formComponent, setFormComponent] = useState()
  const [modalTitle, setModalTitle] = useState()
  const [modalSubtitle, setModalSubtitle] = useState()
  const [modalWidth, setModalWidth] = useState()
  const [hideAlert, setHideAlert] = useState(true)
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemUrl, setDeleteItemUrl] = useState('')
  const [deleteItemName, setDeleteItemName] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [totalNumPages, setTotalNumPages] = useState()
  const [actualPage, setActualPage] = useState(1)
  const [reloadPage, setReloadPage] = useState(false)

  const [teachers, setTeachers] = useState([])
  const [search, setSearch] = useState('')
  const [filterResultData, setFilterResultData] = useState(null)

  useEffect(() => {
    let info = null
    if (userInfo == undefined && localStorage.getItem('userInfo') !== 'undefined') {
      info = JSON.parse(localStorage.getItem('userInfo'))
    } else {
      info = userInfo
    }
  }, [])

  useEffect(() => {
    async function getTeachers() {
      const response = await axios.get('/admin/live/teachers')
      setTeachers(response.data)
    }
    getTeachers()
  }, [])

  useEffect(() => {
    getLives(1)
  }, [filterResultData])

  useEffect(() => {
    reloadPage && getLives(actualPage).then(() => setReloadPage(false))
  }, [reloadPage])

  async function getLives(page) {
    setLoadingTable(true)
    const url = '/admin/live/pagination/search'
    page = page - 1
    const pageFilter = getFilters(page, filterResultData, search)
    if (pageFilter.params['startDate']) {
      const startDate = pageFilter.params['startDate'].split('/')
      pageFilter.params['startDate'] = startDate[2] + '-' + startDate[1] + '-' + startDate[0]
    }
    if (pageFilter.params['endDate']) {
      const endDate = pageFilter.params['endDate'].split('/')
      pageFilter.params['endDate'] = endDate[2] + '-' + endDate[1] + '-' + endDate[0]
    }
    await axios.get(url + `?size=20&page=${page}&sort=liveStart,desc`, pageFilter).then((res) => {
      setTotalNumPages(res.data.totalPages)
      setLives(res.data.content)
      setActualPage(res.data.number + 1)
    })
    setLoadingTable(false)
  }

  const livesColumns = useMemo(() => [
    {
      title: 'Data e hora',
      id: 'liveStart',
      content: (v) => <span>{v.liveStart}</span>,
      props: {
        left: true,
      },
    },
    {
      title: 'Nome',
      id: 'theme',
      content: (v) => <span>{v.theme}</span>,
      width: 200,
      props: {
        left: true,
      },
    },
    {
      title: 'Level',
      id: 'level',
      content: (v) => <span>{v.level ? v.level : 'S/ diagnóstico'}</span>,
      props: {
        left: true,
      },
    },
    {
      title: 'URL',
      id: 'pdfUploadFile',
      content: (v) => (
        <>
          {v.pdfUploadFile ? (
            <a href={v.pdfUploadFile} target='_blank' rel='noreferrer'>
              {' '}
              Arquivo do material
            </a>
          ) : (
            'Aula SEM material'
          )}
        </>
      ),
      width: 200,
      props: {
        left: true,
      },
    },
    {
      title: 'Teacher',
      id: 'teacherName',
      content: (v) => <span>{v.teacher?.name}</span>,
      props: {
        left: true,
      },
    },
    {
      title: 'Limite',
      content: (v) => <span>{v.numberVacancies}</span>,
      width: 200,
      props: {
        left: true,
      },
    },
    {
      title: 'Vagas disponíveis',
      content: (v) => <span>{v.numberVacancies - (v.bookings || 0)}</span>,
      width: 200,
      props: {
        left: true,
      },
    },
    {
      title: '',
      id: 'actions-lives',
      content: (v) => (
        <ActionButton onClick={() => handleLiveViewButton(v)}>
          <FontAwesomeIcon icon={faEye} /> Visualizar
        </ActionButton>
      ),
      width: 200,
    },
    {
      title: '',
      id: 'actions-lives',
      content: (v) => (
        <ActionButton onClick={() => handleLiveEditButton(v)}>
          <FontAwesomeIcon icon={faPencil} /> Editar
        </ActionButton>
      ),
      width: 200,
    },
    {
      title: '',
      id: 'actions-lives',
      content: (v) => (
        <ActionButton onClick={() => handleLiveDeleteButton(v)}>
          <FontAwesomeIcon icon={faTrash} /> Apagar
        </ActionButton>
      ),
      width: 200,
    },
  ])

  function handleNewLive() {
    setFormComponent(
      <LiveForm
        showAlert={handleAlert}
        close={handleCloseModal}
        teachers={teachers}
        updateData={() => {
          getLives(1)
          setShowModal(false)
        }}
      />,
    )
    setModalTitle('Agendar nova aula ao vivo')
    setShowModal(true)
  }

  function handleLiveViewButton(e) {
    setFormComponent(
      <ViewModalContainer
        showAlert={handleAlert}
        close={handleClose}
        liveId={e.id}
        updateData={() => {
          getLives(1)
          setShowModal(false)
        }}
      />,
    )
    setModalTitle('Alunos agendados')
    setModalSubtitle('')
    setModalWidth('50%')
    setShowModal(true)
  }

  function handleLiveEditButton(e) {
    setFormComponent(
      <EditLiveForm
        showAlert={handleAlert}
        close={handleClose}
        liveId={e.id}
        updateData={() => {
          getLives(1)
          setShowModal(false)
        }}
        teachers={teachers}
      />,
    )
    setModalTitle('Edição aula ao vivo')
    setModalSubtitle(e.theme)
    setModalWidth('500px')
    setShowModal(true)
  }

  function handleLiveDeleteButton(e) {
    setDeleteItemUrl('admin/live/' + e.id)
    setDeleteItemName(e.theme)
    setDeleteConfirmation(true)
  }

  function handleClose() {
    setShowModal(false)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
  }

  function handleCloseModal() {
    setShowModal(false)
  }

  function handleCloseModalConfirmation(v) {
    setDeleteConfirmation(false)
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handlePageChange(page) {
    getLives(page)
  }

  const filters = useMemo(() => liveClassFilters)

  function handleFilterResult(e) {
    setFilterResultData(e)
  }

  function clearFilter() {
    setSearch('')
  }

  function handleFilterKeyPress(e) {
    if (e.key === 'Enter') {
      setSearch(e.target.value)
      getLives(1)
    }
  }

  return (
    <>
      <Container>
        <TableHeader>
          <span style={{ fontSize: '2rem' }}>Aulas ao vivo</span>
          <div style={{ marginLeft: 'auto' }}>
            {teachers.length > 0 && (
              <FilterLiveClass
                teacherList={teachers}
                data={lives}
                filters={filters}
                result={handleFilterResult}
              />
            )}
            <HeaderSpacer />
            <SearchField>
              <input
                type='text'
                placeholder='Pesquisar'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={handleFilterKeyPress}
              />
              <button onClick={() => getLives(1)}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
              {search && search !== '' && (
                <a onClick={() => clearFilter()}>
                  <FontAwesomeIcon icon={faTimes} />
                  Limpar pesquisa
                </a>
              )}
            </SearchField>
            <HeaderSpacer />
          </div>
          <NewButton onClick={() => handleNewLive()}>Agendar nova aula ao vivo</NewButton>
        </TableHeader>

        <ConquerTablePaginated
          columns={livesColumns}
          data={lives}
          totalNumPages={totalNumPages}
          loading={loadingTable}
          onPageChange={handlePageChange}
        />

        {showModal && (
          <Modal
            close={handleCloseModal}
            title={modalTitle}
            subtitle={modalSubtitle}
            width={modalWidth}
          >
            {formComponent}
          </Modal>
        )}
      </Container>

      <Alert
        hide={hideAlert}
        success={alertSuccess}
        message={alertMessage}
        animationEnded={handleAnimation}
        animationTime={30}
      />

      {deleteConfirmation && (
        <ModalDeleteConfirmation
          handleClose={handleCloseModalConfirmation}
          title='Apagar:'
          subtitle={deleteItemName}
          url={deleteItemUrl}
          onSuccess={() => getLives(1)}
        ></ModalDeleteConfirmation>
      )}
    </>
  )
}
