import styled from 'styled-components'
import { ReactComponent as Icon } from '../../assets/svg/icon-conquer.svg'

export const HeaderBox = styled.div`
  background-color: #141414;
  width: 100%;
  height: 90px;
`

export const IconConquer = styled(Icon)`
  height: 52px;
  margin-right: 80px;
  &:hover {
    cursor: pointer;
  }
`

export const HelpBox = styled.div`
  background-color: #242424;
  height: 100%;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`

export const Item = styled.div``

export const SubMenu = styled.div`
  bottom: 0;
  right: 0;
  border-top: #ffff00 solid 1px;
  width: 100%;
  position: absolute;
  display: ${(props) => (props.Active ? 'block' : 'none')};
  transition: 0.5s;
  transform: translateY(100%);
  z-index: 105;
  & > ${Item} {
    background-color: black;
    padding: 20px;
    color: white;
    font-size: 1.6rem;

    &:hover {
      background-color: rgb(80, 80, 80);
    }
  }
  &:hover {
    cursor: pointer;
  }
`

export const Highlight = styled.span``

export const AccountMenu = styled.div`
  color: white;
  font-size: 1.6rem;
  padding-left: 40px;
  padding-right: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
  }

  & > svg {
    margin-left: 12px;
  }
`

export const MenuItems = styled.div`
  color: ${(props) => (props.Active ? 'white' : '#545454')};
  text-transform: uppercase;
  font-size: 1.9rem;
  font-weight: 300;
  background-color: ${(props) => (props.Active ? '#1B1B1B' : 'transparent')};
  padding: 22px 35px;
  margin-bottom: -21px;
  cursor: pointer;
  border-radius: 20px 20px 0px 0px;
  & ${Highlight} {
    color: ${(props) => (props.Active ? '#FFFF00' : '#545454')};
  }
`

export const ButtonNews = styled.div`
  border: 1px solid ${(props) => (props.active ? '#FFFF00' : '#FFFFFF')};
  border-radius: 4px;
  background-color: ${(props) => (props.active ? '#FFFF00' : '')};
  color: ${(props) => (props.active ? '#1A1A1A' : '#FFFFFF')};
  font-size: 16px;
  padding: 6px 20px;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: 0;
  }

  & svg {
    color: ${(props) => (props.active ? '#1A1A1A' : '#FFFF00')};
    transform: rotate(315deg);
  }
`
