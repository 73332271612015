import styled from 'styled-components'

export const LoadingContainer = styled.div`
  width: 100%;
  height: 300px;
`

export const NewEnrollmentButtonPositioning = styled.div`
  margin-left: 12px;
`

export const SuccessMessageStyle = styled.div`
  display: flex;
  color: #969696;
  font-size: 1.4rem;
  padding-top: 4px;
  align-items: center;
`
