import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
`

export const ContainerFlexWrap = styled.div`
  background-color: #212121;
  width: 100%;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  flex-wrap: wrap;
  padding: 10px;
`

// @deprecated
export const TableContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || '#212121'};
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 60px;
`

export const TableHeader = styled.div`
  width: 100%;
  background-color: #212121;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 60px 0px 60px;

  & > span {
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }

  & > p {
    font-size: 2rem;
    color: white;
  }

  & > div {
    display: flex;
    align-items: center;
  }
`

export const SearchField = styled.div`
  height: 42px;
  display: flex;
  position: relative;

  & > input {
    height: 100%;
    border: none;
    background-color: #2a2a2a;
    color: white;
    font-family: 'DM Sans';
    padding: 0px 20px;
    font-size: 15px;
    width: 300px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgb(220, 220, 220);
    }
  }

  & > button {
    height: 100%;
    width: 65px;
    background-color: #ffff00;
    color: black;
    border: none;
    border-radius: 0px;
    font-size: 18px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  & > a {
    position: absolute;
    bottom: -5px;
    left: 20px;
    transform: translateY(100%);
    color: #ffff00;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    & > svg {
      margin-right: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

export const HeaderSpacer = styled.div`
  height: 100%;
  width: 20px;
`

export const NewButton = styled.button`
  border: none;
  background-color: ${(props) => (props.transparent ? '#000' : '#FFFF00')};
  color: ${(props) => (props.transparent ? '#fff' : 'black')};
  height: 42px;
  width: max-content;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 25px;
  font-family: 'DM Sans';

  &:focus {
    outline: none;
  }
`

export const Badge = styled.div`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  background-color: #28a745;
  color: white;
`

export const EditButton = styled.button`
  height: 30px;
  width: 30px;
  color: black;
  background-color: #ffff00;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  /* display: flex;
    flex-direction: column; */

  &:focus {
    outline: none;
    border: none;
  }

  & > svg {
    font-size: 1.5rem;
    margin-left: 2px;
    margin-top: 4px;
  }

  div {
    margin-bottom: 30px;
    color: white;
  }
`

export const ActionButton = styled.button`
  height: 30px;
  width: 30px;
  color: #ffff00;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    background-color: rgba(200, 200, 200, 0.1);
  }

  & > svg {
    font-size: 1.5rem;
  }
`

export const InputBox = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'block')};

  & > input[type='text'],
  & > input[type='date'],
  & > input[type='time'],
  & > input[type='number'] {
    display: initial;
    height: 36px;
    color: white;
    width: 100%;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 10px 12px;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }

  & > label {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  & > .checkbox {
    display: flex;
    align-items: center;
    width: 100%;

    & > label {
      color: white;
      font-size: 1.5rem;
      margin-left: 5px;
    }

    & > input {
      background-color: #373737;
    }
  }

  & > select {
    display: block;
    height: 36px;
    width: 100%;
    color: white;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.6rem;
    border-radius: 6px;
    padding: 0px 12px;

    &:focus {
      border: 1px solid #ffff00;
      outline: none;
    }
  }
`

export const InputBoxDynamic = styled.div`
  display: ${(props) => props.display || 'block'};
  flex: ${(props) => props.flexSpacing || '0 1 auto'};
  padding: 30px 60px 0 60px;
  align-items: center;

  & > select {
    background-color: inherit;
    color: #fff;
    padding: 5px;
    border-radius: 5px;

    & > option {
      color: black;
    }
  }

  & > input[type='button'] {
    display: initial;
    height: 30px;
    color: white;
    background-color: #323232;
    border: 1px solid #898989;
    font-size: 1.4rem;
    border-radius: 6px;
    margin: 10px;
    cursor: pointer;

    &:hover {
      border: 1px solid #ffff00;
      outline: none;
    }

    &:active {
      border: 1px solid #ffff00;
      background-color: #545454;
      outline: none;
    }
  }

  & > input[type='text'] {
    height: 100%;
    width: 130%;
    margin-top: 15px;
  }

  & > span {
    color: white;
    font-size: 1.6rem;
  }

  & > label {
    font-size: 1.4rem;
    color: #969696;
  }
`

export const Tabs = styled.div`
  padding: 30px 60px 0px 60px;
  height: 50px;

  & > .actions {
    display: flex;

    & > button {
      color: #969696;
      background-color: inherit;
      border: none;
      outline: none;
      cursor: pointer;
      padding-left: 10px;
      margin: 0;
      transition: 0.3s;
      font-size: 17px;
      padding-right: 10px;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 900;
    }

    & > .selectedTab {
      color: yellow;
      border-bottom: 1px solid yellow;
    }

    & > button:hover {
      color: yellow;
      border-bottom: 1px solid yellow;
    }

    & > button.active {
      color: yellow;
    }
  }

  & > .line {
    border-bottom: 1px solid #424242;
  }
`

export const Input = styled.input`
  display: block;
  height: 36px;
  color: white;
  width: 100%;
  background-color: #323232;
  border: 1px solid #898989;
  font-size: 1.6rem;
  border-radius: 6px;
  padding: 10px 12px;
  max-width: ${(props) => props.maxWidth || 'auto'};
  text-align: ${(props) => props.textAlign || 'auto'};

  &:focus {
    border: 1px solid #ffff00;
    outline: none;
  }
`

export const TextButton = styled.button`
  align-self: center;
  width: max-content;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  height: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: ${(props) => (props.color === 'primary' ? '#FFFF00' : '#828282')};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(255, 255, 0, 0.05);
  }
`

export const Row = styled.div`
  width: 100%;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'normal'};
  margin-top: ${(props) => props.mt || '0px'};
`

export const ButtonContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

export const ConfirmButton = styled.button`
  background: #ffff00;
  border: none;
  font-weight: 500;
  font-size: 1.8rem;
  color: black;
  cursor: pointer;
  align-items: center;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  height: 40px;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  &:active {
    outline: none;
  }

  & > svg {
    margin-left: 10px;
  }
`

export const Error = styled.span`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  color: rgba(255, 70, 70);
  text-align: center;
  padding: 0px 20px;
  margin-top: 20px;

  & > svg {
    margin-right: 5px;
  }
`

export const NotificationModal = styled.div`
  width: 100%;
  max-height: 70vh;

  font-family: 'IBM Plex Sans', monospace !important;
  font-size: medium;

  overflow-y: auto;
  overflow-x: hidden;

  color: white;

  p {
    font-family: 'IBM Plex Mono', monospace;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    margin-bottom: 1rem;
    text-align: start;
  }

  a {
    margin-bottom: 1rem;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s linear;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .notice-container {
    width: 90%;
    margin: 1.2rem auto 1.8rem;
    padding: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 1.9rem;
      font-family: 'IBM Plex Mono', monospace;
      box-sizing: border-box !important;
    }
  }
`
