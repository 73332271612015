import styled from 'styled-components'

export const Category = styled.div`
  width: 100%;

  & > span.empty-section {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    line-height: 24px;
    color: #898989;
  }

  & + & {
    margin-top: 20px;
  }
`

export const CategoryHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #898989;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 24px;

  & > span.category-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    width: 100%;
    color: white;
  }
`

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  & > span.text {
    font-size: 1.5rem;
    line-height: 24px;
    max-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 0 1 100%;
  }

  & > div.buttons {
    display: flex;
    height: 20px;
    flex: 0 0 120px;
    justify-content: flex-end;
  }
`

export const NewButton = styled.button`
  border: none;
  background: transparent;
  color: #ffff00;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 20px;
  padding: 0px 5px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border-radius: 32px;

  &:focus,
  &:hover {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export const ActionButton = styled.button`
  border: none;
  background: transparent;
  color: #ffff00;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 20px;
  width: 20px;
  padding: 0px;
  display: flex;
  align-items: center;
  transition: 0.25s;
  border-radius: 10px;

  & > span {
    font-size: 1.15rem;
    color: transparent;
    transition: color 0s ease-in-out 0s;
    flex: 0 1 70%;
    text-transform: uppercase;
    pointer-events: none;
    user-select: none;
  }

  & > svg {
    height: 13px;
    flex: 1 0 30%;
  }

  &:focus,
  &:hover {
    outline: none;
    width: ${(props) => props.maxWidth};
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0px 10px 0px 5px;
    color: ${(props) => props.hoverColor || '#FFFF00'};

    & > span {
      color: ${(props) => props.hoverColor || '#FFFF00'};
      transition: color 0.05s ease-in-out 0.1s;
    }
  }

  & + & {
    margin-left: 5px;
  }
`
