import React, { useEffect } from 'react'

import {
  Ballon,
  BallonContent,
  StepIndicator,
  StepText,
  CloseButton,
  NextButton,
  FinishButton,
} from './styles'

import { useTour } from '../../contexts/tour.context'

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { setPersistedState } from 'persisted-state'

export default function Tour(props) {
  const { tour, setTour } = useTour()

  useEffect(() => {
    if (isActualStep()) {
      if (props.disabled) {
        nextStep()
        return
      }

      scrollToStep()
    }
  }, [tour.actualStep])

  function handleNext() {
    if (props.onNext) props.onNext()

    isLastStep() ? finishTour() : nextStep()
  }

  function nextStep() {
    setTour({ ...tour, actualStep: tour.actualStep + 1 })
  }

  function finishTour() {
    setPersistedState('onboardingCompleted', true)
    setTour({ active: false, actualStep: -1, actualPage: '', total: 0 })
  }

  function isActualStep() {
    return props.sequence === tour.actualStep && props.page === tour.actualPage
  }

  function isLastStep() {
    return !props.isNotLastStep && tour.actualStep + 1 === tour.total
  }

  function isMdView() {
    return window.innerWidth < 1200
  }

  function scrollToStep() {
    if (props.scrollTo)
      window.scrollTo({ behavior: 'smooth', top: isMdView() ? props.mdScrollTo : props.scrollTo })
  }

  return (
    <>
      {tour.active && isActualStep() && (
        <Ballon position={props.position} positionMd={props.positionMd}>
          <BallonContent hideButtons={props.hideButtons}>
            <StepIndicator>
              {tour.actualStep + 1}/{tour.total}
            </StepIndicator>
            <StepText>{props.children}</StepText>
            {!props.hideButtons && (
              <>
                <CloseButton onClick={() => finishTour()}>
                  <FontAwesomeIcon icon={faTimes} />
                </CloseButton>
                {isLastStep() ? (
                  <FinishButton onClick={() => handleNext()}>Finalizar</FinishButton>
                ) : (
                  <NextButton onClick={() => handleNext()}>Entendi</NextButton>
                )}
              </>
            )}
          </BallonContent>
        </Ballon>
      )}
    </>
  )
}
