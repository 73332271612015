import React from 'react'
import { AlertBox } from './styles'

export default function Alert(props) {
  return (
    <>
      <AlertBox type={props.type} show={props.show}>
        {props.text}
      </AlertBox>
    </>
  )
}
