import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import DropFileZone from '../../../../../DropFileZone'
import { ButtonContainer, Error, InputBox } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'

export default function NewCurationForm(props) {
  const [payload, setPayload] = useState(null)
  const [levels, setLevels] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setPayload({ ...props.payload })
  }, [props.payload])

  useEffect(() => {
    let isMounted = true

    getLevels(isMounted)

    if (props.curationId) {
      getCurationById(props.curationId)
    }
    return () => (isMounted = false)
  }, [])

  async function getCurationById(curationId) {
    await axios.get('/admin/curation/' + curationId).then((resp) => {
      setPayload(resp.data)
    })
  }
  async function getLevels() {
    await axios.get('/admin/curation/level').then((resp) => {
      setLevels(resp?.data)
    })
  }

  function handleChange(e) {
    if (e.target.name == 'levelId') {
      setPayload({
        ...payload,
        ['level']: { id: e.target.value },
      })
    } else {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      })
    }
  }

  function handleUploadedFile(e) {
    setPayload({
      ...payload,
      thumb: e,
    })
  }

  function validatePayload() {
    if (!payload.name) {
      setErrorMessage('Por favor, insira o nome da curadoria.')
      return false
    }

    if (!payload.description) {
      setErrorMessage('Por favor, insira a descrição da curadoria.')
      return false
    }

    if (!payload.thumb) {
      setErrorMessage('Por favor, insira a imagem de capa da curadoria.')
      return false
    }

    if (!payload.level.id) {
      setErrorMessage('Por favor, selecione o nível da curadoria.')
      return false
    }

    setErrorMessage('')
    return true
  }

  function onSubmitResponse(e) {
    let msg = 'cadastrada'
    if (props.action != 'CREATE') {
      msg = 'alterada'
    }
    props.showAlert({
      message: <span>Curadoria {msg} com sucesso!</span>,
      success: true,
    })
    props.updateData(true)
    props.close()
  }

  function onSubmitError(data, status, generalMessage) {
    setErrorMessage('Ocorreu um erro. ' + generalMessage)
  }

  return (
    <>
      {payload && (
        <>
          <InputBox>
            <label>Nome da Curadoria</label>
            <input
              name='name'
              type='text'
              placeholder='Nome'
              value={payload.name}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Descrição</label>
            <input
              name='description'
              type='text'
              placeholder='Descrição'
              value={payload.description}
              onChange={handleChange}
            />
          </InputBox>
          <InputBox>
            <label>Imagem de Capa</label>
            <DropFileZone
              validTypes={['image/jpeg', 'image/jpg', 'image/png']}
              maxSize={1024 * 1024 * 10}
              uploadedFiles={(e) => handleUploadedFile(e)}
            />
          </InputBox>
          <InputBox>
            <label>Nível</label>
            <select name='levelId' value={payload?.level?.id} onChange={handleChange}>
              <option disabled value=''>
                Selecione o Nível
              </option>
              {levels.map((level) => (
                <option key={level.id} value={level.id}>
                  {level.name}
                </option>
              ))}
            </select>
          </InputBox>
          {errorMessage && (
            <Error>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </Error>
          )}
          <ButtonContainer>
            <div></div>
            <SubmitButton
              isUpdate={props.action != 'CREATE'}
              url={'admin/curation'}
              payload={payload}
              isValid={validatePayload}
              onResponse={onSubmitResponse}
              onError={onSubmitError}
            />
          </ButtonContainer>
        </>
      )}
    </>
  )
}
