import React from 'react'
import { Container } from '../../styles'
import Awards from './components/Awards'
import { GamificationContainer, GamificationSection } from './styles'
import Ranking from './components/Ranking'

export default function Gamification() {
  return (
    <GamificationContainer>
      <Container>
        <GamificationSection>
          <Awards />
        </GamificationSection>
      </Container>
      <Container>
        <GamificationSection>
          <Ranking />
        </GamificationSection>
      </Container>
    </GamificationContainer>
  )
}
