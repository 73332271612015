import React, { useEffect, useState } from 'react'

import { AdminContainer, ContentContainer } from './styles'
import Panel from '../../components/Admin/Template/Panel'
import Item from '../../components/Admin/Template/Item'
import Users from '../../components/Admin/Pages/Users'
import Student from '../../components/Admin/Pages/Student'
import Modules from '../../components/Admin/Pages/Modules'
import AdminCuration from '../../components/Admin/Pages/Curations'
import Exercises from '../../components/Admin/Pages/Exercises'
import LiveClass from '../../components/Admin/Pages/LiveClass'

import {
  faBallot,
  faBoxesAlt,
  faChalkboardTeacher,
  faUsers,
  faUsersClass,
  faVideo,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons'
import { useUserInfo } from '../../contexts/user.context'
import { Navigate } from 'react-router-dom'
import MentorsRecorded from '../../components/Admin/Pages/MentorsRecorded'
import MentoringRecorded from '../../components/Admin/Pages/MentoringRecorded'
import Gamification from '../../components/Admin/Pages/Gamification'
import { faCrown, faGamepadAlt, faRocketLaunch } from '@fortawesome/pro-light-svg-icons'
import NewsAdmin from '../../components/Admin/Pages/News'
import RankingAdmin from '../../components/Admin/Pages/Ranking'

export default function Admin() {
  const [currentPage, setCurrentPage] = useState({
    component: <Student />,
    name: 'new-users',
  })
  const [redirect, setRedirect] = useState(false)
  const { userInfo } = useUserInfo()

  useEffect(() => {
    let info = null
    if (userInfo == undefined && localStorage.getItem('userInfo') !== 'undefined') {
      info = JSON.parse(localStorage.getItem('userInfo'))
    } else {
      info = userInfo
    }

    if (!info) {
      setRedirect(true)
    }
  }, [])

  const items = [
    {
      id: 'new-users',
      text: 'Alunos - Online/Live',
      component: <Student />,
      icon: faUsers,
    },
    { id: 'users', text: 'Admin Users', component: <Users />, icon: faWrench },
    {
      id: 'modules',
      text: 'Módulos do curso',
      component: <Modules />,
      icon: faBoxesAlt,
    },
    {
      id: 'curations',
      text: 'Skills',
      component: <AdminCuration />,
      icon: faBoxesAlt,
    },
    {
      id: 'live-class',
      text: 'Aulas ao vivo - Live',
      component: <LiveClass />,
      icon: faVideo,
    },
    {
      id: 'exercises',
      text: 'Exercícios',
      component: <Exercises />,
      icon: faBallot,
    },
    {
      id: 'mentors-recorded',
      text: 'Mentores - SUPREME',
      component: <MentorsRecorded />,
      icon: faChalkboardTeacher,
    },
    {
      id: 'mentoring-recorded',
      text: 'Mentorias - SUPREME',
      component: <MentoringRecorded />,
      icon: faUsersClass,
    },
    {
      id: 'gamification',
      text: 'Gamificação',
      component: <Gamification />,
      icon: faGamepadAlt,
    },
    {
      id: 'ranking',
      text: 'Ranking',
      component: <RankingAdmin />,
      icon: faCrown,
    },
    {
      id: 'Novidades',
      text: 'Novidades',
      component: <NewsAdmin />,
      icon: faRocketLaunch,
    },
  ]

  function handlePageChange(item) {
    if (currentPage.name !== item.id) {
      setCurrentPage({ component: item.component, name: item.id })
    }
  }

  return (
    <>
      {redirect ? <Navigate to='/login' /> : null}
      <AdminContainer>
        <Panel>
          {items.map((item) => (
            <Item
              id={item.id}
              key={item.id}
              text={item.text}
              active={item.id === currentPage.name}
              icon={item.icon}
              onClick={() => handlePageChange(item)}
            />
          ))}
        </Panel>
        <ContentContainer>{currentPage.component}</ContentContainer>
      </AdminContainer>
    </>
  )
}
