import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
`

export const TableContainer = styled.div`
  width: 100%;
  background-color: #212121;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 100px;
`

export const EditButton = styled.button`
  height: 30px;
  width: 30px;
  color: black;
  background-color: #ffff00;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }

  & > svg {
    font-size: 1.5rem;
    margin-left: 2px;
  }
`

export const Image = styled.img`
  background-image: url(${(props) => props.src});
`

export const FailureContent = styled.h2`
  color: #fff;
`

export const FailureList = styled.ul`
  color: #fff;
  font-size: 1.5rem;
`
