import styled from 'styled-components'

export const RankingContainer = styled.div`
  font-size: 1.6rem;
  margin-top: 50px;

  .date-ranking {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .dates {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 20%;
      margin-right: 15px;

      label {
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
      }

      input {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: none;
        padding: 0 10px;
        font-size: 1.6rem;
      }

      .disabled {
        cursor: not-allowed;
        color: #000;
      }
    }
  }
`
