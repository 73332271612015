import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/pro-solid-svg-icons'

import { SearchField } from './styles'

export default function SearchBox({ data, fields, result }) {
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    if (searchText.length > 0) {
      result(filteredData)
    } else {
      result(null)
    }
  }, [filteredData])

  function handleSearchChange(e) {
    setSearchText(e.target.value)
  }

  function handleSearchKeyPress(e) {
    if (e.key === 'Enter') filterBySearch()
  }

  function filterBySearch() {
    let filteredData = []
    const filteredDataArrays = []

    if (searchText) {
      fields.forEach((field) => {
        filteredDataArrays.push(
          data.filter((d) => d[field]?.toLowerCase().includes(searchText.toLowerCase())),
        )
      })

      filteredDataArrays.forEach((array) => {
        filteredData = filteredData.concat(array)
        filteredData = [...new Set([...filteredData, ...array])]
      })
    } else filteredData = []

    setFilteredData(filteredData)
  }

  function clearFilteredData() {
    setFilteredData([])
    setSearchText('')
    result(null)
  }

  return (
    <SearchField>
      <input
        type='text'
        placeholder='Pesquisar'
        value={searchText}
        onChange={handleSearchChange}
        onKeyPress={handleSearchKeyPress}
      />
      <button onClick={() => filterBySearch()}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
      {filteredData.length > 0 && (
        <a onClick={() => clearFilteredData()}>
          <FontAwesomeIcon icon={faTimes} />
          Limpar pesquisa
        </a>
      )}
    </SearchField>
  )
}
