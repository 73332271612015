import { React, useState } from 'react'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinnerThird } from '@fortawesome/pro-light-svg-icons'

import { ConfirmButton } from './style'

export default function SubmitButton(props) {
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('Confirmar')
  const [icon, setIcon] = useState(faCheck)

  function handleSubmitClick() {
    let valid = isValid()
    if (!valid) return

    processingStart()

    setTimeout(() => {
      doRequest()
    }, 1000)
  }

  function isValid() {
    if (props.isValid) {
      return props.isValid()
    }
    return true
  }

  function processingStart() {
    setDisabled(true)
    setLoading(true)
    setText('Salvando')
    setIcon(faSpinnerThird)
  }

  function processingFinish() {
    setDisabled(false)
    setLoading(false)
    setText('Confirmar')
    setIcon(faCheck)
  }

  function doRequest() {
    axios({
      method: props.isUpdate ? 'PUT' : 'POST',
      data: props.payload,
      url: props.url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => {
        if (props.onResponse) {
          props.onResponse(resp)
        }
        processingFinish()

        if (props.closeModal) {
          props.closeModal()
        }
      })
      .catch((error) => {
        let data = null
        let status = null
        let generalMessage = ''
        let request = null
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          data = error.response.data
          status = error.response.status
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          request = error.request
        } else {
          // Something happened in setting up the request that triggered an Error
          generalMessage = error.message
        }

        if (props.onError) {
          props.onError(data, status, generalMessage, request, error.config)
        }
        processingFinish()
      })
  }

  return (
    <>
      <ConfirmButton onClick={() => handleSubmitClick()} disabled={disabled}>
        <span>{text}</span>
        <FontAwesomeIcon icon={icon} className={loading ? 'fa-spin' : ''} />
      </ConfirmButton>
    </>
  )
}
