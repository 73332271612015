export const columnsMentoring = [
  {
    title: 'Data',
    field: 'mentoringStart',
  },
  {
    title: 'Dia da semana',
    field: 'dayOfWeek',
  },
  {
    title: 'Horário',
    field: 'mentoringTime',
  },
  {
    title: 'Nome do aluno',
    field: 'studentMentoringDTO.name',
    content: (v) => v.studentMentoringDTO.name,
    props: {
      center: true,
    },
  },
  {
    title: 'Nome do mentor',
    field: 'mentorDTO.name',
    content: (v) => v.mentorDTO.name,
    props: {
      center: true,
    },
  },
  {
    title: 'Nível do aluno',
    field: 'studentMentoringDTO.level',
    content: (v) => v.studentMentoringDTO.level,
  },
  {
    title: 'Status',
    field: 'status',
  },
]

export const mentoringFilters = [
  {
    title: 'Professores',
    field: 'mentorId',
  },
  {
    title: 'Data Inicial',
    field: 'startDate',
    options: [],
    type: 'date',
  },
  {
    title: 'Data Final',
    field: 'endDate',
    options: [],
    type: 'date',
  },
  {
    title: 'Status',
    field: 'status',
    options: [
      { text: 'Realizadas', value: 'DONE' },
      { text: 'Canceladas', value: 'CANCELED' },
      { text: 'Agendadas', value: 'PENDING' },
      { text: 'Livre', value: 'FREE' },
    ],
  },
  {
    title: 'Dia da Semana',
    field: 'dayOfWeek',
    options: [
      { text: 'MONDAY', value: 'MONDAY' },
      { text: 'TUESDAY', value: 'TUESDAY' },
      { text: 'WEDNESDAY', value: 'WEDNESDAY' },
      { text: 'THURSDAY', value: 'THURSDAY' },
      { text: 'FRIDAY', value: 'FRIDAY' },
      { text: 'SATURDAY', value: 'SATURDAY' },
    ],
  },
]
