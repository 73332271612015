import styled from 'styled-components'

export const BlockerBackground = styled.div`
  background-color: #000000d4;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.Active ? 'flex' : 'none')};
`

export const Modal = styled.div`
  background-color: #ffff00;
  width: 600px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  @media (max-width: 900px) {
    width: 85vw;
  }
`

export const Title = styled.h1`
  color: black;
  font-size: 34px;
  text-align: center;
`

export const Description = styled.p`
  color: black;
  font-size: 18px;
  text-align: center;
  line-height: 29px;
`
