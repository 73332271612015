import styled, { keyframes } from 'styled-components'

const AnimationSkeletonButton = keyframes`
    0%{
        left: -100%;
    }
    100%{
        left: 200%
    }
`

export const TextButton = styled.div`
  color: white;
  font-weight: bold;
  font-size: 19px;
`
export const SkeletonLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  left: 0;
  background: linear-gradient(to right, transparent 0%, #353232 50%, transparent 100%);
  animation: ${AnimationSkeletonButton} 4s infinite;
`
export const ZindexFlex = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconButton = styled.div`
  background-color: #ffe607;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  transition: 0.3s;

  & > svg {
    font-size: 18px;
    transition: 0.3s;
  }
`

export const Button = styled.div`
  height: 60px;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.Loading ? '' : 'space-between')};
  background-color: #141414;
  margin-top: 20px;
  cursor: pointer;
  & ${TextButton} {
    padding-left: ${(props) => (props.Loading ? '10px' : '20px')};
  }

  &:hover {
    ${IconButton} {
      background-color: black;

      & > svg {
        color: #ffe607;
      }
    }
  }
`
