import React, { createContext, useState, useContext, useEffect } from 'react'

const LoadingContext = createContext()

export default function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const [fadeLoading, setFadeLoading] = useState(false)
  const [displayLoading, setDisplayLoading] = useState(false)

  useEffect(() => {
    if (loading) {
      setFadeLoading(false)
      setDisplayLoading(true)
    } else {
      setFadeLoading(true)
      setTimeout(() => {
        setDisplayLoading(false)
      }, 2000)
    }
  }, [loading])

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        fadeLoading,
        displayLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}

export function useLoading() {
  const context = useContext(LoadingContext)
  const { loading, setLoading, fadeLoading, displayLoading } = context
  return { loading, setLoading, fadeLoading, displayLoading }
}
