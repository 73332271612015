import React, { useEffect, useState } from 'react'
import axios from 'axios'

import {
  Container,
  TableHeader,
  NewButton,
  HeaderSpacer,
  ActionButton,
  NotificationModal,
} from '../../styles'
import ConquerTable from '../../Template/ConquerTable'
import { formatTimestampDDMMYYYYhhmmss } from '../../../../utils/DateFormatter'
import NewsInputForm from './components/NewInputForm'

import { faEye, faEyeSlash, faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../Template/Modal'

export default function NewsAdmin() {
  const [data, setData] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [showNewsForm, setNewsForm] = useState(false)
  const [payload, setPayload] = useState(null)
  const [newsFormTitle, setNewsFormTitle] = useState('')
  const [showNewsModal, setShowNewsModal] = useState(false)
  const [news, setNews] = useState()

  let isMounted = true

  const columns = React.useMemo(() => [
    { title: 'Título', field: 'title' },
    {
      title: 'Data de criação',
      field: 'createdAt',
      content: (v) => formatTimestampDDMMYYYYhhmmss(v.createdAt),
    },
    { title: 'Criado por', field: 'createdBy' },
    { title: 'Editado por', field: 'updatedBy' },
    {
      title: 'Editar',
      content: (v) => (
        <ActionButton
          onClick={() =>
            handleSetEditNews({
              id: v.id,
              title: v.title,
              description: v.description,
            })
          }
        >
          <FontAwesomeIcon icon={faPencil} />
        </ActionButton>
      ),
      width: 20,
    },
    // {title: "Excluir",
    //     content: (v) => (
    //         <ActionButton onClick={() => handleHideNews(v.id)}>
    //             <FontAwesomeIcon icon={faEyeSlash} />
    //         </ActionButton>
    //     ),
    //     width: 20,
    // },
  ])

  function handleSetEditNews(v) {
    setNewsFormTitle('Editar Novidade da plataforma')
    setPayload(v)
    setNewsForm(true)
  }

  async function getData(isMounted) {
    setLoadingTable(true)

    await axios
      .get('/news')
      .then((res) => {
        setTimeout(() => {
          if (isMounted) {
            setData(
              res.data.payload.body.sort(function (a, b) {
                if (a.createdAt < b.createdAt) return 1
                if (a.createdAt > b.createdAt) return -1
                return 0
              }),
            )
            setLoadingTable(false)
          }
        }, 1000)
      })
      .catch((error) => {
        setLoadingTable(false)
      })
  }

  function handleNewsOpen() {
    setNewsFormTitle('Cadastrar Novidade na plataforma')
    setPayload(null)
    setNewsForm(true)
  }

  function handleUpdateData() {
    getData(true)
    setNewsForm(false)
  }

  async function handleVisualizeNews() {
    await axios.get(`/news/view`).then((resp) => {
      setNews(resp.data)
      setShowNewsModal(true)
    })
  }

  async function handleHideNews(id) {
    await axios
      .delete(`/news/${id}`)
      .then((res) => {
        handleUpdateData()
      })
      .catch((error) => {
        setLoadingTable(false)
      })
  }

  useEffect(() => {
    getData(isMounted)

    return () => (isMounted = false)
  }, [])

  return (
    <>
      <Container>
        {showNewsForm && (
          <Modal title={newsFormTitle} close={() => setNewsForm(false)} width={50}>
            <NewsInputForm payload={payload}></NewsInputForm>
          </Modal>
        )}
        {showNewsModal && (
          <Modal title={newsFormTitle} close={() => setShowNewsModal(false)} width={50}>
            <NotificationModal>
              {news &&
                news.map((value) => (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: value.description }}
                      className='notice-container'
                    />
                    <div className='division'></div>
                  </>
                ))}
            </NotificationModal>
            <p style={{ color: '#fff', fontSize: '16px' }}>
              <b>Visualização aproximada:</b> Esta visualização pode não corresponder exatamente à
              plataforma.
            </p>
          </Modal>
        )}
        <>
          <TableHeader>
            <span>Novidades</span>
            <div>
              <HeaderSpacer />
              <NewButton onClick={() => handleVisualizeNews()}>
                Visualizar <FontAwesomeIcon icon={faEye} />{' '}
              </NewButton>
              <HeaderSpacer />
            </div>
          </TableHeader>
          <ConquerTable
            columns={columns}
            data={data}
            loading={loadingTable}
            disablePagination={true}
          />
        </>
      </Container>
    </>
  )
}
