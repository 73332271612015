import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import DropFileZone from '../../../../../DropFileZone'
import { ButtonContainer, Error, InputBox } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'
import axios from 'axios'

export default function LiveForm(props) {
  const [payload, setPayload] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [formInputs, setFormInputs] = useState()
  const [minDate, setMinDate] = useState(new Date().toISOString().split('T')[0])
  const [teachersList, setTeachersList] = useState(props.teachers)

  useEffect(() => {
    formClear()
  }, [])

  useEffect(() => {
    if (payload) {
      renderFormInputs()
    }
  }, [payload])

  async function getTeachers() {
    const resp = await axios.get('/admin/live/teachers')
    setTeachersList(resp.data)
  }

  function getTeacher(teacherId) {
    let rets = teachersList.filter((t) => t.id === teacherId)
    if (Array.isArray(rets)) {
      return rets[0]
    }
    return rets
  }

  function handleChange(e) {
    let value = e.target.value
    let name = e.target.name
    if (name === 'teacher') {
      value = getTeacher(value)
    }
    setPayload({
      ...payload,
      [name]: value,
    })
  }

  function handleUploadedFile(e, target) {
    setPayload({
      ...payload,
      [target]: e,
    })
  }

  function formClear() {
    setPayload({
      theme: null,
      liveDate: null,
      liveTime: null,
      meetingUrl: null,
      level: null,
      teacher: null,
      pdfUploadFile: null,
      liveStart: null,
    })
  }

  function renderFormInputs() {
    setFormInputs(
      <>
        <InputBox>
          <label>Nome do tema*</label>
          <input
            name='theme'
            type='text'
            placeholder='Nome do tema'
            value={payload.theme}
            onChange={handleChange}
            required
          />
        </InputBox>

        <InputBox>
          <label>Data da aula ao vivo*</label>
          <input
            name='liveDate'
            type='date'
            value={payload?.liveDate}
            onChange={handleChange}
            placeholder='A data da aula ao vivo'
            min={minDate}
          />
        </InputBox>
        <InputBox>
          <label>Horário em que a aula ao vivo vai iniciar *</label>
          <input
            name='liveTime'
            type='time'
            value={payload?.liveTime}
            onChange={handleChange}
            placeholder='O horário da aula ao vivo'
          />
        </InputBox>

        <InputBox>
          <label>
            URL*{' '}
            <FontAwesomeIcon
              icon={faInfoCircle}
              title='A url em que a aula vai acontecer, exemplo: https://zoom.com/haishirs'
            ></FontAwesomeIcon>
          </label>
          <input
            name='meetingUrl'
            type='text'
            placeholder='URL da aula no Zoom'
            value={payload?.meetingUrl}
            onChange={handleChange}
            required
          />
        </InputBox>

        <InputBox>
          <label>Nível*</label>
          <select name='level' value={payload?.level} onChange={handleChange} required>
            <option value=''>Selecione o Nível</option>
            <option value='BEGINNER'>Beginner</option>
            <option value='LOWER_INTERMEDIATE'>Lower Intermediate</option>
            <option value='INTERMEDIATE'>Intermediate</option>
            <option value='UPPER_INTERMEDIATE'>Upper Intermediate</option>
            <option value='ADVANCED'>Advanced</option>
            <option value='UPPER_ADVANCED'>Upper Advanced</option>
          </select>
        </InputBox>

        <InputBox>
          <label>Nome do professor(a)*</label>
          <select name='teacher' value={payload?.teacher?.id} onChange={handleChange} required>
            <option value=''>Selecione o Professor</option>
            {teachersList.map((teacher) => {
              const { name, id } = teacher
              return (
                <option value={id} key={id}>
                  {name.toUpperCase()}
                </option>
              )
            })}
          </select>
        </InputBox>

        <InputBox>
          <label>Arquivo do E-Book (PDF)</label>
          <DropFileZone
            validTypes={['application/pdf']}
            maxSize={1024 * 1024 * 20}
            uploadedFiles={(e) => handleUploadedFile(e, 'pdfUploadFile')}
          />
        </InputBox>
      </>,
    )
  }

  function validatePayload() {
    if (!payload.theme) {
      setErrorMessage('Por favor, insira o nome do tema da aula ao vivo.')
      return false
    }

    if (!payload.liveDate) {
      setErrorMessage('Por favor, insira a data da aula ao vivo.')
      return false
    }

    if (!payload.liveTime) {
      setErrorMessage('Por favor, insira o horário da aula ao vivo.')
      return false
    }

    if (!payload.meetingUrl) {
      setErrorMessage('Por favor, insira a URL da aula ao vivo.')
      return false
    }

    if (!payload.level) {
      setErrorMessage('Por favor, selecione o nível da aula ao vivo.')
      return false
    }
    return true
  }

  function handleSubmitResponse() {
    props.showAlert({
      message: <span>Cadastrado com sucesso!</span>,
      success: true,
    })
    if (props.updateData) {
      props.updateData()
    }
  }

  function handleSubmitError(data, status, generalMessage) {
    let msg = data && data.msg ? data.msg : generalMessage ? generalMessage : null
    if (status === 428) {
      msg =
        'O(a) professor(a) já está alocado(a) para dar aula na mesma data e hora informados. Verifique outra data e horario.'
    }
    setErrorMessage(msg)
  }

  return (
    <>
      {formInputs}
      {errorMessage && (
        <Error>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </Error>
      )}
      <ButtonContainer>
        <div></div>
        <SubmitButton
          url={'admin/live'}
          onResponse={handleSubmitResponse}
          onError={handleSubmitError}
          payload={payload}
          isValid={validatePayload}
          isUpdate={false}
        ></SubmitButton>
      </ButtonContainer>
    </>
  )
}
