import React, { useState, useEffect } from 'react'
import { AwardContainer, AwardList, ModalContainer } from './styles'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '../../../../Template/Modal'
import { Input, NewButton } from '../../../../styles'
import axios from 'axios'

export default function Awards() {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState()
  const [showModalCreateAward, setShowModalCreateAward] = useState()
  const [createAwardMessage, setCreateAwardMessage] = useState()
  const [formComponent, setFormComponent] = useState()
  const [awards, setAwards] = useState([])
  const [newAwardName, setNewAwardName] = useState()
  const [newAwardPosition, setNewAwardPosition] = useState(0)

  const handleNewAwardName = (event) => {
    setNewAwardName(event.target.value)
  }

  const handleNewAwardPosition = (event) => {
    setNewAwardPosition(event.target.value)
  }

  async function handleSubmitAward() {
    setNewAwardName((prevName) => prevName)
    setNewAwardPosition((prevPosition) => prevPosition)

    if (newAwardName === undefined || newAwardPosition === 0) {
      setCreateAwardMessage('Os valores de nome e posição são obrigatórios')
      return
    }

    const formData = {
      name: newAwardName,
      position: newAwardPosition,
    }

    await axios
      .post('/admin/gamification/award', formData)
      .then(() => {
        closeModal()
      })
      .catch((error) => {
        const startErrorMsgIndex = error.response.data.msg.toString().indexOf('["')
        const errorMessage = error.response.data.msg.substring(
          startErrorMsgIndex,
          error.response.data.msg.toString().length,
        )
        setCreateAwardMessage(`Erro ao cadastrar award:  ${errorMessage}`)
      })
    getAwards()
  }

  async function getAwards() {
    await axios.get('/admin/gamification/award').then((resp) => {
      const someAwards = []
      if (resp.status === 200) {
        resp.data &&
          resp.data.forEach((respAward) => {
            const award = {
              name: respAward.name,
              position: respAward.position,
              status: 'ativo',
            }
            someAwards.push(award)
          })
      }
      setAwards(someAwards)
    })
  }

  const handleModalCreateAward = () => {
    setShowModalCreateAward(true)
  }

  function handleModalDeleteAward(name, position) {
    setModalTitle('Excluir Prêmio?')
    setFormComponent(
      <ModalContainer>
        <h3>
          Deseja mesmo excluir o prêmio <span>{name}</span> ?
        </h3>

        <div className='form-buttons'>
          <NewButton onClick={() => closeModal()}>Não</NewButton>
          <NewButton onClick={() => deleteAward(position)}>Sim</NewButton>
        </div>
      </ModalContainer>,
    )
    setShowModal(true)
  }

  async function deleteAward(position) {
    await axios.put('/admin/gamification/award/disable/' + position).then((resp) => {
      if (resp.status === 200) {
        console.log('Success')
      }
    })
    setShowModal(false)
    getAwards()
  }

  function closeModal() {
    setShowModalCreateAward(false)
    setShowModal(false)
  }

  useEffect(() => {
    getAwards()
  }, [])

  return (
    <AwardContainer>
      {showModalCreateAward && (
        <Modal title={'Cadastrar Prêmio'} close={closeModal}>
          <ModalContainer>
            <div className='form'>
              <label>Descrição do Prêmio</label>
              <Input
                type='text'
                name='awardName'
                value={newAwardName}
                onChange={handleNewAwardName}
              />

              <label>Posição do prêmio</label>
              <select name='position' value={newAwardPosition} onChange={handleNewAwardPosition}>
                <option>Selecione </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </select>

              {createAwardMessage && <p color='white'>{createAwardMessage}</p>}

              <NewButton className='confirm-button' onClick={handleSubmitAward}>
                Confirmar
              </NewButton>
            </div>
          </ModalContainer>
        </Modal>
      )}

      <div className='gamification-title'>
        <h2>Prêmios</h2>
        <NewButton onClick={handleModalCreateAward}>Cadastrar novo Prêmio</NewButton>
      </div>
      {showModal && (
        <Modal title={modalTitle} close={closeModal} subtitle={``}>
          {formComponent}
        </Modal>
      )}
      <AwardList>
        <table>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Posição</td>
              <td>Status</td>
              <td>Excluir</td>
            </tr>
          </thead>

          {awards?.length > 0 ? (
            awards?.map((award) => {
              return (
                <tbody key={award.position}>
                  <tr key={award.position}>
                    <td>{award.name}</td>
                    <td>{award.position}</td>
                    <td>{award.status}</td>
                    <td>
                      <NewButton onClick={() => handleModalDeleteAward(award.name, award.position)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </NewButton>
                    </td>
                  </tr>
                </tbody>
              )
            })
          ) : (
            <tbody></tbody>
          )}
        </table>
      </AwardList>
    </AwardContainer>
  )
}
