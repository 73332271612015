import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import { ButtonContainer, Error, InputBox } from '../../../../styles'
import SubmitButton from '../../../../Template/SubmitButton'

export default function ScheduleForm(props) {
  const [payload, setPayload] = useState(null)
  const [contentType, setContentType] = useState('')
  const [mentorId, setMentorId] = useState(props.mentorId)
  const [errorMessage, setErrorMessage] = useState('')
  const [formInputs, setFormInputs] = useState()
  const [mentor, setMentor] = useState()
  const [minDate, setMinDate] = useState(new Date().toISOString().split('T')[0])

  useEffect(() => {
    if (!mentorId) {
      formClear()
    }
  }, [contentType, mentorId])

  useEffect(() => {
    if (props.mentor) {
      setPayload({
        ...payload,
        ['mentor']: props.mentor,
      })
    }
    setMentor(props.mentor)
  }, [props.mentor])

  useEffect(() => {
    if (payload) {
      renderFormInputs()
    }
  }, [payload])

  function handleTypeChange(e) {
    setContentType(e.target.value)
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function formClear() {
    setPayload({})
  }

  function renderFormInputs() {
    setFormInputs(
      <>
        <InputBox>
          <label>Dia da semana</label>
          <select name='dayOfWeek' value={payload?.dayOfWeek} onChange={handleChange}>
            <option disabled value='' selected>
              Selecione o dia da semana
            </option>
            <option value='MONDAY'>Segunda-feira</option>
            <option value='TUESDAY'>Terça-feira</option>
            <option value='WEDNESDAY'>Quarta-feira</option>
            <option value='THURSDAY'>Quinta-feira</option>
            <option value='FRIDAY'>Sexta-feira</option>
            <option value='SATURDAY'>Sábado</option>
          </select>
        </InputBox>
        <InputBox>
          <label>Horário de inicio</label>
          <select
            name='timeStart'
            type='time'
            value={payload?.timeStart}
            required
            onChange={handleChange}
          >
            <option disabled value='' selected>
              Selecione o horário de inicio
            </option>
            <option value='06:00'>06:00</option>
            <option value='06:30'>06:30</option>
            <option value='07:00'>07:00</option>
            <option value='07:30'>07:30</option>
            <option value='08:00'>08:00</option>
            <option value='08:30'>08:30</option>
            <option value='09:00'>09:00</option>
            <option value='09:30'>09:30</option>
            <option value='10:00'>10:00</option>
            <option value='10:30'>10:30</option>
            <option value='11:00'>11:00</option>
            <option value='11:30'>11:30</option>
            <option value='12:00'>12:00</option>
            <option value='12:30'>12:30</option>
            <option value='13:00'>13:00</option>
            <option value='13:30'>13:30</option>
            <option value='14:00'>14:00</option>
            <option value='14:30'>14:30</option>
            <option value='15:00'>15:00</option>
            <option value='15:30'>15:30</option>
            <option value='16:00'>16:00</option>
            <option value='16:30'>16:30</option>
            <option value='17:00'>17:00</option>
            <option value='17:30'>17:30</option>
            <option value='18:00'>18:00</option>
            <option value='18:30'>18:30</option>
            <option value='19:00'>19:00</option>
            <option value='19:30'>19:30</option>
            <option value='20:00'>20:00</option>
            <option value='20:30'>20:30</option>
            <option value='21:00'>21:00</option>
            <option value='21:30'>21:30</option>
            <option value='22:00'>22:00</option>
            <option value='22:30'>22:30</option>
            <option value='23:00'>23:00</option>
            <option value='23:30'>23:30</option>
          </select>
        </InputBox>
        <InputBox>
          <label>Horário de fim</label>
          <select
            name='timeEnd'
            type='time'
            required
            value={payload?.timeEnd}
            onChange={handleChange}
          >
            <option disabled value='' selected>
              Selecione o horário de fim
            </option>
            <option value='06:30'>06:30</option>
            <option value='07:00'>07:00</option>
            <option value='07:30'>07:30</option>
            <option value='08:00'>08:00</option>
            <option value='08:30'>08:30</option>
            <option value='09:00'>09:00</option>
            <option value='09:30'>09:30</option>
            <option value='10:00'>10:00</option>
            <option value='10:30'>10:30</option>
            <option value='11:00'>11:00</option>
            <option value='11:30'>11:30</option>
            <option value='12:00'>12:00</option>
            <option value='12:30'>12:30</option>
            <option value='13:00'>13:00</option>
            <option value='13:30'>13:30</option>
            <option value='14:00'>14:00</option>
            <option value='14:30'>14:30</option>
            <option value='15:00'>15:00</option>
            <option value='15:30'>15:30</option>
            <option value='16:00'>16:00</option>
            <option value='16:30'>16:30</option>
            <option value='17:00'>17:00</option>
            <option value='17:30'>17:30</option>
            <option value='18:00'>18:00</option>
            <option value='18:30'>18:30</option>
            <option value='19:00'>19:00</option>
            <option value='19:30'>19:30</option>
            <option value='20:00'>20:00</option>
            <option value='20:30'>20:30</option>
            <option value='21:00'>21:00</option>
            <option value='21:30'>21:30</option>
            <option value='22:00'>22:00</option>
            <option value='22:30'>22:30</option>
            <option value='23:00'>23:00</option>
            <option value='23:30'>23:30</option>
            <option value='00:00'>00:00</option>
          </select>
        </InputBox>
        <InputBox>
          <label>Data de início</label>
          <input
            name='dateStart'
            type='date'
            required
            value={payload?.dateStart}
            onChange={handleChange}
            min={minDate}
          />
        </InputBox>
        <InputBox>
          <label>Data de fim</label>
          <input
            name='dateEnd'
            type='date'
            required
            value={payload?.dateEnd}
            onChange={handleChange}
            min={minDate}
          />
        </InputBox>
        <InputBox>
          <label>Link da sala</label>
          <input
            name='meetingUrl'
            type='text'
            placeholder='URL da sala'
            value={payload?.meetingUrl}
            onChange={handleChange}
          />
        </InputBox>
      </>,
    )
  }

  function handleSubmitResponse(response) {
    if (response.data.success) {
      props.updateData(true)
    } else {
      setErrorMessage(response?.data?.errorMessage)
    }
  }

  function handleSubmitError(data, status, generalMessage, request, config) {
    setErrorMessage(
      'Erro ao tentar agendar: ' +
        (data && data.msg ? data.msg : generalMessage ? generalMessage : null),
    )
  }

  return (
    <>
      {formInputs}
      {errorMessage && (
        <Error>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {errorMessage}
        </Error>
      )}
      <ButtonContainer>
        <div></div>
        <SubmitButton
          url={'/admin/schedule-config'}
          onResponse={handleSubmitResponse}
          onError={handleSubmitError}
          payload={payload}
          isUpdate={false}
        ></SubmitButton>
      </ButtonContainer>
    </>
  )
}
