import styled from 'styled-components'

export const Header = styled.div`
  display: block;
  width: fit-content;
  padding-left: 20px;
`

export const ImageNews = styled.div`
  background-image: url(${(props) => props.image});
  width: 100%;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  @media (max-width: 600px) {
    min-height: 200px;
  }
`

export const Sections = styled.div`
  display: grid;
  gap: 20px;
`

export const SectionTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 30px;
  color: #ffff00;
  font-size: 2em;

  :after {
    background: #ffff00;
    height: 2px;
    flex: 1;
    content: '';
  }
`

export const HeaderSection = styled.div`
  display: grid;
  height: fit-content;
`
