import styled from 'styled-components'

export const Title = styled.h1`
  color: #ffffff;
  font-weight: normal;
`

export const Body = styled.div`
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 1.5rem;
  padding: 3px;
`

export const AlertDanger = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;

  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
`

export const AlertSuccess = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;

  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
`

export const Button = styled.button`
  background-color: rgba(255, 255, 0, ${(props) => (props.disabled ? '0.4' : '1')});
  height: 44px;
  border: none;
  border-radius: 53px;
  color: black;
  display: flex;
  align-items: center;
  font-size: 19px;
  padding: 0px 28px;
  cursor: pointer;
  margin-left: 10px;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
