import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  padding: ${(props) => props.padding || '40px 60px'};
  background-color: ${(props) => props.backgroundColor || '#212121'};
`

export const TableSpacer = styled.td`
  width: 100%;
  height: 5px;
`

export const Table = styled.table`
  border-spacing: 0px;
  width: 100%;

  & > thead {
    & > tr {
      height: 30px;
    }
  }

  & > tbody {
    & > tr:not(.no-hover) {
      height: 35px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`

export const Th = styled.th`
  font-size: 1.4rem;
  color: #969696;
  border-bottom: 2px solid #525252;
  text-align: start;
  padding: 0px 25px;
  width: ${(props) => props.width || 'auto'};
  text-align: ${(props) => (props.center ? 'center' : 'start')};
`

export const Td = styled.td`
  color: white;
  font-size: 1.4rem;
  letter-spacing: 0.4px;
  padding: 0px 25px;
  width: ${(props) => props.width || 'auto'};
  text-align: ${(props) => (props.center ? 'center' : 'start')};
  text-transform: ${(props) => props.textTransform || 'none'};
  text-decoration: none;
`

export const Pagination = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;
`

export const PaginationButton = styled.button`
  display: ${(props) => (props.show ? 'inline-block' : 'none')};
  border: none;
  border-radius: 0px;
  background-color: #2a2a2a;
  color: white;
  height: 40px;
  width: 36px;
  cursor: pointer;
  font-family: 'DM Sans';
  font-size: ${(props) => (props.dots ? '15px' : '14px')};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${(props) => (props.active ? '#FFFF00' : '#424242')};
  }

  ${(props) =>
    props.active &&
    `
        background-color: #FFFF00;
        color: #2a2a2a;
        font-weight: 700;
    `}

  ${(props) =>
    props.disabled &&
    `
        color: #424242;
        pointer-events: none;
    `}

    &:first-child {
    border-radius: 6px 0px 0px 6px;
    margin-right: 2px;
  }

  &:last-child {
    border-radius: 0px 6px 6px 0px;
    margin-left: 2px;
  }

  & > svg {
    font-size: 16px;
    color: #969696;
  }

  ${(props) =>
    props.dots &&
    `
        color: #727272;
    `}
`

export const LoadingContainer = styled.td`
  width: 100%;
  height: 300px;
`

export const NoDataSpan = styled.td`
  width: 100%;
  color: rgb(200, 200, 200);
  font-size: 1.4rem;
  text-align: center;
`
