import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import {
  HeaderBox,
  MenuItems,
  Highlight,
  IconConquer,
  HelpBox,
  AccountMenu,
  SubMenu,
  Item,
  ButtonNews,
} from './styles'
import { Container, Flex } from '../../assets/styles/global'
import { ReactComponent as IconQuestion } from '../../assets/svg/icon-question.svg'
import { ReactComponent as IconArrow } from '../../assets/svg/icon-arrow.svg'
import { useBlockerModal } from '../../contexts/blockerModal.context'
import BlockerModal from '../BlockerModal'
import { useUserInfo } from '../../contexts/user.context'
import { TourContainer } from '../Tour/styles'
import Tour from '../Tour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons'
import ModalNews from '../ModalNews'

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false)
  const [redirectAdmin, setRedirectAdmin] = useState(false)
  const { blockerModal, setBlockerModal } = useBlockerModal()
  const { userInfo } = useUserInfo()
  const [hideModal, setHideModal] = useState(true)
  const [newsActive, setNewsActive] = useState(false)

  const subMenu = useRef()

  const navigate = useNavigate()

  useEffect(() => {
    if (userInfo.isAdmin) {
      navigate('/admin')
    }
  }, [])

  useEffect(() => {
    setRedirectAdmin(false)
  }, [redirectAdmin])

  useEffect(() => {
    if (openMenu) {
      document.addEventListener('mousedown', handleClickOutsideSubMenu)
    } else {
      document.removeEventListener('mousedown', handleClickOutsideSubMenu)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSubMenu)
    }
  }, [openMenu])

  function handleClickOutsideSubMenu(e) {
    if (subMenu.current.contains(e.target)) return

    setOpenMenu(false)
  }

  function logout() {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('token')
    window.location = '/login'
  }

  function handleIconQuestion() {
    window.open('https://escolaconquer.zendesk.com/hc/pt-br', '_blank')
  }

  function handleLogo() {
    setRedirectAdmin(true)
  }

  function handleAdmin() {
    setRedirectAdmin(true)
    setOpenMenu(false)
  }

  useEffect(() => {
    var is_chrome = navigator.userAgent.indexOf('CriOS') > -1
    var is_safari = navigator.userAgent.indexOf('Safari') > -1
    var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    if (is_chrome && is_safari) {
      is_safari = false
    }

    if (iOS && is_chrome) {
      setBlockerModal({
        status: true,
        text: 'Podemos te dar uma dica? Sua experiência na nossa plataforma será muito melhor pelo Safari ou pelo computador. Nos encontramos lá?',
        type: 'low',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleHideModal(e) {
    setHideModal(e)
    setNewsActive(!e)
  }

  return (
    <>
      {redirectAdmin ? <Navigate to='/admin'></Navigate> : ''}
      <HeaderBox>
        <Container isHeader HeightFull>
          <Flex AlignItems='center' JustifyContent='space-between'>
            <Flex AlignItems='center' JustifyContent='center'>
              <IconConquer onClick={() => handleLogo()} />
              <MenuItems style={{ display: 'none' }}>ead rocks</MenuItems>
              <MenuItems style={{ display: 'none' }}>meu rocket</MenuItems>
              <MenuItems Active>
                Admin - CONQUER<Highlight>ENGLISH</Highlight>
              </MenuItems>
            </Flex>
            <Flex AlignItems='center' JustifyContent='center'>
              <ButtonNews active={newsActive} onClick={() => handleHideModal(false)}>
                <FontAwesomeIcon icon={faRocketLaunch} /> Novidades
              </ButtonNews>
              <TourContainer height='100%'>
                <Tour sequence={1} page='home' position='bottom-left'>
                  Precisou de ajuda, chama o nosso time por aqui!
                </Tour>
                <HelpBox onClick={() => handleIconQuestion()}>
                  <IconQuestion />
                </HelpBox>
              </TourContainer>
              <Flex AlignItems='center' JustifyContent='center'>
                <AccountMenu onClick={() => setOpenMenu(!openMenu)}>
                  MINHA CONTA
                  <IconArrow />
                </AccountMenu>

                <SubMenu ref={subMenu} Active={openMenu}>
                  <Item onClick={() => logout()}>Sair</Item>
                </SubMenu>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </HeaderBox>
      <ModalNews hide={hideModal} closeModal={handleHideModal} loadData={!hideModal} />
      <BlockerModal Active={blockerModal} />
    </>
  )
}
