export function shouldShowButton(pageNumber, actualPage, pageTotal) {
  return (
    pageNumber === 1 ||
    pageNumber === pageTotal ||
    pageNumber === actualPage ||
    (pageNumber < actualPage && pageNumber > actualPage - 2) ||
    (pageNumber > actualPage && pageNumber < actualPage + 2) ||
    pageTotal <= 5
  )
}

export function getFilters(pages, filters, search) {
  let pageFilter = {}
  let params = {}
  let page = {}

  for (let i = 0; Array.isArray(filters) && i < filters.length; i++) {
    const f = filters[i]
    if (f.field === 'teacherId') {
      params.teacherId = f.value
    }
    if (f.field === 'startDate') {
      params.startDate = f.value
    }
    if (f.field === 'endDate') {
      params.endDate = f.value
    }
    if (f.field === 'level') {
      params.level = f.value
    }
  }

  if (search || search !== '') {
    params.name = search
  }

  if (params || (params !== '' && page) || page !== '') {
    pageFilter.params = params
    page.pageNumber = pages
    pageFilter.page = page
  }

  return pageFilter
}
