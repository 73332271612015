import React, { createContext, useState, useContext } from 'react'

const Tour = createContext()

export default function TourProvider({ children }) {
  const [tour, setTour] = useState({
    active: false,
    modal: false,
    actualStep: -1,
    total: 0,
    actualPage: '',
  })

  return <Tour.Provider value={{ tour, setTour }}>{children}</Tour.Provider>
}

export function useTour() {
  const context = useContext(Tour)
  const { tour, setTour } = context
  return { tour, setTour }
}
