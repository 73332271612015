import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { SubTitle } from '../../../../../../pages/Modules/styles'
import { Error, InputBox } from '../../../../styles'
import Alert from '../../../../Template/Alert'
import SubmitButton from '../../../../Template/SubmitButton'
import { Content, Main, Option, SelectStudent } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

export function EditMentoring({
  mentoring,
  setShowEditModal,
  showEditModal,
  setReloadPage,
  props,
}) {
  const [data, setData] = useState([])
  const [mentorData, setMentorData] = useState([])
  const [hideAlert, setHideAlert] = useState(true)
  const [choiceSelected, setChoiceSelected] = useState()
  const [mentorSelected, setMentorSelected] = useState()
  const [studentSelected, setStudentSelected] = useState()
  const [alertSuccess, setAlertSuccess] = useState()
  const [alertMessage, setAlertMessage] = useState('')
  const [payload, setPayload] = useState()
  const [meetingUrl, setMeetingUrl] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [selectMentorValid, setSelectMentorValid] = useState(true)

  useEffect(() => {
    if (choiceSelected == 'mentor') {
      delete payload?.studentId
    }

    if (choiceSelected == 'aluno') {
      delete payload?.mentorId
    }
  }, [choiceSelected])

  useEffect(() => {
    getStudentList()
    getMentorList()
  }, [])

  useEffect(() => {
    if (Array.isArray(mentoring)) {
      setPayload({
        ...payload,
        ['scheduleMentoringId']: mentoring[0].scheduleMentoringId,
      })
    }
  }, [mentoring])

  const getStudentList = () => {
    // fixme: esta carregando TODOS os alunos
    axios
      .get('/admin/student/')
      .then((res) => {
        setData(res.data)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const getMentorList = () => {
    axios
      .get('/admin/mentor/')
      .then((res) => {
        setMentorData(res.data)
        setMeetingUrl(mentoring[0].meetingUrl)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const onChoice = (event) => {
    setChoiceSelected(event.target.value)
  }

  function handleAnimation() {
    setHideAlert(true)
  }

  function handleSubmitResponse() {
    setHideAlert(false)
    handleAlert({
      message: 'Mentoria atualizada com sucesso',
      success: true,
    })
    setShowEditModal(false)
    setReloadPage(true)
  }

  function handleSubmitError() {
    handleAlert({
      message: 'ERRO ao atualizar Mentoria',
      success: true,
    })
    setShowEditModal(false)
    setReloadPage(true)
  }

  function handleAlert(alert) {
    setAlertMessage(alert.message)
    setAlertSuccess(alert.success)
    setHideAlert(false)
  }

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })

    if (e.target.name === 'mentorId' && e.target.value) {
      setSelectMentorValid(true)
      setMentorSelected(e.target.value)
    }
  }

  function handleSubmitErrorMentor(data, status, generalMessage, request, config) {
    setErrorMessage(
      'Erro ao atualizar a mentoria: ' +
        (data && data.msg ? data.msg : generalMessage ? generalMessage : null),
    )
  }

  function formIsValid() {
    if (choiceSelected === 'mentor') {
      if (!mentorSelected) {
        setSelectMentorValid(false)
        return false
      }
      setSelectMentorValid(true)
    }
    return true
  }

  return (
    <Main>
      {mentoring[0].scheduleStatusText !== 'Cancelada' ? (
        <>
          <SubTitle style={{ color: '#FFFF00', fontSize: '1.4vw' }}>
            Quem você deseja editar?
          </SubTitle>
          <Content>
            <SelectStudent value={choiceSelected} onChange={onChoice}>
              <Option value={''}>Selecione Mentor ou Aluno</Option>
              <Option value={'mentor'}>Mentor </Option>
              <Option value={'aluno'}>Aluno</Option>
            </SelectStudent>
          </Content>
          <br />
          {choiceSelected == 'aluno' ? (
            <>
              <SubTitle style={{ color: '#FFFF00', fontSize: '1.4vw' }}>
                Selecione o aluno:
              </SubTitle>

              <Content>
                <SelectStudent name={'studentId'} value={studentSelected} onChange={handleChange}>
                  <Option value={''}>Deixar vago</Option>
                  {data &&
                    data.map((student) => (
                      <Option key={student.id} value={student.id}>
                        {student.name}
                      </Option>
                    ))}
                </SelectStudent>
              </Content>
            </>
          ) : (
            ''
          )}

          {choiceSelected == 'mentor' ? (
            <>
              <SubTitle style={{ color: '#FFFF00', fontSize: '1.4vw' }}>
                Selecione o mentor:
              </SubTitle>

              <Content>
                <SelectStudent
                  name={'mentorId'}
                  value={mentorSelected}
                  onChange={handleChange}
                  notValid={!selectMentorValid}
                >
                  <Option value={null}>Selecione:</Option>
                  {mentorData &&
                    mentorData.map((mentor) => (
                      <Option key={mentor.id} value={mentor.id}>
                        {mentor.name}
                      </Option>
                    ))}
                </SelectStudent>
              </Content>
            </>
          ) : (
            ''
          )}

          <br />
          <InputBox>
            <label>Link da sala (Zoom)</label>
            <input name='meetingUrl' type='text' placeholder={meetingUrl} onChange={handleChange} />
          </InputBox>
          <br />

          {errorMessage && (
            <Error>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </Error>
          )}
          <div className='row-buttons'>
            <SubmitButton
              url={'/admin/mentoring'}
              onResponse={handleSubmitResponse}
              onError={handleSubmitError}
              payload={payload}
              isValid={formIsValid}
              isUpdate={true}
            ></SubmitButton>
          </div>
          <Alert
            hide={hideAlert}
            success={alertSuccess}
            message={alertMessage}
            animationEnded={handleAnimation}
          />
        </>
      ) : null}
    </Main>
  )
}
