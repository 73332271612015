import styled from 'styled-components'

export const Alert = styled.div`
  border: 1px solid #ffff00;
  border-radius: 14px;
  font-size: 14px;
  /*height: 60px;*/
  // display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  width: 600px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #0c0c0c;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 30;

  &.esconde {
    display: none;
  }

  &.mostrar {
    display: flex;
  }

  @media (max-width: 1200px) {
    width: calc(100% - 30px);
    padding-right: 10px;
    padding-left: 10px;
    margin: 0px 15px;
    right: 0px;
  }

  animation: show;
  animation-duration: ${(props) => props.animationTime};
  animation-fill-mode: forwards;
  animation-play-state: ${(props) => (props.hide ? 'paused' : 'initial')};
  @keyframes show {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`

export const TextXp = styled.span`
  font-size: 14px;
  color: ${(props) => props.color || '#FFFFFF'};
`

export const Message = styled.span`
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.28px;
  margin-left: 16px;
`
